import footer_white from '../../../assets/images/footer_white_242x38.png'
import { Box, Divider, Link, List, ListItem, Typography } from '@mui/material'
import { color } from '../../../styles/theme'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import type React from 'react'

export const Footer = memo(() => {
  const navigate = useNavigate()
  const handleRootLink = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    navigate('/accounts', { replace: true })
  }

  return (
    <Box
      sx={{
        height: 150,
        marginTop: '42px',
        backgroundColor: color.darkPurple,
        overflow: 'hidden',
        width: '100vw',
      }}
    >
      <Box
        sx={{
          height: '100%',
          margin: '0 auto',
        }}
      >
        <Link
          onClick={handleRootLink}
          sx={{
            width: '245px',
            height: '36px',
            backgroundImage: `url(${footer_white})`,
            backgroundRepeat: 'no-repeat',
            float: 'left',
            margin: '32px 0 0',
            cursor: 'pointer',
          }}
        />
        <Box
          sx={{
            paddingTop: '40px',
          }}
        >
          <nav style={{ textAlign: 'center' }}>
            <List
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                listStyle: 'none',
                paaing: 0,
                marginTop: '0px',
                marginBottom: '10px',
              }}
            >
              <ListItem
                sx={{
                  margin: '0 15px',
                  display: 'inline-block',
                  width: 'auto',
                  height: 'auto',
                  padding: '0px',
                }}
              >
                <Link
                  href="/about"
                  sx={{
                    textDecoration: 'none',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: color.white,
                      fontWeight: 400,
                    }}
                  >
                    About
                  </Typography>
                </Link>
              </ListItem>
              <ListItem
                sx={{
                  margin: '0 15px',
                  display: 'inline-block',
                  width: 'auto',
                  height: 'auto',
                  padding: '0px',
                }}
              >
                <Link
                  href="/about"
                  sx={{
                    textDecoration: 'none',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: color.white,
                      fontWeight: 400,
                    }}
                  >
                    Sellers
                  </Typography>
                </Link>
              </ListItem>
              <ListItem
                sx={{
                  margin: '0 15px',
                  display: 'inline-block',
                  width: 'auto',
                  height: 'auto',
                  padding: '0px',
                }}
              >
                <Link
                  href="/about"
                  sx={{
                    textDecoration: 'none',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: color.white,
                      fontWeight: 400,
                    }}
                  >
                    Contact us
                  </Typography>
                </Link>
              </ListItem>
            </List>
          </nav>
        </Box>
        <Divider
          sx={{
            width: '100%',
            left: '0px',
            marginTop: '20px',
            marginBottom: '20px',
            borderColor: '#3e3842',
          }}
        />
      </Box>
    </Box>
  )
})
