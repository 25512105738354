import {
	Link,
	Stack,
	type SxProps,
	type Theme,
	Typography,
} from "@mui/material";
import type { ReactNode } from "react";
import { color, theme } from "../../../styles/theme";

type PurchaseInfoProps = {
	title: string;
	titleWidth?: string;
	titleVariant?: "subtitle1" | "subtitle3" | "body1";
	content?: string;
	contentWidth?: string;
	contentAlign?: "center" | "start" | "end";
	contentVariant?: "subtitle1" | "subtitle3";
	subContent?: string;
	children?: ReactNode;
	sx?: SxProps<Theme>;
	linkProps?: LinkProps;
};

type LinkProps = {
	isLink: boolean;
	accountTitle: string;
	href: string;
};

export const TableItem = ({
	title,
	titleWidth = "20%",
	titleVariant = "body1",
	content,
	contentWidth = "80%",
	contentAlign = "start",
	contentVariant = "subtitle1",
	subContent,
	children,
	sx,
	linkProps,
}: PurchaseInfoProps) => {
	const { isLink, accountTitle, href } = linkProps || {};
	return (
		<Stack direction={"row"} sx={sx}>
			<Typography
				variant={titleVariant}
				sx={{
					height: "inherit",
					width: titleWidth,
					backgroundColor: color.matGray,
					textAlign: "center",
					padding: theme.spacing(2),
				}}
			>
				{title}
			</Typography>
			{children ? (
				children
			) : (
				<Stack
					width={contentWidth}
					justifyContent={"center"}
					sx={{
						padding: theme.spacing(2),
					}}
				>
					{isLink ? (
						<Link href={href}>{accountTitle}</Link>
					) : (
						<>
							<Typography
								variant={contentVariant}
								textAlign={contentAlign}
								whiteSpace={"pre-wrap"}
							>
								{content}
							</Typography>
							{subContent && (
								<Typography variant={contentVariant}>{subContent}</Typography>
							)}
						</>
					)}
				</Stack>
			)}
		</Stack>
	);
};
