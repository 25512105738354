import { Box } from '@mui/material'
import { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AccountForm } from '../../../components/common/layouts/accounts/AccountForm'
import { AuthContext } from '../../../hooks/context/auth'

export const CreateAccount = () => {
  const location = useLocation()
  const authContext = useContext(AuthContext)
  const [images, setImages] = useState<File[]>(location?.state?.accountParams?.images || [])

  return authContext?.auth ? (
    <AccountForm
      images={images}
      accountParams={location?.state?.accountParams}
      mode="create"
      onImagesChange={(files) => {
        setImages(files)
      }}
    />
  ) : (
    <Box />
  )
}
