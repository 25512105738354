import { Box, Link, Typography } from '@mui/material'
import { memo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useVerifyRegisterCodeAPI } from '../../../hooks/api/auth'

export const VerifyRegisterCode = memo(() => {
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const { authRegister, succeeded } = useVerifyRegisterCodeAPI(code || undefined)
  const navigate = useNavigate()

  if (!code) {
    return <Typography variant="h1">Invalid code</Typography>
  }

  if (succeeded) {
    navigate('/verify/sms-factor', { state: { authRegister } })
  }
  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <Typography variant="h2">認証中です。移動するまでしばらくおまちください。</Typography>
      <Typography variant="h3">
        しばらく経っても移動しない場合は受信したメールボックスから再度やり直してください。
      </Typography>
      <Link href="/">Topへ戻る</Link>
    </Box>
  )
})
