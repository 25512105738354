import {
	Box,
	Stack,
	Step,
	StepConnector,
	type StepIconProps,
	StepLabel,
	Stepper,
	Typography,
	stepConnectorClasses,
	styled,
} from "@mui/material";
import { memo, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../../../components/common/parts/Loading";
import { UserRoleTypes } from "../../../enum/userRole";
import { useGetPurchasedAccount } from "../../../hooks/api/purchases";
import { AuthContext } from "../../../hooks/context/auth";
import { color } from "../../../styles/theme";
import { ReviewForm } from "./ReviewForm";
import { TradeTabs } from "./TradeTabs";

const ColorlibConnector = styled(StepConnector)(() => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 12,
		left: "calc(-50%)",
		right: "calc(50%)",
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: color.red,
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: color.red,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderTopWidth: 3,
		borderColor: color.paleGray,
	},
}));

const ColorlibStepIconRoot = styled("div")<{
	ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
	backgroundColor: color.paleGray,
	zIndex: 1,
	width: 25,
	height: 25,
	borderRadius: "50%",
	...(ownerState.active && {
		backgroundColor: color.red,
	}),
	...(ownerState.completed && {
		backgroundColor: color.red,
	}),
}));

function ColorlibStepIcon(props: StepIconProps) {
	const { active, completed, className } = props;

	return (
		<ColorlibStepIconRoot
			ownerState={{ active, completed }}
			className={className}
		/>
	);
}

function getStepTitle(step: number) {
	switch (step) {
		case 1:
			return "支払い確認中です。";
		case 2:
		case 3:
			return "レビューをしてください";
		case 4:
			return "取引が完了しました。";
		case 5:
			return "取引がキャンセルされました。";
		default:
			return "取引情報が見つかりませんでした。";
	}
}

function getStepDescription(step: number) {
	switch (step) {
		case 1:
			return "商品の準備をしてください。支払いの確認が取れるまで商品の譲渡はしないでください。";
		case 2:
			return "商品を受け取り、売り手へレビューを行なってください。";
		case 3:
			return "買い手からレビューされました。買い手へレビューを返信してください。";
		case 4:
		case 5:
			return "";
		default:
			return "取引情報が見つかりませんでした。";
	}
}

function getActiveStep(step: number) {
	switch (step) {
		case 1:
			return 0;
		case 2:
		case 3:
			return 1;
		case 4:
			return 2;
		default:
			return 0;
	}
}

export const TradeAccount = memo(() => {
	const authContext = useContext(AuthContext);
	const steps = ["支払い待ち", "商品お渡し", "取引完了"];

	const { id } = useParams();
	const { purchasedAccount, error } = useGetPurchasedAccount(id || "");
	const [activeStep, setActiveStep] = useState(0);

	const purchaseDescriptionTitle = getStepTitle(
		purchasedAccount?.transactionStatus || 0,
	);
	const purchaseDescription = getStepDescription(
		purchasedAccount?.transactionStatus || 0,
	);
	useEffect(() => {
		if (purchasedAccount?.transactionStatus) {
			setActiveStep(getActiveStep(purchasedAccount.transactionStatus));
		}
	}, [purchasedAccount]);

	if (!authContext?.auth) {
		return <Loading />;
	}

	if (!purchasedAccount) {
		return (
			<Box>
				<Typography>取引情報が見つかりませんでした。</Typography>
			</Box>
		);
	}

	if (authContext?.auth?.role === UserRoleTypes.USER) {
		if (!purchasedAccount?.account || !authContext?.auth || error) {
			return (
				<Box>
					<Typography>取引情報が見つかりませんでした。</Typography>
				</Box>
			);
		}

		if (
			purchasedAccount.exhibitorUserId !== authContext.auth.id &&
			purchasedAccount.purchasedUserId !== authContext.auth.id
		) {
			return (
				<Box>
					<Typography>取引情報が見つかりませんでした。</Typography>
				</Box>
			);
		}
	}

	return (
		<Stack
			sx={{
				width: "100%",
				backgroundColor: color.paleGray,
				minHeight: "calc(100vh - 64px)",
			}}
		>
			<Stack
				alignItems={"center"}
				sx={{
					marginTop: "20px",
					gap: "20px",
				}}
			>
				<Box>
					<Stack
						sx={{
							width: "800px",
							backgroundColor: color.white,
							padding: "40px 60px 40px",
							gap: "20px",
						}}
					>
						<Stepper
							alternativeLabel
							activeStep={activeStep}
							connector={<ColorlibConnector />}
						>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel StepIconComponent={ColorlibStepIcon}>
										{label}
									</StepLabel>
								</Step>
							))}
						</Stepper>
						<Stack
							alignItems={"center"}
							sx={{
								width: "100%",
								backgroundColor: color.lightYellow,
								padding: "15px",
								gap: "10px",
							}}
						>
							<Typography
								variant="h5"
								sx={{
									fontWeight: 700,
								}}
							>
								{purchaseDescriptionTitle}
							</Typography>
							<Typography variant="subtext1">{purchaseDescription}</Typography>
						</Stack>
					</Stack>
					<ReviewForm
						purchasedAccount={purchasedAccount}
						userId={authContext.auth.id}
					/>
					<Box
						sx={{
							marginTop: "20px",
						}}
					>
						<TradeTabs purchasedAccount={purchasedAccount} />
					</Box>
				</Box>
			</Stack>
		</Stack>
	);
});
