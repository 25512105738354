import { Box, Button, Stack, Typography } from "@mui/material";
import { memo, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ScrollToTop } from "../../../components/common/layouts/ScrollToTop";
import { Border } from "../../../components/common/parts/Border";
import {
	type AccountFormRequest,
	type AccountFormResponse,
	type AccountUpdateRequest,
	useCreateAccount,
	useUpdateAccount,
} from "../../../hooks/api/accounts";
import { fetcher } from "../../../hooks/api/fetcher";
import type { AccountRequest } from "../../../hooks/api/schema/accountRequest";
import { AuthContext } from "../../../hooks/context/auth";
import { color } from "../../../styles/theme";
import { SellingStatus } from "../../../types/account";
import { resizeImage } from "../../../utils/resizer";

export type PresignedURLResponse = {
	url: string;
};

export const CreateAccountConfirm = memo(() => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const location = useLocation();
	ScrollToTop();
	const { createAccount } = useCreateAccount();
	const { updateAccount } = useUpdateAccount();
	const params = location.state.accountParams as AccountRequest;

	const handleCreateAccount = async () => {
		if (!authContext?.auth) {
			alert("ログインしてください");
			return;
		}

		const req: AccountFormRequest = {
			userId: authContext.auth?.id.toString(),
			accountName: params.accountName,
			categoryId: Number(params.categoryId),
			platform: params.categoryName,
			description: params.description,
			followers: params.followers,
			price: params.price,
		};
		createAccount(req).then(async (res: { account: AccountFormResponse }) => {
			const uploadedImages: { index: number; url: string }[] = [];
			const promises = Array.from(params.images).map(async (file, index) => {
				const presignedResponse = await fetcher<PresignedURLResponse>(
					`/api/account/generate-presigned-url?accountId=${res.account.id}&userId=${
						authContext!.auth!.id
					}&imageType=${file.type}&order=${index + 1}&extension=${file.name.split(".").pop()}`,
				);
				const url = presignedResponse.url;
				const resized = await resizeImage(file);
				const uploadResponse = await fetch(url, {
					method: "PUT",
					headers: {
						"Content-Type": resized.type,
					},
					body: resized,
				});

				if (!uploadResponse.ok) throw new Error("Upload failed");

				uploadedImages.push({ index: index + 1, url: url.split("?")[0] });
			});

			await Promise.all(promises);

			const sortedImages = uploadedImages
				.sort((a, b) => {
					if (a.index < b.index) return -1;
					if (a.index > b.index) return 1;
					return 0;
				})
				.map((image) => {
					return image.url;
				});

			const account = res.account;
			const updateRequest: AccountUpdateRequest = {
				id: account.id.toString(),
				userId: account.userId,
				accountName: account.accountName,
				categoryId: account.categoryId.toString(),
				imageUrls: sortedImages,
				platform: account.platform,
				description: account.description,
				followers: account.followers,
				price: account.price,
				sellingStatus: SellingStatus.Selling,
			};

			await updateAccount(updateRequest);

			navigate("/accounts/complete", {
				state: {
					accountId: account.id,
					resultState: "created",
				},
			});
		});
	};
	return (
		<Stack
			alignItems={"center"}
			sx={{ width: "100%", height: "100%", backgroundColor: color.paleGray }}
		>
			<Stack
				direction={"row"}
				justifyContent={"center"}
				alignItems={"center"}
				sx={{ marginTop: "20px" }}
			>
				<Typography variant="h4">M & A</Typography>
				<Typography variant="h5">CLUB</Typography>
			</Stack>
			<Stack
				alignItems={"center"}
				sx={{
					width: "660px",
					height: "100%",
					backgroundColor: color.white,
					margin: "0px auto",
					padding: "30px",
					gap: "20px",
				}}
			>
				<Typography variant="subtitle1">出品画像・動画</Typography>
				<Stack
					direction={"row"}
					justifyContent={"start"}
					alignItems={"center"}
					sx={{
						width: "100%",
						gap: "20px",
						flexWrap: "wrap",
						maxwidth: "500px",
					}}
				>
					{params.images.map((image: File, index: number) => (
						<Box key={`${image.name}-${image.lastModified}`}>
							<Box
								component="img"
								src={URL.createObjectURL(image)}
								sx={{ width: 100, height: 100, marginTop: 2 }}
							/>
						</Box>
					))}
				</Stack>
				<Border />
				<Typography variant="subtitle1">出品情報</Typography>
				<Stack
					direction={"column"}
					justifyContent={"start"}
					alignItems={"start"}
					sx={{ width: "100%" }}
				>
					<Typography
						variant="body1"
						sx={{
							width: "inherit",
							backgroundColor: color.paleGray,
							padding: "10px",
							border: `1px solid ${color.midGray}`,
						}}
					>
						カテゴリー
					</Typography>
					<Typography
						variant="body1"
						sx={{
							width: "inherit",
							padding: "10px",
							borderTop: "0px",
							borderRight: `1px solid ${color.midGray}`,
							borderBottom: `1px solid ${color.midGray}`,
							borderLeft: `1px solid ${color.midGray}`,
						}}
					>
						{params.categoryName}
					</Typography>
					<Typography
						variant="body1"
						sx={{
							width: "inherit",
							backgroundColor: color.paleGray,
							padding: "10px",
							borderTop: "0px",
							borderRight: `1px solid ${color.midGray}`,
							borderBottom: `1px solid ${color.midGray}`,
							borderLeft: `1px solid ${color.midGray}`,
						}}
					>
						出品タイトル
					</Typography>
					<Typography
						variant="body1"
						sx={{
							width: "inherit",
							padding: "10px",
							borderTop: "0px",
							borderRight: `1px solid ${color.midGray}`,
							borderBottom: `1px solid ${color.midGray}`,
							borderLeft: `1px solid ${color.midGray}`,
						}}
					>
						{params.accountName}
					</Typography>
					<Typography
						variant="body1"
						sx={{
							width: "inherit",
							backgroundColor: color.paleGray,
							padding: "10px",
							borderTop: "0px",
							borderRight: `1px solid ${color.midGray}`,
							borderBottom: `1px solid ${color.midGray}`,
							borderLeft: `1px solid ${color.midGray}`,
						}}
					>
						商品説明
					</Typography>
					<Typography
						variant="body1"
						sx={{
							width: "inherit",
							padding: "10px",
							borderTop: "0px",
							borderRight: `1px solid ${color.midGray}`,
							borderBottom: `1px solid ${color.midGray}`,
							borderLeft: `1px solid ${color.midGray}`,
						}}
					>
						{params.description}
					</Typography>
					<Typography
						variant="body1"
						sx={{
							width: "inherit",
							backgroundColor: color.paleGray,
							padding: "10px",
							borderTop: "0px",
							borderRight: `1px solid ${color.midGray}`,
							borderBottom: `1px solid ${color.midGray}`,
							borderLeft: `1px solid ${color.midGray}`,
						}}
					>
						フォロワー数
					</Typography>
					<Typography
						variant="body1"
						sx={{
							width: "inherit",
							padding: "10px",
							borderTop: "0px",
							borderRight: `1px solid ${color.midGray}`,
							borderBottom: `1px solid ${color.midGray}`,
							borderLeft: `1px solid ${color.midGray}`,
						}}
					>
						{params.followers
							? params.followers.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							: 0}
						人
					</Typography>
				</Stack>
				<Border />
				<Typography variant="subtitle1">価格情報</Typography>
				<Stack
					direction={"column"}
					justifyContent={"start"}
					alignItems={"start"}
					sx={{ width: "100%" }}
				>
					<Stack
						direction={"row"}
						sx={{
							width: "inherit",
							alignItems: "center",
							border: `1px solid ${color.midGray}`,
						}}
					>
						<Typography
							sx={{
								width: "inherit",
								fontSize: 14,
								fontWeight: 700,
								backgroundColor: color.paleGray,
								padding: "10px",
								textAlign: "center",
							}}
						>
							商品価格
						</Typography>
						<Typography
							variant="body1"
							sx={{
								width: "inherit",
								padding: "10px",
								textAlign: "right",
							}}
						>
							{`¥${params.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円`}
						</Typography>
					</Stack>
					<Stack
						direction={"row"}
						sx={{
							width: "inherit",
							borderTop: "0px",
							borderRight: `1px solid ${color.midGray}`,
							borderBottom: `1px solid ${color.midGray}`,
							borderLeft: `1px solid ${color.midGray}`,
						}}
					>
						<Typography
							sx={{
								width: "inherit",
								fontSize: 14,
								fontWeight: 700,
								backgroundColor: color.paleGray,
								padding: "10px",
								textAlign: "center",
							}}
						>
							システム手数料(10%)
						</Typography>
						<Typography
							variant="body1"
							sx={{
								width: "inherit",
								padding: "10px",
								textAlign: "right",
							}}
						>
							{`¥${String(location.state.systemFee).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円`}
						</Typography>
					</Stack>
					<Stack
						direction={"row"}
						sx={{
							width: "inherit",
							borderTop: "0px",
							borderRight: `1px solid ${color.midGray}`,
							borderBottom: `1px solid ${color.midGray}`,
							borderLeft: `1px solid ${color.midGray}`,
						}}
					>
						<Typography
							sx={{
								width: "inherit",
								fontSize: 14,
								fontWeight: 700,
								backgroundColor: color.paleGray,
								padding: "10px",
								textAlign: "center",
							}}
						>
							売上金
						</Typography>
						<Typography
							variant="body1"
							sx={{
								width: "inherit",
								padding: "10px",
								textAlign: "right",
							}}
						>
							{`¥${String(location.state.proceeds).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
						</Typography>
					</Stack>
				</Stack>
				<Stack
					sx={{
						width: "100%",
						backgroundColor: color.lightYellow,
						padding: "20px",
						gap: "5px",
					}}
				>
					<Typography
						variant="subtitle3"
						sx={{
							textAlign: "center",
						}}
					>
						利用規約に同意の上、出品を行なってください。
					</Typography>
					<Typography
						variant="subtitle3"
						sx={{
							textAlign: "center",
						}}
					>
						※チート行為やデータ改竄がされた商品の販売は禁止しています。
					</Typography>
				</Stack>
				<Button
					sx={{
						width: "100%",
						backgroundColor: color.red,
						borderRadius: 0,
						"&:hover": {
							backgroundColor: color.red,
							boxShadow: `0 0 10px ${color.red}, 0 0 20px ${color.red}`,
						},
					}}
					onClick={handleCreateAccount}
				>
					<Typography variant="h5">出品する</Typography>
				</Button>
				<Box
					sx={{
						marginTop: "20px",
					}}
				>
					<Link
						to="/accounts/create"
						state={{
							accountParams: params,
							systemFee: location.state?.systemFee,
							proceeds: location.state?.proceeds,
						}}
					>
						戻る
					</Link>
				</Box>
			</Stack>
		</Stack>
	);
});
