export enum TransactionStatusTypes {
	WAITING_PAYMENT = 1,
	WAITING_BUYER_EVALUATION = 2,
	WAITING_EXHIBITOR_EVALUATION = 3,
	SUCCESS = 4,
	CANCEL = 5,
}

export type TransactionStatusType = TransactionStatusTypes;

export const TransactionStatusStrings = {
	[TransactionStatusTypes.WAITING_PAYMENT]: "支払い待ち",
	[TransactionStatusTypes.WAITING_BUYER_EVALUATION]: "買い手評価待ち",
	[TransactionStatusTypes.WAITING_EXHIBITOR_EVALUATION]: "売り手評価待ち",
	[TransactionStatusTypes.SUCCESS]: "取引完了",
	[TransactionStatusTypes.CANCEL]: "キャンセル",
} as const;

export type TransactionStatusString =
	(typeof TransactionStatusStrings)[TransactionStatusType];
