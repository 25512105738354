import { z } from "zod";

export const UserBankAccountRequestSchema = z.object({
	bankCode: z.string().optional(), // 銀行コード
	bankName: z.string().optional(), // 銀行名
	branchCode: z.string().optional(), // 支店コード
	branchName: z.string().optional(), // 支店名
	accountType: z.string().optional(), // 口座種別
	accountNumber: z.string().optional(), // 口座番号
	accountName: z.string().optional(), // 口座名義
	remarks: z.string().optional(), // 備考
});

export type UserBankAccountRequest = z.infer<
	typeof UserBankAccountRequestSchema
>;
