import { Box, Button, Link, Stack, TextField, Typography } from "@mui/material";
import { type ReactNode, memo, useState } from "react";
import { useFormSubmit } from "../../../hooks/custom/useFormSubmit";
import type { LoginRequest } from "../../../types/auth";
import { ModalBase } from "./ModalBase";

export const LoginModal = memo(
	({
		open,
		onClose,
		onSubmit,
		message,
		alertMessage,
		confirmLabel,
		confirmIcon,
	}: {
		open: boolean;
		onClose: () => void;
		onSubmit: (req: LoginRequest) => void;
		message: string;
		alertMessage?: string;
		confirmLabel?: string;
		confirmIcon?: ReactNode;
	}) => {
		const [email, setEmail] = useState("");
		const [password, setPassword] = useState("");
		const handleSubmit = () => {
			onSubmit({ email, password });
		};
		const handleKeyDown = useFormSubmit(handleSubmit);
		return (
			<ModalBase
				open={open}
				onClose={onClose}
				sx={{
					width: 390,
					minHeight: 160,
				}}
			>
				<Box
					id={"modal-title"}
					sx={{ flexGrow: 1, textAlign: "center", marginBottom: "15px" }}
				>
					<Typography variant={"h4"}>{message}</Typography>
				</Box>
				{alertMessage && (
					<Stack sx={{ marginBottom: "15px" }}>
						{alertMessage.split("\n").map((line, i) => (
							<Typography
								key={`alert-message-${line}`}
								variant="subtext1"
								sx={{ color: "#d9534f", textAlign: "center" }}
							>
								{line}
							</Typography>
						))}
					</Stack>
				)}
				<Box
					sx={{
						width: "280px",
						margin: "auto",
					}}
				>
					<Box
						sx={{
							marginBottom: "5px",
						}}
					>
						<TextField
							size="small"
							placeholder="Email"
							sx={{
								width: "280px",
							}}
							onChange={(e) => setEmail(e.target.value)}
							onKeyDown={handleKeyDown}
						/>
					</Box>
					<Box
						sx={{
							marginBottom: "5px",
						}}
					>
						<TextField
							size="small"
							type="password"
							placeholder="Password"
							sx={{
								width: "280px",
							}}
							onChange={(e) => setPassword(e.target.value)}
							onKeyDown={handleKeyDown}
						/>
					</Box>
					{/* <Link sx={{ marginBottom: '25px', textDecoration: 'none' }}>
            <Typography variant="body2" sx={{ color: '#337ab7' }}>
              Reset Password
            </Typography>
          </Link> */}
				</Box>
				<Box
					sx={{
						textAlign: "center",
						margin: "20px auto auto",
					}}
				>
					<Button
						size={"medium"}
						sx={{ width: 160, ml: 2 }}
						onClick={handleSubmit}
						startIcon={confirmIcon}
					>
						{confirmLabel}
					</Button>
				</Box>
			</ModalBase>
		);
	},
);
