import { useContext } from "react";
import useSWR from "swr";
import type {
	LoginRequest,
	LoginResponse,
	LoginWithTokenResponse,
	RegisterRequest,
	RegisterResponse,
	VerifyRegisterCodeResponse,
} from "../../types/auth";
import type { AuthRegister } from "../../types/auth";
import { AuthContext } from "../context/auth";
import { fetcher } from "./fetcher";

export type AuthRegisterResponse = {
	authRegister: AuthRegister;
};

export const useCreateRegisterCodeAPI = (): {
	register: (data: RegisterRequest) => Promise<RegisterResponse>;
} => {
	return {
		register: (data: RegisterRequest): Promise<RegisterResponse> =>
			fetcher<RegisterResponse>("/auth/register/code/create", {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};

export const useUpdateRegisterCodeAPI = (): {
	updateRegister: (data: RegisterRequest) => Promise<AuthRegisterResponse>;
} => {
	return {
		updateRegister: (data: RegisterRequest): Promise<AuthRegisterResponse> =>
			fetcher<AuthRegisterResponse>("/auth/register/code/update", {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};

export const useVerifyRegisterCodeAPI = (
	verifyCode?: string,
): {
	authRegister?: AuthRegister;
	succeeded: boolean;
} => {
	const key = `/auth/verify/register/${verifyCode}`;
	const { data, error } = useSWR<VerifyRegisterCodeResponse>(key, fetcher);

	if (error) {
		return {
			authRegister: undefined,
			succeeded: false,
		};
	}

	return {
		authRegister: data?.authRegister,
		succeeded: true,
	};
};

export const useLoginAPI = (): {
	login: (data: LoginRequest) => Promise<void>;
} => {
	return {
		login: (data: LoginRequest): Promise<void> =>
			fetcher<LoginResponse>("/auth/login", {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}).then((r) => {}),
	};
};

export const useVerifyAPI = (): { data: LoginWithTokenResponse } => {
	const authContext = useContext(AuthContext);

	const customFetcher = () => {
		return fetcher<LoginWithTokenResponse>("/auth/verify");
	};

	const { data, error } = useSWR<LoginWithTokenResponse>(
		authContext?.isAuthenticated ? "/auth/verify" : null,
		customFetcher,
	);

	if (error) {
		return {
			data: {
				auth: undefined,
			},
		};
	}

	return {
		data: {
			auth: data?.auth,
		},
	};
};

export const useLogoutAPI = (): {
	logout: () => Promise<void>;
} => {
	return {
		logout: (): Promise<void> =>
			fetcher("/auth/logout", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};

export const useSendOPTAPI = (): {
	sendSMS: (tel: string) => Promise<void>;
} => {
	return {
		sendSMS: (tel: string): Promise<void> =>
			fetcher<void>("/auth/verify/send_otp", {
				method: "POST",
				body: JSON.stringify({ tel }),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};

export const useRecieveOPTAPI = (): {
	recieveSMS: (tel: string, oneTimePassword: string) => Promise<void>;
} => {
	return {
		recieveSMS: (tel: string, oneTimePassword: string): Promise<void> =>
			fetcher<void>("/auth/verify/recieve_otp", {
				method: "POST",
				body: JSON.stringify({ tel, oneTimePassword }),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};
