import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { mutate } from "swr";
import {
	type MessageData,
	useGetUserMessages,
} from "../../../hooks/api/userMessage";
import { AuthContext } from "../../../hooks/context/auth";
import { color } from "../../../styles/theme";
import type { User } from "../../../types/users";
import { ChatMessage } from "../parts/ChatMessage";
import { Partner } from "./Partner";

interface ChatProps {
	profileUser: User;
	onClose: () => void;
}

export const Chat = memo(({ profileUser, onClose }: ChatProps) => {
	const wsRef = useRef<WebSocket | null>(null);
	const [message, setMessage] = useState("");
	const authContext = useContext(AuthContext);
	const { responses } = useGetUserMessages(authContext?.auth?.id || "");
	const [selectedPartnerId, setSelectedPartnerId] = useState<string | null>(
		profileUser.id.toString(),
	);

	const [selectedMessagedata, setSelectedMessagedata] =
		useState<MessageData | null>(null);

	const messagesEndRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (messagesEndRef.current && selectedMessagedata?.messages) {
			messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
		}
	}, [selectedMessagedata?.messages]);

	useEffect(() => {
		// WebSocket接続を確立する関数
		const connectWebSocket = () => {
			const newWs = new WebSocket(
				`${process.env.REACT_APP_WEB_SOCKET_URL}/chat`,
			);

			newWs.onopen = () => {
				console.log("WebSocket connection established");
				// クライアントの登録メッセージを送信
				const registerMessage = {
					type: "register",
					userID: authContext?.auth?.id, // 適切なユーザーIDを設定
				};
				newWs.send(JSON.stringify(registerMessage));
			};

			newWs.onmessage = async (event) => {
				const incomingMessage = JSON.parse(event.data);
				console.log("Incoming message:", incomingMessage);
				// ここで状態を更新して受信メッセージを含めます
				await mutate(`/api/user_message/${authContext?.auth?.id}`);
			};

			newWs.onerror = (error) => {
				console.error("WebSocket error:", error);
			};

			newWs.onclose = (event) => {
				console.log("WebSocket connection closed. Attempting to reconnect...");
				setTimeout(connectWebSocket, 5000); // 5秒後に再接続を試みる
			};

			wsRef.current = newWs;
		};

		// コンポーネントのマウント時にWebSocket接続を行う
		connectWebSocket();

		// コンポーネントのアンマウント時にWebSocket接続をクローズする
		return () => {
			if (wsRef.current) {
				wsRef.current.close();
			}
		};
	}, [authContext?.auth?.id]);

	useEffect(() => {
		if (responses && selectedPartnerId !== null) {
			// partnersから現在選択されているpartnerIdに対応するデータを探す
			const updatedMessageData = responses.find(
				(res) => res.partner.id.toString() === selectedPartnerId,
			);

			if (updatedMessageData) {
				// selectedPartnerを更新
				setSelectedMessagedata(updatedMessageData);
			}
		}
	}, [responses, selectedPartnerId]);

	const sendMessage = (message: string) => {
		if (authContext?.auth === undefined || selectedPartnerId === null) {
			console.error("User not authenticated. Message not sent.");
			return;
		}
		if (
			wsRef.current &&
			wsRef.current.readyState === WebSocket.OPEN &&
			message.trim()
		) {
			const messageData = {
				senderID: authContext.auth.id,
				receiverID: selectedPartnerId,
				content: message,
			};
			console.log("Sending message:", messageData);
			wsRef.current.send(JSON.stringify(messageData));
			setMessage("");
		} else {
			console.log("WebSocket is not open. Message not sent.");
		}
	};

	const handleSendMessage = () => {
		sendMessage(message);
	};

	const handleClick = (data: MessageData) => {
		setSelectedPartnerId(data.partner.id.toString());
		setSelectedMessagedata(data);
	};

	return (
		<Stack
			direction={"column"}
			justifyContent={"center"}
			alignItems={"center"}
			sx={{
				position: "fixed",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				backgroundColor: "rgba(0, 0, 0, 0.5)",
				zIndex: 1000,
			}}
		>
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				sx={{ width: "100%", height: "100vh" }}
			>
				<Stack
					direction={"column"}
					sx={{
						width: "360px",
						height: "100vh",
						backgroundColor: color.bluePurple,
					}}
				>
					{/* パートナーリスト */}
					<Box
						sx={{
							width: "360px",
							overflowY: "auto",
							overflowX: "hidden",
						}}
					>
						{responses && responses.length > 0 ? (
							responses.map((res, index) => (
								<Partner
									key={`${res.partner.id}-${index}`}
									res={res}
									partnerId={selectedPartnerId}
									onClick={handleClick}
								/>
							))
						) : (
							<Partner
								res={{ partner: profileUser, currentMessage: "", messages: [] }}
								partnerId={selectedPartnerId}
								onClick={handleClick}
							/>
						)}
					</Box>
				</Stack>
				{/* メッセージリスト */}
				<Stack
					direction={"column"}
					sx={{
						width: "100%",
						height: "100%",
						padding: "30px 30px 0px",
						backgroundColor: color.matGray,
					}}
				>
					<Box
						ref={messagesEndRef}
						sx={{ height: "inherit", overflowY: "auto" }}
					>
						{selectedMessagedata?.messages?.map((message) => (
							<ChatMessage
								key={`${message.senderId}-${message.updatedAt}`}
								message={message}
								isPartner={
									Number(message.senderId) === Number(selectedPartnerId)
								}
							/>
						))}
					</Box>
					<Stack
						direction="row"
						alignItems={"center"}
						spacing={2}
						sx={{ padding: "20px" }}
					>
						<TextField
							fullWidth
							variant="outlined"
							placeholder="メッセージを入力..."
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
								if (e.key === "Enter" && !e.nativeEvent.isComposing) {
									handleSendMessage();
								}
							}}
						/>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSendMessage}
							sx={{ minWidth: "80px" }}
						>
							送信
						</Button>
					</Stack>
				</Stack>
				{/* 選択中パートナー */}
				<Box
					sx={{
						width: "275px",
						minWidth: "275px",
						height: "auto",
						backgroundColor: color.white,
						overflow: "hidden",
						padding: "30px 0px 0px",
					}}
				>
					<IconButton
						onClick={onClose}
						sx={{
							position: "absolute",
							top: "16px",
							right: "12px",
						}}
					>
						<CloseIcon />
					</IconButton>
					{selectedMessagedata && (
						<Stack
							direction={"column"}
							justifyContent={"center"}
							alignItems={"center"}
							divider={<Divider orientation="horizontal" flexItem={true} />}
							sx={{
								width: "inherit",
								gap: "10px",
							}}
						>
							<>
								<Box
									sx={{
										width: "120px",
										height: "120px",
										border: `1px solid ${color.salmon}`,
										borderRadius: "50%",
										margin: "0px auto 15px",
										backgroundColor: color.bluePurple,
									}}
								>
									<img
										src={selectedMessagedata?.partner.avatar}
										alt={selectedMessagedata?.partner.username}
										height="120px"
										width="120px"
										style={{
											borderRadius: "50%",
											border: `1px solid ${color.salmon}`,
										}}
									/>
								</Box>
								<Typography
									variant="h3"
									sx={{
										color: color.black,
										margin: "10px 0px 9px 0px",
										textAlign: "center",
									}}
								>
									{selectedMessagedata.partner.username}
								</Typography>
								<Stack
									justifyContent="center"
									alignItems={"center"}
									divider={<Divider orientation="vertical" flexItem={true} />}
								>
									<Typography variant="subtitle3">
										レビュー数 {selectedMessagedata.partner.rating}
									</Typography>
									<Stack direction={"row"} sx={{ gap: "15px" }}>
										<Typography variant="subtitle2" sx={{ color: "#00D50A" }}>
											+{selectedMessagedata.partner.reviewPlus}
										</Typography>
										<Typography variant="subtitle2" sx={{ color: "#BFBFBF" }}>
											{selectedMessagedata.partner.reviewMiddle}
										</Typography>
										<Typography variant="subtitle2" sx={{ color: "#FF0000" }}>
											-{selectedMessagedata.partner.reviewMinus}
										</Typography>
									</Stack>
								</Stack>
								{/* <Identification /> */}
								<Box
									sx={{
										padding: "0px 27px",
									}}
								>
									<TextField
										value={profileUser.introduction}
										multiline={true}
										variant="outlined"
										rows={6}
										InputProps={{
											readOnly: true,
										}}
										sx={{
											resize: "none",
											overflowY: "auto",
											"& .MuiOutlinedInput-notchedOutline": {
												borderRadius: "20px",
											},
										}}
									/>
								</Box>
							</>
							<Stack
								direction={"column"}
								justifyContent={"start"}
								sx={{
									width: "inherit",
									padding: "0px 20px",
									gap: "20px",
								}}
							>
								<Typography variant="body1">出品リスト</Typography>
								{selectedMessagedata.partner.accounts.map((account, _) => (
									<Stack
										key={account.id}
										sx={{
											gap: "10px",
										}}
									>
										<Typography variant="subtext1">
											{account.accountName}
										</Typography>
										<Typography variant="subtext1" sx={{ opacity: 0.5 }}>
											Subscribers:{account.followers}
										</Typography>
										<Typography variant="subtext1" sx={{ opacity: 0.5 }}>
											Price: {account.price}
										</Typography>
									</Stack>
								))}
							</Stack>
						</Stack>
					)}
				</Box>
			</Stack>
		</Stack>
	);
});
