import { v4 as uuidv4 } from "uuid";

// オブジェクトからクエリパラメータを生成する
export const buildSearchParams = (input?: object): string => {
	if (!input) {
		return "";
	}

	const searchParams = new URLSearchParams();
	for (const [key, value] of Object.entries(input)) {
		if (value !== undefined && value !== null) {
			if (Array.isArray(value)) {
				for (const v of value) {
					searchParams.append(key, v);
				}
			} else {
				searchParams.set(key, value);
			}
		}
	}

	const params = searchParams.toString();
	return params ? `?${params}` : "";
};

async function urlToFile(
	url: string,
	filename: string,
	mimeType: string,
): Promise<File> {
	// S3 URLからデータをフェッチする
	const response = await fetch(url);
	if (!response.ok) throw new Error("Network response was not ok");
	// レスポンスデータをBlobとして取得
	const blob = await response.blob();
	// BlobからFileオブジェクトを作成
	return new File([blob], filename, { type: mimeType });
}

export interface FileData {
	url: string;
	mimeType: string;
}

export async function urlsToFiles(fileDatas: FileData[]): Promise<File[]> {
	const uniqueId = uuidv4();
	const filePromises = fileDatas.map((fileData, index) =>
		urlToFile(
			fileData.url,
			`image-${uniqueId}-${index + 1}`,
			fileData.mimeType,
		),
	);

	return Promise.all(filePromises);
}
