import useSWR, { type KeyedMutator } from "swr";
import type { TransactionStatusType } from "../../enum/transactionStatus";
import type { Account } from "../../types/account";
import type { User } from "../../types/users";
import { buildSearchParams } from "../../utils/url";
import { fetcher } from "./fetcher";
import type { EvaluateRequest } from "./schema/evaluateRequest";

export type PurchaseAccountRequest = {
	purchasedUserId: string;
	accountId: string;
	uuid: string;
};

export type UpdatePurchaseAccountRequest = {
	transactionStatus: TransactionStatusType;
};

export type PurchasedAccountResponse = {
	id: string;
	accountId: string;
	exhibitorUserId: string;
	purchasedUserId: string;
	uuid: string;
	transactionStatus: TransactionStatusType;
	createdAt: string;

	account: Account;
	exhibitorUser: User;
	purchasedUser: User;
};

export type PurchasedAccountListResponse = {
	id: string;
};

export const useCreatePurchaseAccount = (): {
	createAccount: (data: PurchaseAccountRequest) => Promise<void>;
} => {
	return {
		createAccount: (data: PurchaseAccountRequest): Promise<void> =>
			fetcher("/api/account/purchase/create", {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};

export type PurchasedAccountQueryParams = {
	id?: string;
	startDate?: string;
	endDate?: string;
	transactionStatus?: string;
	sort?: string;
	order?: string;
};

export const useGetPurchasedAccounts = (
	query: PurchasedAccountQueryParams | undefined = undefined,
): {
	purchasedAccounts: PurchasedAccountResponse[];
	mutate: KeyedMutator<PurchasedAccountResponse[]>;
} => {
	const key = `/api/account/purchase/list${buildSearchParams(query)}`;
	const { data, error, mutate } = useSWR<PurchasedAccountResponse[]>(
		key,
		fetcher,
	);

	if (error) {
		return { purchasedAccounts: [], mutate };
	}

	return { purchasedAccounts: data || [], mutate };
};

export const useGetPurchasedAccount = (
	accountId: string,
): {
	purchasedAccount: PurchasedAccountResponse | null;
	error: string | null;
} => {
	const { data, error } = useSWR<PurchasedAccountResponse>(
		`/api/account/purchase/${accountId}`,
		fetcher,
	);

	if (error) {
		return {
			purchasedAccount: null,
			error: error.message,
		};
	}

	return {
		purchasedAccount: data || null,
		error: null,
	};
};

export const useUpdatePurchaseAccount = (): {
	updateAccount: (
		accountId: string,
		data: UpdatePurchaseAccountRequest,
	) => Promise<void>;
} => {
	return {
		updateAccount: (
			accountId: string,
			data: UpdatePurchaseAccountRequest,
		): Promise<void> =>
			fetcher(`/api/account/purchase/${accountId}`, {
				method: "PUT",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};

export const useUpdatePurchaseAccountReview = (): {
	updateReview: (accountId: string, data: EvaluateRequest) => Promise<void>;
} => {
	return {
		updateReview: async (
			accountId: string,
			data: EvaluateRequest,
		): Promise<void> =>
			await fetcher(`/api/account/purchase/${accountId}/review`, {
				method: "PUT",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};
