import type { Bank } from "../types/bank";
import type { BankBranch } from "../types/bankBranch";
import type { CommonOption } from "../types/commonOption";

export const getBankOptions = (banks: Bank[]): CommonOption[] => {
	return (
		banks?.map((bank) => ({
			label: bank.name,
			value: bank.code,
		})) || []
	);
};

export const getBankBranchOptions = (
	bankBranches: BankBranch[],
): CommonOption[] => {
	return (
		bankBranches?.map((branch) => ({
			label: branch.branchName,
			value: branch.branchCode,
		})) || []
	);
};
