import { memo } from "react";
import { getBankBranchOptions } from "../../../../data/bankData";
import { useGetBankBranches } from "../../../../hooks/api/banks";
import type { CommonOption } from "../../../../types/commonOption";
import { CustomAutocomplete } from "../../../CustomAutocomplete";

type BankBranchPulldownProps = {
	bankCode: string;
	value: CommonOption | null;
	onChange: (
		event: React.SyntheticEvent<Element, Event>,
		newValue: CommonOption | null,
	) => void;
};

export const BankBranchPulldown = memo(
	({ bankCode, value, onChange }: BankBranchPulldownProps) => {
		const { bankBranches } = useGetBankBranches(bankCode);
		const options = getBankBranchOptions(bankBranches);
		return (
			<CustomAutocomplete
				options={options}
				value={value}
				onChange={onChange}
				sx={{
					width: "70%",
					"& .MuiAutocomplete-inputRoot": {
						height: "40px",
					},
					"& .MuiInputBase-input": {
						marginTop: "-10px",
						height: "25px",
					},
				}}
			/>
		);
	},
);
