import pica from 'pica'

export async function resizeImage(
  file: File,
  maxWidth?: number,
  maxHeight?: number,
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = async (e) => {
      if (!e.target) {
        reject(new Error('FileReader target is null'))
        return
      }
      const img = new Image()
      img.onload = async () => {
        // 元の画像のアスペクト比を維持しながら、maxWidthとmaxHeightに収まるようにサイズを計算
        const aspectRatio = img.width / img.height
        let newWidth = img.width
        let newHeight = img.height

        if (maxWidth && maxHeight) {
          if (newWidth > maxWidth) {
            newWidth = maxWidth
            newHeight = newWidth / aspectRatio
          }

          if (newHeight > maxHeight) {
            newHeight = maxHeight
            newWidth = newHeight * aspectRatio
          }
        }

        const canvas = document.createElement('canvas')
        canvas.width = newWidth
        canvas.height = newHeight

        // picaを使用してリサイズ
        await pica()
          .resize(img, canvas)
          .then((result) => pica().toBlob(result, file.type, 0.9))
          .then(resolve)
          .catch(reject)
      }
      img.onerror = reject
      img.src = e.target.result as string
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}
