import SendIcon from "@mui/icons-material/Send";
import {
	Box,
	Button,
	Divider,
	Stack,
	Tab,
	Tabs,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { memo, useContext, useEffect, useRef, useState } from "react";
import type React from "react";
import { useParams } from "react-router-dom";
import { mutate } from "swr";
import { CustomTextareaAutosize } from "../../../components/styled/TextareaAutosize";
import { UserRoleTypes } from "../../../enum/userRole";
import type { PurchasedAccountResponse } from "../../../hooks/api/purchases";
import { useGetTradeUserMessages } from "../../../hooks/api/tradeUserMessage";
import { AuthContext } from "../../../hooks/context/auth";
import { color, theme } from "../../../styles/theme";
import { PurchaseInfoList } from "./PurchaseInfoList";

const CustomTabs = styled(Tabs)({
	"& .MuiTabs-indicator": {
		border: `2px solid ${color.red}`,
		top: 0,
	},
});

const CustomTab = styled(Tab)({
	textTransform: "none",
	width: "49.9%",
	maxWidth: "50%",
	backgroundColor: color.midGray,
	marginRight: "2px",
	borderTop: `3px solid ${color.darkGray}`,
	fontWeight: "bold",
	fontFamily: [
		"-apple-system",
		"BlinkMacSystemFont",
		'"Segoe UI"',
		"Roboto",
		'"Helvetica Neue"',
		"Arial",
		"sans-serif",
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(","),
	color: "rgba(0, 0, 0, 0.54)",
	"&:last-child": {
		marginRight: 0,
	},
	"&:hover": {
		opacity: 1,
	},
	"&.Mui-selected": {
		backgroundColor: color.white,
		color: color.black,
		fontWeight: "bold",
	},
	"&.Mui-focusVisible": {
		backgroundColor: "#d1eaff",
	},
});

const CustomTabPanel = styled(TabPanel)({
	backgroundColor: color.white,
	borderTop: `1px solid ${color.matGray}`,
});

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					<Typography>{children}</Typography>
				</Box>
			)}
		</Box>
	);
}

type TradeTabsProps = {
	purchasedAccount: PurchasedAccountResponse;
};

export const TradeTabs = memo(({ purchasedAccount }: TradeTabsProps) => {
	const wsRef = useRef<WebSocket | null>(null);
	const [message, setMessage] = useState("");
	const authContext = useContext(AuthContext);
	const [value, setValue] = useState(0);
	const { id } = useParams<{ id: string }>();
	const { responses, error } = useGetTradeUserMessages(id || "");

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const sendMessage = (message: string) => {
		if (
			wsRef.current &&
			wsRef.current.readyState === WebSocket.OPEN &&
			message.trim()
		) {
			const receiverId =
				authContext?.auth?.id === purchasedAccount.purchasedUserId
					? purchasedAccount.exhibitorUserId
					: purchasedAccount.purchasedUserId;
			const messageData = {
				accountId: purchasedAccount.accountId,
				senderId: authContext?.auth?.id,
				receiverId: receiverId,
				content: message,
			};
			wsRef.current.send(JSON.stringify(messageData));
			setMessage("");
		} else {
			console.log("WebSocket is not open. Message not sent.");
		}
	};

	useEffect(() => {
		// WebSocket接続を確立する関数
		const connectWebSocket = () => {
			const newWs = new WebSocket(
				`${process.env.REACT_APP_WEB_SOCKET_URL}/trade`,
			);

			newWs.onopen = () => {
				console.log("WebSocket connection established");
				// クライアントの登録メッセージを送信
				const registerMessage = {
					type: "register",
					userID: authContext?.auth?.id, // 適切なユーザーIDを設定
				};
				newWs.send(JSON.stringify(registerMessage));
			};

			newWs.onmessage = async (event) => {
				const incomingMessage = JSON.parse(event.data);
				// ここで状態を更新して受信メッセージを含めます
				await mutate(`/api/user_message/${authContext?.auth?.id}`);
			};

			newWs.onerror = (error) => {
				console.error("WebSocket error:", error);
			};

			newWs.onclose = (event) => {
				console.log("WebSocket connection closed. Attempting to reconnect...");
				setTimeout(connectWebSocket, 5000); // 5秒後に再接続を試みる
			};

			wsRef.current = newWs;
		};

		// コンポーネントのマウント時にWebSocket接続を行う
		connectWebSocket();

		// コンポーネントのアンマウント時にWebSocket接続をクローズする
		return () => {
			if (wsRef.current) {
				wsRef.current.close();
			}
		};
	}, [authContext?.auth?.id]);

	if (!authContext?.auth) {
		return (
			<Box>
				<Typography>ログインしてください。</Typography>
			</Box>
		);
	}

	const isAdmin = authContext.auth.role === UserRoleTypes.ADMIN;

	return (
		<Box sx={{ width: "100%" }}>
			<CustomTabs
				value={value}
				onChange={handleChange}
				aria-label="trade account tabs"
				sx={{
					width: "100%",
				}}
			>
				<CustomTab label="チャット" />
				<CustomTab label="取引情報" />
			</CustomTabs>
			<CustomTabPanel value={value} index={0}>
				<Box
					sx={{
						padding: "24px",
					}}
				>
					{responses && responses.length > 0 ? (
						responses.map((response, index) => (
							<Box key={`${response.updatedAt}-${index}`}>
								<Typography>{response.content}</Typography>
							</Box>
						))
					) : (
						<Typography>メッセージがありません。</Typography>
					)}
				</Box>
				<Divider orientation="horizontal" />
				<Box
					sx={{
						padding: "24px",
					}}
				>
					<Stack
						sx={{
							gap: theme.spacing(2),
						}}
					>
						<CustomTextareaAutosize
							disabled={isAdmin}
							aria-label="empty textarea"
							placeholder={"メッセージを入力"}
							minRows={4}
							onChange={(e) => setMessage(e.target.value)}
							sx={{
								resize: "none",
							}}
						/>
						<Button
							disabled={isAdmin}
							startIcon={<SendIcon />}
							sx={{
								borderRadius: 1,
							}}
							onClick={() => sendMessage(message)}
						>
							メッセージを送信する
						</Button>
					</Stack>
				</Box>
			</CustomTabPanel>
			<CustomTabPanel
				value={value}
				index={1}
				sx={{
					padding: "24px",
				}}
			>
				<Stack
					sx={{
						gap: theme.spacing(2),
					}}
				>
					<Typography
						variant="h3"
						sx={{
							backgroundColor: color.black,
							color: color.white,
							padding: theme.spacing(2),
						}}
					>
						購入情報
					</Typography>
					{purchasedAccount.account && (
						<PurchaseInfoList purchasedAccount={purchasedAccount} />
					)}
				</Stack>
			</CustomTabPanel>
		</Box>
	);
});
