import { buildSearchParams } from "../../utils/url";

export type ErrorWithStatus = Error & { status: number };

interface FetcherConfig {
	method?: "GET" | "POST" | "PUT" | "DELETE"; // 他のHTTPメソッドも追加可能
	headers?: HeadersInit;
	body?: BodyInit | null;
	queryParams?: { [key: string]: string | number };
}

export const fetcher = async <T>(
	url: string,
	config: FetcherConfig = {},
): Promise<T> => {
	const { method = "GET", headers, body, queryParams } = config;
	// クエリパラメータの処理
	const queryString = queryParams ? buildSearchParams(queryParams) : "";

	// 完全なURLを構築
	const fullUrl = `${process.env.REACT_APP_API_BASE_URL}${url}${queryString}`;
	console.log("fullUrl", fullUrl);

	const response = await fetch(fullUrl, {
		method,
		headers,
		body,
		credentials: "include",
	});

	if (!response.ok) {
		const res = response.json() as Promise<{ error: string }>;
		const errorMessage = (await res).error;
		// ErrorWithStatus型にキャスト
		const error = new Error(errorMessage) as ErrorWithStatus;
		error.status = response.status;
		throw error;
	}

	if (response.status === 204) {
		return {} as T;
	}

	return response.json() as Promise<T>;
};
