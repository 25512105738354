import {
	Button,
	Divider,
	List,
	ListItem,
	ListItemText,
	Stack,
	Typography,
} from "@mui/material";
import { memo, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
	useGetAccountDetail,
	useUpdateAccount,
} from "../../../hooks/api/accounts";
import type { ErrorWithStatus } from "../../../hooks/api/fetcher";
import { useCreatePurchaseAccount } from "../../../hooks/api/purchases";
import { AuthContext } from "../../../hooks/context/auth";
import { color } from "../../../styles/theme";
import { SellingStatus } from "../../../types/account";

export const AccountPurchase = memo(() => {
	const navigate = useNavigate();
	const authContext = useContext(AuthContext);
	const { id } = useParams<{ id: string }>();
	const { createAccount } = useCreatePurchaseAccount();
	const { updateAccount } = useUpdateAccount();
	const { account, error: accountError } = useGetAccountDetail(id || "");
	const systemFee = 0;

	const purchasePrice = account?.price ? Number(account?.price) + systemFee : 0;

	const handlePurchaseAccountClick = async () => {
		if (!account) {
			return;
		}

		if (!authContext?.auth) {
			return;
		}
		const uniqueId = uuidv4();
		await createAccount({
			accountId: account.id,
			purchasedUserId: authContext.auth.id,
			uuid: uniqueId,
		}).catch((error: ErrorWithStatus) => {
			if (error.status === 409) {
				navigate(`/accounts/${account.id}/purchase/${uniqueId}`);
			}
			return;
		});

		const imageUrls = [account.image].concat(
			account.subImages?.map((subImage) => subImage.imageUrl) || [],
		);

		await updateAccount({
			id: account.id,
			userId: account.userId,
			accountName: account.accountName,
			categoryId: account.categoryId,
			platform: account.platform,
			imageUrls: imageUrls,
			description: account.description,
			followers: account.followers,
			price: account.price,
			sellingStatus: SellingStatus.Trading,
		});

		navigate(`/accounts/${account.id}/purchase/${uniqueId}`);
	};
	return (
		<>
			<Stack
				alignItems={"center"}
				sx={{
					height: "100vh",
					backgroundColor: color.paleGray,
				}}
			>
				<Stack
					justifyContent={"center"}
					alignItems={"center"}
					sx={{
						width: "600px",
						marginTop: "50px",
						gap: "10px",
					}}
				>
					<Stack
						sx={{
							width: "inherit",
						}}
					>
						<Stack
							justifyContent={"center"}
							alignItems={"center"}
							sx={{
								height: "40px",
								width: "inherit",
								backgroundColor: "black",
							}}
						>
							<Typography variant="h4" color={color.white}>
								購入手続き
							</Typography>
						</Stack>
						<Stack
							direction={"row"}
							spacing={2}
							sx={{
								width: "inherit",
								height: "140px",
								padding: "20px",
								backgroundColor: color.white,
							}}
						>
							<img
								src={account?.image}
								alt={`${account?.accountName}`}
								width="100"
								height="100"
								loading="lazy"
							/>
							<Typography
								variant="subtitle1"
								sx={{
									width: "100%",
								}}
							>
								{account?.accountName}
							</Typography>
							<Stack justifyContent={"center"}>
								<Typography variant="h4" color={color.red}>
									{`¥${account?.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
								</Typography>
							</Stack>
						</Stack>
					</Stack>
					<Stack
						sx={{
							width: "inherit",
							backgroundColor: color.white,
						}}
					>
						<Stack
							justifyContent={"center"}
							sx={{
								backgroundColor: color.black,
								height: "35px",
								padding: "10px",
							}}
						>
							<Typography variant="subtitle1" color={color.white}>
								決済方法
							</Typography>
						</Stack>
						<List
							sx={{
								padding: "0px",
							}}
						>
							<ListItemText>
								<Stack
									direction={"row"}
									alignItems={"center"}
									sx={{
										width: "100%",
										height: "32px",
										padding: "8px 16px",
									}}
								>
									<>
										<Typography variant="subtitle1" color={color.midGray}>
											銀行振込
										</Typography>
									</>
								</Stack>
							</ListItemText>
							<Divider />
							<ListItem>
								<ListItemText>
									<Stack direction={"row"} justifyContent={"space-between"}>
										<Typography variant="subtitle3">商品金額</Typography>
										<Typography variant="subtitle3">
											{`${account?.price.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												",",
											)}円`}
										</Typography>
									</Stack>
								</ListItemText>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText>
									<Stack direction={"row"} justifyContent={"space-between"}>
										<Typography variant="subtitle3">決済手数料</Typography>
										<Typography variant="subtitle3">{`${String(
											systemFee,
										).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円`}</Typography>
									</Stack>
								</ListItemText>
							</ListItem>
							<Divider />
							<ListItem>
								<ListItemText>
									<Stack direction={"row"} justifyContent={"space-between"}>
										<Typography variant="h4">購入金額</Typography>
										<Typography variant="h4">{`${String(purchasePrice).replace(
											/\B(?=(\d{3})+(?!\d))/g,
											",",
										)}円`}</Typography>
									</Stack>
								</ListItemText>
							</ListItem>
						</List>
					</Stack>
					<Button
						sx={{
							width: "inherit",
							borderRadius: "0px",
						}}
						onClick={handlePurchaseAccountClick}
					>
						<Typography variant="subtitle1" color={color.white}>
							購入する
						</Typography>
					</Button>
					<Typography variant="subtext1" textAlign={"center"}>
						ここから先は売り手と買い手のみが閲覧できる「取引チャット」へ進みます。
					</Typography>
				</Stack>
			</Stack>
		</>
	);
});
