import {
	Avatar,
	Button,
	Snackbar,
	Stack,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material";
import { memo, useState } from "react";
import { BankAccountTypeStrings } from "../../../../enum/bankAccountType";
import {
	SaleStatusStrings,
	SaleStatusTypes,
} from "../../../../enum/saleStatus";
import {
	type WithdrawalRequest,
	useUpdateWithdrawal,
} from "../../../../hooks/api/withdrawal";
import { color } from "../../../../styles/theme";
import type { Withdrawal } from "../../../../types/withdrawal";
import { SaleStatusModal } from "./SaleStatusModal";

export const WithdrawalRow = memo(
	({
		withdrawal,
		onChange,
	}: { withdrawal: Withdrawal; onChange: () => void }) => {
		const [open, setOpen] = useState(false);
		const [snackbarOpen, setSnackbarOpen] = useState(false);
		const [snackbarMessage, setSnackbarMessage] = useState<string>("");
		const [selectedStatus, setSelectedStatus] = useState<SaleStatusTypes>();
		const { updateWithdrawal } = useUpdateWithdrawal();

		const user = withdrawal.user;
		const userBankAccount = withdrawal.userBankAccount;
		if (!user || !userBankAccount) return null;

		const handleStatusClick = (status?: SaleStatusTypes, open = true) => {
			setSelectedStatus(status);
			setOpen(open);
		};

		const handleUpdateStatus = async () => {
			if (!selectedStatus) return;
			try {
				const request: WithdrawalRequest = {
					userId: user.id.toString(),
					userBankAccountId: userBankAccount.id.toString(),
					amount: withdrawal.amount,
					remarks: withdrawal.remarks,
					status: selectedStatus,
				};

				await updateWithdrawal(request);
				setSnackbarMessage("取引状態を変更しました");
			} catch (error) {
				setSnackbarMessage("取引状態の変更に失敗しました");
			} finally {
				setSnackbarOpen(true);
				handleStatusClick(undefined, false);
				onChange();
			}
		};

		return (
			<>
				<TableRow
					sx={{
						height: "100px",
					}}
					onClick={() => {}}
				>
					<TableCell align="left" sx={{ width: 100, height: 100 }}>
						<Typography variant="h2" sx={{ color: color.red }}>
							{user.id}
						</Typography>
					</TableCell>
					<TableCell align="left" sx={{ width: 100, height: 100 }}>
						<Avatar
							src={user.avatar}
							alt={`${user.id}-avatar`}
							sx={{
								width: 100,
								height: 100,
							}}
						/>
					</TableCell>
					<TableCell
						sx={{
							verticalAlign: "top",
						}}
					>
						<Stack
							direction={"column"}
							sx={{
								gap: 2,
							}}
						>
							<Typography
								variant="h2"
								noWrap={true}
								sx={{
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
							>
								{user.username}
							</Typography>
						</Stack>
					</TableCell>
					<TableCell width={"100%"}>
						<Typography variant="h2" textAlign={"end"}>
							{`¥${withdrawal.amount
								.toString()
								.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円`}
						</Typography>
					</TableCell>
					<TableCell width={"300px"}>
						<Stack direction={"row"} spacing={1} justifyContent={"end"}>
							<Typography variant="h4" textAlign={"end"}>
								{userBankAccount.bankName}
							</Typography>
							<Typography variant="h4" textAlign={"end"}>
								{userBankAccount.branchName}
							</Typography>
						</Stack>
						<Stack direction={"row"} spacing={2} justifyContent={"end"}>
							<Typography variant="h4" textAlign={"end"}>
								{userBankAccount.accountType
									? BankAccountTypeStrings[userBankAccount.accountType]
									: "未設定"}
							</Typography>
							<Typography variant="h4" textAlign={"end"}>
								{userBankAccount.accountNumber}
							</Typography>
						</Stack>
						<Typography width={"300px"} variant="h4" textAlign={"end"}>
							{userBankAccount.accountName}
						</Typography>
					</TableCell>
					<TableCell
						sx={{
							width: 100,
						}}
					>
						<Typography width={"100px"} variant="h3" sx={{ color: color.red }}>
							{SaleStatusStrings[withdrawal.status]}
						</Typography>
					</TableCell>
					<TableCell
						sx={{
							display: "flex",
							flexDirection: "column",
							width: "170px",
							padding: "10px",
							gap: 2,
						}}
					>
						<Button
							color="info"
							onClick={() => handleStatusClick(SaleStatusTypes.PENDING)}
							sx={{
								width: 160,
							}}
						>
							<Typography variant="subtitle1">申請中に変更</Typography>
						</Button>
						<Button
							onClick={() => handleStatusClick(SaleStatusTypes.APPROVED)}
							sx={{
								width: 160,
							}}
						>
							<Typography variant="subtitle1">承認済みに変更</Typography>
						</Button>
						<Button
							color="secondary"
							onClick={() => handleStatusClick(SaleStatusTypes.REJECTED)}
							sx={{
								width: 160,
							}}
						>
							<Typography variant="subtitle1">却下済みに変更</Typography>
						</Button>
					</TableCell>
				</TableRow>
				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={snackbarOpen}
					autoHideDuration={60000}
					message={snackbarMessage}
					onClose={() => setSnackbarOpen(false)}
				/>
				<SaleStatusModal
					open={open}
					statusName={SaleStatusStrings[selectedStatus || 1]}
					onUpdate={handleUpdateStatus}
					onClose={() => handleStatusClick(undefined, false)}
				/>
			</>
		);
	},
);
