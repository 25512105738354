import { Stack, Typography } from "@mui/material";
import { memo } from "react";
import { TransactionStatusTypes } from "../../../enum/transactionStatus";
import type { PurchasedAccountResponse } from "../../../hooks/api/purchases";
import { EvaluateForm } from "./EvaluateForm";

type ReviewFormProps = {
	purchasedAccount: PurchasedAccountResponse;
	userId: string;
};

export const ReviewForm = memo(
	({ purchasedAccount, userId }: ReviewFormProps) => {
		const isBuyer = purchasedAccount.purchasedUserId === userId;
		const accountId = purchasedAccount.accountId;
		const account = purchasedAccount.account;
		const purchasedUserId = isBuyer
			? purchasedAccount.purchasedUserId
			: purchasedAccount.exhibitorUserId;
		const exhibitorUserId = isBuyer
			? purchasedAccount.exhibitorUserId
			: purchasedAccount.purchasedUserId;

		if (
			isBuyer &&
			purchasedAccount.transactionStatus !==
				TransactionStatusTypes.WAITING_BUYER_EVALUATION
		) {
			return null;
		}

		if (
			!isBuyer &&
			purchasedAccount.transactionStatus !==
				TransactionStatusTypes.WAITING_EXHIBITOR_EVALUATION
		) {
			return null;
		}

		return (
			<Stack
				alignItems={"center"}
				sx={{
					padding: "20px",
					marginTop: "20px",
					backgroundColor: "white",
					gap: "10px",
				}}
			>
				<Typography variant={"h4"} textAlign={"center"}>
					レビューを送信して取引を完了
				</Typography>
				<EvaluateForm
					accountId={accountId}
					points={Number(account.price) - Number(account.price) * 0.1}
					isBuyer={isBuyer}
					purchasedUserId={purchasedUserId}
					exhibitorUserId={exhibitorUserId}
				/>
			</Stack>
		);
	},
);
