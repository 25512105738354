import { memo } from 'react'
import { MyMessage } from './MyMessage'
import { PartnerMessage } from './PartnerMessage'
import type { Message } from '../../../hooks/api/userMessage'

export const ChatMessage = memo(
  ({ message, isPartner }: { message: Message; isPartner: boolean }) => {
    return isPartner ? <PartnerMessage message={message} /> : <MyMessage message={message} />
  },
)
