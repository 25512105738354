import { Answer } from './Answer'
import { memo } from 'react'
import { Question } from './Question'
import type { AccountInquiry } from '../../../types/account'

export const Inquiry = memo(
  ({ isOwner, inquiry }: { isOwner: boolean; inquiry: AccountInquiry }) => {
    return isOwner ? <Answer inquiry={inquiry} /> : <Question inquiry={inquiry} />
  },
)
