import { Button, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { memo, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TransactionStatusStrings } from "../../../enum/transactionStatus";
import { UserRoleTypes } from "../../../enum/userRole";
import type { PurchasedAccountResponse } from "../../../hooks/api/purchases";
import { AuthContext } from "../../../hooks/context/auth";
import { color } from "../../../styles/theme";
import { TransactionStatusModal } from "./TransactionStatusModal";

export const TradingRow = memo(
	({
		purchasedAccount,
		onChange,
	}: { purchasedAccount: PurchasedAccountResponse; onChange: () => void }) => {
		const authContext = useContext(AuthContext);
		const [open, setOpen] = useState(false);
		const navigate = useNavigate();
		const handleClose = () => {
			setOpen(false);
			onChange();
		};
		return (
			<>
				<TableRow
					sx={{
						height: "100px",
					}}
					onClick={() => {
						navigate(
							`/accounts/${purchasedAccount.id}/purchase/${purchasedAccount.uuid}`,
						);
					}}
				>
					<TableCell align="left" sx={{ width: 100, height: 100 }}>
						<Typography variant="h2" sx={{ color: color.red }}>
							{purchasedAccount.id}
						</Typography>
					</TableCell>
					<TableCell align="left" sx={{ width: 100, height: 100 }}>
						<img
							src={purchasedAccount.account.image}
							alt={`${purchasedAccount.account.accountName}`}
							width="100"
							height="100"
							loading="lazy"
						/>
					</TableCell>
					<TableCell
						sx={{
							verticalAlign: "top",
						}}
					>
						<Stack
							direction={"column"}
							sx={{
								gap: 2,
							}}
						>
							<Typography
								variant="h2"
								noWrap={true}
								sx={{
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
							>
								{purchasedAccount.account.accountName}
							</Typography>
							<Typography
								variant="h4"
								noWrap={true}
								sx={{
									maxWidth: "500px",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
							>
								{purchasedAccount.account.description}
							</Typography>
						</Stack>
					</TableCell>
					<TableCell
						sx={{
							width: "100%",
						}}
					>
						<Typography
							variant="h2"
							textAlign={"end"}
							sx={{ color: color.red }}
						>
							{`¥${purchasedAccount.account.price.replace(
								/\B(?=(\d{3})+(?!\d))/g,
								",",
							)}円`}
						</Typography>
					</TableCell>
					{authContext?.auth?.role === UserRoleTypes.ADMIN && (
						<TableCell align="right">
							<Stack
								justifyContent={"space-between"}
								sx={{
									height: "100px",
								}}
							>
								<Typography
									variant="h4"
									sx={{
										textAlign: "center",
									}}
								>
									{TransactionStatusStrings[purchasedAccount.transactionStatus]}
								</Typography>
								<Button
									sx={{
										width: "150px",
									}}
									onClick={(event) => {
										event.stopPropagation();
										setOpen(true);
									}}
								>
									<Typography variant="subtitle1">変更する</Typography>
								</Button>
							</Stack>
						</TableCell>
					)}
				</TableRow>
				<TransactionStatusModal
					accountId={purchasedAccount.id}
					open={open}
					onClose={handleClose}
					value={purchasedAccount.transactionStatus}
				/>
			</>
		);
	},
);
