import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Box, IconButton, Stack } from "@mui/material";
import { memo, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TablePagination } from "../../../components/Pagination";
import { Footer } from "../../../components/common/layouts/Footer";
import { useGetAccounts } from "../../../hooks/api/accounts";
import { AuthContext } from "../../../hooks/context/auth";
import { color } from "../../../styles/theme";
import { AccountHeader } from "./AccountHeader";
import { AccountTable } from "./AccountTable";

export const Accounts: React.FC = memo(() => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const accountName = searchParams.get("accountName") ?? undefined;
	const keyword = searchParams.get("keyword") ?? undefined;
	const followersFrom = searchParams.get("followersFrom") ?? undefined;
	const followersTo = searchParams.get("followersTo") ?? undefined;
	const priceFrom = searchParams.get("priceFrom") ?? undefined;
	const priceTo = searchParams.get("priceTo") ?? undefined;
	const sort = searchParams.get("sort") ?? undefined;
	const order = searchParams.get("order") ?? undefined;
	const page = searchParams.get("p") ?? undefined;
	const currentPage = page ? Number(page) : 1;

	const { accounts, count } = useGetAccounts({
		accountName,
		keyword,
		followersFrom,
		followersTo,
		priceFrom,
		priceTo,
		sort,
		order,
		page: currentPage,
	});

	return (
		<>
			<AccountHeader
				filter={{
					accountName,
					keyword,
					followersFrom,
					followersTo,
					priceFrom,
					priceTo,
				}}
			/>
			<Stack sx={{ gap: 2 }}>
				{accounts && <AccountTable accounts={accounts} />}
				<TablePagination
					total={count}
					displayCount={10}
					currentPage={currentPage}
				/>
			</Stack>
			{authContext?.auth && (
				<Box
					sx={{
						position: "fixed",
						bottom: "10px",
						right: "10px",
					}}
				>
					<IconButton
						sx={{
							width: "100px",
							height: "100px",
							border: `1px solid ${color.midGray}`,
							borderRadius: "50%",
							backgroundColor: color.red,
							"&:hover": {
								backgroundColor: color.red,
								boxShadow: `0 0 10px ${color.red}, 0 0 20px ${color.red}`,
							},
						}}
						onClick={() => navigate("/accounts/create")}
					>
						<AddAPhotoIcon
							sx={{
								color: color.white,
								fontSize: "40px",
							}}
						/>
					</IconButton>
				</Box>
			)}
			<Footer />
		</>
	);
});
