import useSWR, { type KeyedMutator } from "swr";
import type { Withdrawal } from "../../types/withdrawal";
import { buildSearchParams } from "../../utils/url";
import { fetcher } from "./fetcher";

export type WithdrawalListResponse = {
	withdrawals: Withdrawal[];
};

export type WithdrawalQueryParams = {
	userId?: string;
	saleStatus?: number;
	sort?: string;
	order?: string;
};

export type WithdrawalRequest = {
	userId: string;
	userBankAccountId: string;
	amount: number;
	remarks: string;
	status: number;
};

export const useWithdrawalList = (
	query: WithdrawalQueryParams | undefined = undefined,
): {
	withdrawals: Withdrawal[];
	mutate: KeyedMutator<Withdrawal[]>;
} => {
	const key = `/api/withdrawal/list${buildSearchParams(query)}`;
	const { data, error, mutate } = useSWR<Withdrawal[]>(key, fetcher);

	if (error) {
		return {
			withdrawals: [],
			mutate,
		};
	}

	return {
		withdrawals: data || [],
		mutate,
	};
};

export const useCreateWithdrawal = (): {
	createWithdrawal: (data: WithdrawalRequest) => Promise<void>;
} => {
	return {
		createWithdrawal: (data: WithdrawalRequest): Promise<void> =>
			fetcher("/api/withdrawal/create", {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};

export const useUpdateWithdrawal = (): {
	updateWithdrawal: (data: WithdrawalRequest) => Promise<void>;
} => {
	return {
		updateWithdrawal: (data: WithdrawalRequest): Promise<void> =>
			fetcher("/api/withdrawal/update", {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};
