import { Stack } from "@mui/material";
import { useContext } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { TablePagination } from "../../../../components/Pagination";
import { UserRoleTypes } from "../../../../enum/userRole";
import {
	type WithdrawalQueryParams,
	useWithdrawalList,
} from "../../../../hooks/api/withdrawal";
import { AuthContext } from "../../../../hooks/context/auth";
import { WithdrawalRequestHeader } from "./WithdrawalRequestHeader";
import { WithdrawalRequestTable } from "./WithdrawalRequestTable";

export const WithdrawalRequestList = () => {
	const auth = useContext(AuthContext);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const userId = searchParams.get("userId") ?? undefined;
	const saleStatus = searchParams.get("saleStatus") ?? undefined;
	const sort = searchParams.get("sort") ?? undefined;
	const order = searchParams.get("order") ?? undefined;

	const queryParams: WithdrawalQueryParams = {
		userId,
		saleStatus: saleStatus ? Number(saleStatus) : undefined,
		sort,
		order,
	};
	const { withdrawals, mutate } = useWithdrawalList(queryParams);
	if (auth?.auth?.role !== UserRoleTypes.ADMIN) return <></>;

	const handleSearch = (params: WithdrawalQueryParams) => {
		searchParams.delete("userId");
		searchParams.delete("saleStatus");
		searchParams.delete("sort");
		searchParams.delete("order");
		if (params.userId) {
			searchParams.set("userId", params.userId);
		}
		if (params.saleStatus) {
			searchParams.set("saleStatus", params.saleStatus.toString());
		}
		if (params.sort) {
			searchParams.set("sort", params.sort);
		}
		if (params.order) {
			searchParams.set("order", params.order);
		}

		navigate(`?${searchParams.toString()}`);
	};

	return (
		<Stack
			alignItems={"center"}
			sx={{
				width: "100%",
				marginTop: "50px",
			}}
		>
			<WithdrawalRequestHeader
				queryParams={queryParams}
				onSearch={handleSearch}
			/>
			<Stack
				sx={{
					width: "inherit",
					gap: 2,
				}}
			>
				<WithdrawalRequestTable withdrawals={withdrawals} refetch={mutate} />
				<TablePagination
					total={withdrawals?.length ?? 0}
					displayCount={30}
					currentPage={1}
				/>
			</Stack>
		</Stack>
	);
};
