import {
	Box,
	Divider,
	Link,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { color } from "../../../styles/theme";
import type { Account } from "../../../types/account";
import { AccountRow } from "./AccountRow";

export const AccountTable = memo(({ accounts }: { accounts: Account[] }) => {
	const navigate = useNavigate();
	const [sort, setSort] = useState("");
	const [order, setOrder] = useState("");
	useEffect(() => {
		if (!sort || !order) return;

		const searchParams = new URLSearchParams();
		searchParams.set("sort", sort);
		searchParams.set("order", order);
		return navigate(`?${searchParams.toString()}`);
	}, [sort, order, navigate]);

	const handleSortChange = (newSort: string) => {
		if (sort === newSort) {
			setOrder(order === "asc" ? "desc" : "asc");
		} else {
			setSort(newSort);
			setOrder("asc");
		}
	};

	return (
		<Box sx={{ marginTop: "53px" }}>
			<TableContainer
				sx={{
					overflowX: "hidden",
				}}
			>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell colSpan={3}>
								<Stack
									direction={"row"}
									alignItems={"center"}
									spacing={2}
									divider={<Divider orientation={"vertical"} flexItem={true} />}
								>
									<Typography variant="h4" sx={{ opacity: 0.5 }}>
										並び替え:
									</Typography>
									<Link
										id="recommended"
										onClick={() => handleSortChange("recommended")}
										sx={{
											color: color.black,
											textDecorationColor: color.black,
											cursor: "pointer",
										}}
									>
										<Typography variant="subtitle1">おすすめ</Typography>
									</Link>
									<Link
										id="price"
										onClick={() => handleSortChange("price")}
										sx={{
											color: color.black,
											textDecorationColor: color.black,
											cursor: "pointer",
										}}
									>
										<Typography variant="subtitle1">価格</Typography>
									</Link>
									<Link
										id="subscriberCount"
										onClick={() => handleSortChange("subscriberCount")}
										sx={{
											color: color.black,
											textDecorationColor: color.black,
											cursor: "pointer",
										}}
									>
										<Typography variant="subtitle1">フォロワー数</Typography>
									</Link>
								</Stack>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{accounts.map((account) => (
							<AccountRow key={account.id} account={account} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
});
