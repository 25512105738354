import { z } from "zod";

export const EvaluateRequestSchema = z.object({
	review: z.number(),
	comment: z.string().optional(),
	exhibitorUserId: z.string(),
	purchasedUserId: z.string(),
	transactionStatus: z.number(),
});

export type EvaluateRequest = z.infer<typeof EvaluateRequestSchema>;
