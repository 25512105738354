import { useState } from "react";
import useSWR, { type KeyedMutator } from "swr";
import type {
	Account,
	AccountCategory,
	AccountInquiry,
} from "../../types/account";
import { buildSearchParams } from "../../utils/url";
import { fetcher } from "./fetcher";

type AccountCategoryData = { accountCategories: AccountCategory[] };
type AccountsData = { accounts: Account[]; count: number };
type AccountsInquiryData = { accountInquiries: AccountInquiry[] };
type AccountDetailData = { account: Account };
export type AccountFormRequest = {
	userId: string;
	accountName: string;
	categoryId: number;
	platform: string;
	description: string;
	followers?: string;
	price: string;
};

export type AccountFormResponse = {
	id: number;
	userId: string;
	accountName: string;
	categoryId: number;
	description: string;
	followers?: string;
	price: string;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	imageUrls: string[];
	sellingStatus: number;
	subscriberCount: string;
	platform: string;
	url: string;
	name: string;
	image: string;
	subImages: string | null;
};

export type AccountUpdateRequest = {
	id: string;
	userId: string;
	accountName: string;
	categoryId: string;
	imageUrls: string[];
	platform: string;
	description: string;
	followers?: string;
	price: string;
	sellingStatus: number;
};

export type CreateAccountInquiryRequest = {
	accountId: string;
	userId: string;
	comment: string;
};

export type AccountQueryParams = {
	accountName?: string;
	userID?: string;
	keyword?: string;
	followersFrom?: string;
	followersTo?: string;
	priceFrom?: string;
	priceTo?: string;
	sort?: string;
	order?: string;
	page?: number;
};

export const useGetAccountCategories = (): {
	data: AccountCategoryData;
} => {
	const key = "/api/account/categories";
	const { data, error } = useSWR<AccountCategoryData>(key, fetcher);

	if (error || !data) {
		return {
			data: { accountCategories: [] },
		};
	}

	return {
		data: data,
	};
};

export const useGetAccounts = (
	query: AccountQueryParams | undefined = undefined,
): {
	accounts: Account[];
	count: number;
} => {
	const key = `/api/accounts${buildSearchParams(query)}`;
	const { data, error } = useSWR<AccountsData>(key, fetcher);

	if (error) {
		return {
			accounts: [],
			count: 0,
		};
	}

	return {
		accounts: data?.accounts || [],
		count: data?.count || 0,
	};
};

export const useGetAccountDetail = (
	id: string,
): {
	account?: Account;
	isLoading?: boolean;
	error?: string;
} => {
	const key = `/api/account/${id}`;
	const { data, error, isLoading } = useSWR<AccountDetailData>(key, fetcher);

	if (error) {
		return {
			account: undefined,
			isLoading: false,
			error: error.message,
		};
	}

	return {
		account: data?.account,
		isLoading,
	};
};

export const useGetAccountInquiries = (
	id: number,
): {
	accountInquiries?: AccountInquiry[];
	error: string | null;
	mutate: KeyedMutator<AccountsInquiryData>;
} => {
	const key = `/api/account/${id}/inquiry`;
	const { data, error, mutate } = useSWR<AccountsInquiryData>(key, fetcher);

	if (error) {
		return {
			accountInquiries: [],
			error: error.message,
			mutate,
		};
	}

	return {
		accountInquiries: data?.accountInquiries || [],
		error: null,
		mutate,
	};
};

export const useCreateAccount = (): {
	createAccount: (
		data: AccountFormRequest,
	) => Promise<{ account: AccountFormResponse }>;
} => {
	return {
		createAccount: (
			data: AccountFormRequest,
		): Promise<{ account: AccountFormResponse }> =>
			fetcher("/api/account/create", {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};

export const useUpdateAccount = (): {
	updateAccount: (
		data: AccountUpdateRequest,
	) => Promise<{ account: AccountFormResponse }>;
} => {
	return {
		updateAccount: (
			data: AccountUpdateRequest,
		): Promise<{ account: AccountFormResponse }> =>
			fetcher("/api/account/update", {
				method: "PUT",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};

export const useDeleteAccountImages = () => {
	const deleteAccountImages = async (accountId: string) => {
		try {
			const data = await fetcher(`/api/account/${accountId}/images`, {
				method: "DELETE",
			});
			return data;
		} catch (error) {
			// biome-ignore lint/complexity/noUselessCatch: <explanation>
			throw error;
		}
	};
	return { deleteAccountImages };
};

export const useDeleteAccount = (): {
	deleteAccount: (accountId: string) => Promise<void>;
} => {
	return {
		deleteAccount: (accountId: string): Promise<void> =>
			fetcher(`/api/account/${accountId}`, {
				method: "DELETE",
			}),
	};
};

export const useCreateAccountInquiry = () => {
	const [error, setError] = useState<string | null>(null);

	const createAccountInquiry = async (request: CreateAccountInquiryRequest) => {
		setError(null);

		try {
			const data = await fetcher("/api/account/inquiry/create", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(request),
			});
			return data; // ここでサーバーからのレスポンスを返す
		} catch (error) {
			setError("問い合わせの作成に失敗しました。");
			throw error; // エラーを再スローして、呼び出し元でキャッチできるようにする
		}
	};

	return { createAccountInquiry, error };
};

// export const useCreateCheckoutSession = () => {
// 	const createCheckoutSession = async () => {
// 		const data = await fetcher("/api/account/create-checkout-session", {
// 			method: "POST",
// 			headers: {
// 				"Content-Type": "application/json",
// 			},
// 		});
// 		return data;
// 	};

// 	return { createCheckoutSession };
// };
