import { zodResolver } from "@hookform/resolvers/zod";
import { PhotoCamera } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Button,
	IconButton,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import {
	type AccountOption,
	getCategoryOptions,
} from "../../../../data/accountData";
import { useGetAccountCategories } from "../../../../hooks/api/accounts";
import {
	type AccountRequest,
	AccountRequestSchema,
} from "../../../../hooks/api/schema/accountRequest";
import { color } from "../../../../styles/theme";
import { CustomAutocomplete } from "../../../CustomAutocomplete";
import { Border } from "../../parts/Border";
import { LabelContent } from "../../parts/LabelContent";
import { InputField } from "../InputField";
import { ScrollToTop } from "../ScrollToTop";

type AccountFormProps = {
	accountParams?: AccountRequest;
	images?: File[];
	mode: "create" | "edit";
	onImagesChange: (images: File[]) => void;
};

export const AccountForm = memo(
	({ accountParams, images, mode, onImagesChange }: AccountFormProps) => {
		const location = useLocation();
		const navigate = useNavigate();

		const { data } = useGetAccountCategories();
		const options = getCategoryOptions(data.accountCategories);
		const [categoryId, setCategoryId] = useState<number>(
			accountParams?.categoryId || 0,
		);

		const fee = Math.round(Number(accountParams?.price) * 0.1);
		const proceed = Number(accountParams?.price) - Number(fee);
		const [systemFee, setSystemFee] = useState<number | undefined>(
			location.state?.systemFee || fee || undefined,
		);
		const [proceeds, setProceeds] = useState<number | undefined>(
			location.state?.proceeds || proceed || undefined,
		);

		ScrollToTop();
		const {
			register,
			setValue,
			control,
			handleSubmit,
			setError,
			clearErrors,
			reset,
			formState: { errors },
		} = useForm<AccountRequest>({
			defaultValues: accountParams,
			resolver: zodResolver(AccountRequestSchema),
		});

		useEffect(() => {
			register("images");
			if (accountParams?.categoryId) {
				setCategoryId(accountParams.categoryId);
			}
			reset(accountParams);

			if (images) {
				setValue("images", images);
			}

			if (accountParams?.categoryName) {
				setValue("categoryName", accountParams.categoryName);
			}
		}, [register, accountParams, images, reset, setValue]);

		const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			if (event.target.files && event.target.files.length > 0) {
				const filesArray = Array.from(event.target.files).slice(0, 10);
				onImagesChange(filesArray);
				setValue("images", filesArray);
				clearErrors("images");
			}
		};

		const handleConfirmClick = async (formData: AccountRequest) => {
			const payload = {
				...formData,
				images: images,
			};
			clearErrors();
			try {
				AccountRequestSchema.parse(payload);
				navigate(`/accounts/${mode}/confirm`, {
					state: {
						accountParams: payload,
						systemFee: systemFee,
						proceeds: proceeds,
					},
				});
			} catch (error) {
				if (error instanceof z.ZodError) {
					for (const err of error.errors) {
						setError(err.path[0] as keyof AccountRequest, {
							type: "manual",
							message: err.message,
						});
					}
				}
			}
		};

		return (
			<Stack
				alignItems={"center"}
				sx={{ width: "100%", height: "100%", backgroundColor: color.paleGray }}
			>
				<Stack
					direction={"row"}
					justifyContent={"center"}
					alignItems={"center"}
					sx={{ marginTop: "20px" }}
				>
					<Typography variant="h4">M & A</Typography>
					<Typography variant="h5">CLUB</Typography>
				</Stack>
				<Stack
					alignItems={"center"}
					sx={{
						width: "660px",
						height: "100%",
						backgroundColor: color.white,
						margin: "0px auto",
						padding: "30px",
						gap: "20px",
					}}
				>
					<Stack
						direction={"row"}
						justifyContent={"start"}
						alignItems={"center"}
						sx={{
							width: "100%",
							gap: "20px",
							flexWrap: "wrap",
							maxwidth: "500px",
						}}
					>
						{images?.map((image, index) => (
							<Box
								key={`${image.name}-${image.lastModified}`}
								sx={{ width: 100, height: 100, marginTop: 2 }}
							>
								<Box
									component="img"
									src={URL.createObjectURL(image)}
									sx={{ width: 100, height: 100 }}
								/>
								<IconButton
									onClick={() => {
										const img = images.filter((_, i) => i !== index);
										onImagesChange(img);
										setValue("images", img);
									}}
									sx={{
										position: "relative",
										top: -120,
										left: 70,
										backgroundColor: color.white,
										borderRadius: "50%",
									}}
								>
									<CloseIcon />
								</IconButton>
							</Box>
						))}
					</Stack>
					<IconButton
						color="primary"
						aria-label="upload picture"
						component="label"
						sx={{
							width: 500,
							height: 100,
							backgroundColor: color.paleGray,
							border: `1px dashed ${color.midGray}`,
							overflow: "hidden",
							borderRadius: 0,
						}}
					>
						<input
							hidden
							accept="image/*"
							type="file"
							multiple
							onChange={handleImageChange}
						/>
						<PhotoCamera fontSize="large" color={"disabled"} />
					</IconButton>
					<Typography variant="subtext1">
						PV数や収益など、商品の規模や概要が分かる資料画像を登録すると買い手が見つかりやすくなります。
					</Typography>
					{errors.images && (
						<Typography color="error">{errors.images.message}</Typography>
					)}
					<Border />
					<Stack
						alignItems={"start"}
						sx={{
							width: "100%",
							gap: "5px",
						}}
					>
						<LabelContent title={"案件カテゴリー"} required={true} />
						<Controller
							name="categoryId"
							control={control}
							render={({ field }) => (
								<CustomAutocomplete
									{...field}
									options={options}
									value={
										categoryId
											? options.find(
													(option) => option.value === categoryId.toString(),
												) ?? null
											: null
									}
									groupBy={(option) => {
										const accountOption = option as AccountOption;
										return accountOption.firstLetter;
									}}
									onChange={(_, newValue) => {
										const newCategoryId = newValue ? Number(newValue.value) : 0;
										field.onChange(newCategoryId);
										setCategoryId(newCategoryId);
										setValue("categoryName", newValue?.label ?? "");
									}}
									sx={{
										width: "100%",
										backgroundColor: errors.categoryId?.message
											? color.red
											: color.white,
										"& .MuiAutocomplete-inputRoot": {
											height: "40px",
										},
										"& .MuiInputBase-input": {
											marginTop: "-10px",
											height: "25px",
										},
									}}
								/>
							)}
						/>
						{errors.categoryId?.message && (
							<Typography color="error">案件カテゴリーは必須です</Typography>
						)}
					</Stack>
					<Stack
						alignItems={"start"}
						sx={{
							width: "100%",
						}}
					>
						<InputField
							name={"accountName"}
							title={"案件タイトル"}
							required={true}
							placeholder={"50文字以内"}
							helperText={
								errors.accountName?.message || "50文字以内で入力してください。"
							}
							helperTextColor={
								errors.accountName?.message ? color.red : color.black
							}
							inputProps={{ ...register("accountName") }}
							sx={{
								backgroundColor: errors.accountName?.message
									? color.red
									: color.white,
							}}
						/>
					</Stack>
					<Stack
						alignItems={"start"}
						sx={{
							width: "100%",
						}}
					>
						<InputField
							name={"description"}
							title={"案件説明"}
							required={true}
							placeholder={
								"案件説明には900字〜2000字程度で十分な文字数を使ってアピールすることが重要です。"
							}
							multiline={true}
							inputProps={{ ...register("description") }}
							sx={{
								backgroundColor: errors.description?.message
									? color.red
									: color.white,
							}}
						/>
						{errors.description?.message && (
							<Typography color="error">
								{errors.description.message}
							</Typography>
						)}
					</Stack>
					<Stack
						alignItems={"start"}
						sx={{
							width: "100%",
							gap: "5px",
						}}
					>
						<Typography variant="subtitle2">フォロワー数+</Typography>
						<Stack
							direction={"row"}
							justifyContent={"start"}
							alignItems={"center"}
							sx={{
								width: "50%",
								height: "40px",
							}}
						>
							<TextField
								type="number"
								sx={{
									"& .MuiInputBase-root": {
										height: "40px",
									},
								}}
								{...register("followers")}
							/>
							<Stack
								justifyContent={"center"}
								sx={{
									width: "40px",
									height: "inherit",
									backgroundColor: color.midGray,
									textAlign: "center",
								}}
							>
								<Typography variant="subtitle2">人</Typography>
							</Stack>
						</Stack>
					</Stack>
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						sx={{
							width: "100%",
						}}
					>
						<LabelContent title={"売却価格"} required={true} />
						<Stack direction={"row"}>
							<TextField
								type="number"
								placeholder="3,000〜"
								sx={{
									backgroundColor: errors.price?.message
										? color.red
										: color.white,
									"& .MuiInputBase-root": {
										height: "40px",
									},
								}}
								inputProps={{ min: "0", step: "1", ...register("price") }}
								onChange={(e) => {
									const price = e.target.value;
									const calculatedSystemFee = Math.round(Number(price) * 0.1);
									setSystemFee(calculatedSystemFee);
									setProceeds(Number(price) - calculatedSystemFee);
								}}
							/>
							<Stack
								justifyContent={"center"}
								sx={{
									width: "40px",
									height: "inherit",
									backgroundColor: color.midGray,
									textAlign: "center",
								}}
							>
								<Typography variant="subtitle2">円</Typography>
							</Stack>
						</Stack>
					</Stack>
					{errors.price?.message && (
						<Typography color="error">{errors.price.message}</Typography>
					)}
					<Border />
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						sx={{
							width: "100%",
						}}
					>
						<LabelContent title={"システム手数料(10%)"} />
						<Typography variant="subtitle2">
							{systemFee ||
								Math.round(Number(accountParams?.price) * 0.1) ||
								"--"}
							円
						</Typography>
					</Stack>
					<Border />
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						sx={{
							width: "100%",
						}}
					>
						<LabelContent title={"売上金"} />
						<Typography variant="subtitle2">{proceeds || "--"}円</Typography>
					</Stack>
					<Border />
					<Button
						color="secondary"
						sx={{
							width: "100%",
							backgroundColor: color.red,
							borderRadius: 0,
							"&:hover": {
								backgroundColor: color.red,
								boxShadow: `0 0 10px ${color.red}, 0 0 20px ${color.red}`,
							},
						}}
						onClick={handleSubmit(handleConfirmClick)}
					>
						<Typography variant="h5">確認する</Typography>
					</Button>
				</Stack>
			</Stack>
		);
	},
);
