import useSWR from "swr";
import type { UserBankAccount } from "../../types/userBankAccount";
import { fetcher } from "./fetcher";
import type { UserBankAccountRequest } from "./schema/userBankAccountRequest";

export type CreateUserBankAccountRequest = {
	userId: string;
	defaultAccount: boolean;
	bankCode?: string;
	bankName?: string;
	branchCode?: string;
	branchName?: string;
	accountType?: string;
	accountNumber?: string;
	accountName?: string;
	remarks?: string;
};

export const useGetUserBankAccount = (
	userId?: string,
): {
	userBankAccount?: UserBankAccount;
	error: string | null;
} => {
	const key = `/api/user_bank_account/${userId}`;
	const { data, error } = useSWR<UserBankAccount>(key, fetcher);
	if (!userId) {
		return {
			userBankAccount: undefined,
			error: "userIdが指定されていません",
		};
	}

	if (error) {
		return {
			userBankAccount: undefined,
			error: error.message,
		};
	}
	return {
		userBankAccount: data,
		error: null,
	};
};

export const useCreateUserBankPoint = (): {
	createUserBankPoint: (formData: UserBankAccountRequest) => Promise<void>;
} => {
	return {
		createUserBankPoint: (formData: UserBankAccountRequest) =>
			fetcher("/api/user_bank_account/create", {
				method: "POST",
				body: JSON.stringify(formData),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};
