import useSWR from "swr";
import type { User } from "../../types/users";
import { fetcher } from "./fetcher";

export type UserParams = {
	id: number;
};

export type CreateUserRequest = {
	username: string;
	email: string;
	tel: string;
	password: string;
	registerCode: string;
};

export type UpdateUserAvaterRequest = {
	id: string;
	avatar: string;
};

export type UpdateUserIntroductionRequest = {
	id: string;
	introduction: string;
};

export const useGetUserDetail = (
	name: string,
): {
	user?: User;
	error: string | null;
} => {
	const key = `/api/user_detail/${name}`;
	const { data, error } = useSWR<User>(key, fetcher);
	if (error) {
		return {
			user: undefined,
			error: error.message,
		};
	}

	return {
		user: data,
		error: null,
	};
};

export const useCreateUserAPI = (): {
	registerUser: (data: CreateUserRequest) => Promise<void>;
} => {
	return {
		registerUser: (data: CreateUserRequest): Promise<void> =>
			fetcher<User>("/api/user_register", {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}).then((r) => {}),
	};
};

export const useUpdateUserIntroductionAPI = (): {
	updateUserIntroduction: (
		data: UpdateUserIntroductionRequest,
	) => Promise<{ user: User }>;
} => {
	return {
		updateUserIntroduction: (
			data: UpdateUserIntroductionRequest,
		): Promise<{ user: User }> =>
			fetcher<{ user: User }>("/api/user_detail/update/introduction", {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};

export const useUpdateUserAvatarAPI = (): {
	updateUserAvatar: (data: UpdateUserAvaterRequest) => Promise<{ user: User }>;
} => {
	return {
		updateUserAvatar: (
			data: UpdateUserAvaterRequest,
		): Promise<{ user: User }> =>
			fetcher<{ user: User }>("/api/user_detail/update/avatar", {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};

export const useDeleteAvatarAPI = (): {
	deleteUserAvatar: (data: UpdateUserAvaterRequest) => Promise<{ user: User }>;
} => {
	return {
		deleteUserAvatar: (
			data: UpdateUserAvaterRequest,
		): Promise<{ user: User }> =>
			fetcher<{ user: User }>("/api/user_detail/update/avatar", {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};
