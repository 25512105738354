import {
	Box,
	Button,
	FormControl,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import { useState } from "react";
import { ModalBase } from "../../../components/common/modals/ModalBase";
import type { TransactionStatusType } from "../../../enum/transactionStatus";
import { useUpdatePurchaseAccount } from "../../../hooks/api/purchases";
import { color } from "../../../styles/theme";

type TransactionStatusModalProps = {
	accountId: string;
	value: TransactionStatusType;
	open: boolean;
	onClose: () => void;
};

export const TransactionStatusModal = ({
	accountId,
	value,
	open,
	onClose,
}: TransactionStatusModalProps) => {
	const { updateAccount } = useUpdatePurchaseAccount();
	const [selectedValue, setSelectedValue] =
		useState<TransactionStatusType>(value);

	const handleUpdate = () => {
		updateAccount(accountId, {
			transactionStatus: selectedValue,
		})
			.then(() => {
				onClose();
			})
			.catch(() => {
				alert("エラーが発生しました。");
			});
	};
	return (
		<ModalBase
			title="取引ステータス変更"
			open={open}
			onClose={onClose}
			sx={{
				width: 450,
				height: 300,
			}}
		>
			<Stack
				sx={{
					gap: 2,
				}}
			>
				<Typography variant={"h4"}>
					変更するステータスを選択してください
				</Typography>
				<FormControl fullWidth={true}>
					<Select
						value={selectedValue}
						onChange={(e) =>
							setSelectedValue(e.target.value as TransactionStatusType)
						}
						sx={{
							backgroundColor: color.white,
							"& .MuiInputBase-root": {
								height: "40px",
							},
						}}
					>
						<MenuItem value={1}>支払い確認待ちにする</MenuItem>
						<MenuItem value={2}>買い手評価待ちにする</MenuItem>
						<MenuItem value={3}>売り手評価待ちにする</MenuItem>
						<MenuItem value={4}>取引完了にする</MenuItem>
						<MenuItem value={5}>キャンセルにする</MenuItem>
					</Select>
				</FormControl>
				<Stack direction={"row"} justifyContent={"space-around"}>
					<Button color={"secondary"} onClick={onClose}>
						<Typography variant="subtitle1">キャンセル</Typography>
					</Button>
					<Button onClick={handleUpdate}>
						<Typography variant="subtitle1">更新</Typography>
					</Button>
				</Stack>
			</Stack>
		</ModalBase>
	);
};
