import useSWR from "swr";
import type { User } from "../../types/users";
import { fetcher } from "./fetcher";

export type Message = {
	senderId: string;
	senderName: string;
	avatarUrl: string;
	content: string;
	updatedAt: Date;
};

export type MessageData = {
	partner: User;
	currentMessage: string;
	messages?: Message[];
	lastSentAt?: string;
};

type UserMessageData = { responses?: MessageData[]; error: string | null };

export const useGetUserMessages = (
	userID: string,
): {
	responses: MessageData[] | undefined;
	error: string | null;
} => {
	const key = `/api/user_message/${userID}`;
	const { data, error } = useSWR<UserMessageData>(key, fetcher);

	if (error) {
		return {
			responses: undefined,
			error: "エラーが発生しました",
		};
	}

	return {
		responses: data?.responses || [],
		error: null,
	};
};
