import { Box, Stack, Typography } from '@mui/material'
import { color } from '../../../styles/theme'
import { memo } from 'react'
import type { Message } from '../../../hooks/api/userMessage'

export const MyMessage = memo(({ message }: { message: Message }) => {
  return (
    <Stack
      direction={'row'}
      justifyContent={'end'}
      sx={{
        height: 'auto',
        position: 'relative',
        marginBottom: '35px',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          margin: '0px 25px 0px 30px',
        }}
      >
        <Box
          sx={{
            borderRadius: '6px',
            padding: '15px 20px 18px',
            color: color.white,
            backgroundColor: color.bluePurple,
            '&:before': {
              borderLeftColor: color.black,
              right: '-8px',
              content: '""',
              width: '0px',
              height: '0px',
              borderTop: '5px solid transparent',
              borderBottom: '5px solid transparent',
              borderLeft: `8px solid ${color.bluePurple}`,
              position: 'absolute',
              top: '50%',
              marginTop: '-5px',
            },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              lineHeight: '22px',
              wordBreak: 'break-word',
            }}
          >
            {message.content}
          </Typography>
        </Box>
      </Box>
      <Stack
        direction={'column'}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={message.avatarUrl}
          alt={message.content}
          width="35px"
          height="35px"
          style={{ borderRadius: '50%', border: `1px solid ${color.salmon}` }}
        />
      </Stack>
    </Stack>
  )
})
