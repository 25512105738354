import { memo } from "react";
import { getBankOptions } from "../../../../data/bankData";
import { useGetBanks } from "../../../../hooks/api/banks";
import type { CommonOption } from "../../../../types/commonOption";
import { CustomAutocomplete } from "../../../CustomAutocomplete";

type BankPulldownProps = {
	value: CommonOption | null;
	onChange: (
		event: React.SyntheticEvent<Element, Event>,
		newValue: CommonOption | null,
	) => void;
};

export const BankPulldown = memo(({ value, onChange }: BankPulldownProps) => {
	const { banks } = useGetBanks();
	const options = getBankOptions(banks);
	return (
		<CustomAutocomplete
			options={options}
			value={value}
			onChange={onChange}
			sx={{
				width: "70%",
				"& .MuiAutocomplete-inputRoot": {
					height: "40px",
				},
				"& .MuiInputBase-input": {
					marginTop: "-10px",
					height: "25px",
				},
			}}
		/>
	);
});
