import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { memo, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chat } from "../../components/common/layouts/Chat";
import { UserRoleTypes } from "../../enum/userRole";
import { fetcher } from "../../hooks/api/fetcher";
import {
	useUpdateUserAvatarAPI,
	useUpdateUserIntroductionAPI,
} from "../../hooks/api/users";
import { AuthContext } from "../../hooks/context/auth";
import { color } from "../../styles/theme";
import type { Account } from "../../types/account";
import type { User } from "../../types/users";
import { stringDateDiff } from "../../utils/date";
import { resizeImage } from "../../utils/resizer";
import type { PresignedURLResponse } from "../accounts/create/CreateAccountConfirm";
import { EvaluationIcon } from "./EvaluationIcon";

interface UserProps {
	profileUser: User;
	accounts: Account[];
}

export const Profile: React.FC<UserProps> = memo(
	({ profileUser, accounts }) => {
		const authContext = useContext(AuthContext);
		const [chatOpen, setChatOpen] = useState(false);
		const [introduction, setIntroduction] = useState(profileUser.introduction);
		const { updateUserIntroduction } = useUpdateUserIntroductionAPI();
		const { updateUserAvatar } = useUpdateUserAvatarAPI();
		// TODO: S3のプロファイルを削除するAPIを作成する
		// const { deleteAvatar } = useDeleteAvatarAPI();
		const navigate = useNavigate();
		const isReadOnly = authContext?.auth?.id !== profileUser.id;
		const handleAccountClick = (id: string) => {
			navigate(`/accounts/${id}`);
		};

		const handleProfileUserClick = (name: string) => {
			navigate(`/user/${name}`);
		};

		const handleMessageClick = () => {
			setChatOpen(true);
		};

		const handleProfileImageChange = async (
			e: React.ChangeEvent<HTMLInputElement>,
		) => {
			if (!authContext?.auth) {
				alert("ログインしてください");
				return;
			}
			if (!e.target.files) return;
			const selectedFile = e.target.files[0];
			const presignedResponse = await fetcher<PresignedURLResponse>(
				`/api/user_detail/generate-presigned-url?userId=${authContext.auth.id}&imageType=${
					selectedFile.type
				}&extension=${selectedFile.name.split(".").pop()}`,
			);
			const url = presignedResponse.url;
			const resized = await resizeImage(selectedFile, 120, 120);

			const uploadResponse = await fetch(url, {
				method: "PUT",
				headers: {
					"Content-Type": resized.type,
				},
				body: resized,
			});

			if (!uploadResponse.ok) throw new Error("Upload failed");

			updateUserAvatar({
				id: authContext.auth.id,
				avatar: url.split("?")[0],
			}).then(async (res: { user: User }) => {
				window.location.reload();
			});
		};

		const handleImageDelete = () => {
			if (!authContext?.auth) {
				alert("ログインしてください");
				return;
			}

			updateUserAvatar({
				id: authContext.auth.id,
				avatar: "",
			}).then(async (res: { user: User }) => {
				window.location.reload();
			});
		};

		const handleIntroductionBlur = () => {
			if (isReadOnly || profileUser.introduction === introduction) return;

			updateUserIntroduction({
				id: profileUser.id,
				introduction: introduction,
			}).then(async (res: { user: User }) => {});
		};

		return (
			<Box>
				<Stack direction={"row"} alignItems={"center"}>
					<Box>
						<Button
							component="label"
							disabled={isReadOnly}
							sx={{
								width: "120px",
								height: "120px",
								borderRadius: "50%",
								padding: "0px",
								overflow: "hidden",
								position: "relative",
								backgroundColor: "transparent",
								"&:hover": {
									backgroundColor: "transparent",
									opacity: 0.7,
									boxShadow: "none",
								},
							}}
						>
							{profileUser.avatar ? (
								<img
									alt="avatar"
									src={profileUser.avatar}
									width="120px"
									height="120px"
									style={{
										borderRadius: "50%",
									}}
									loading="lazy"
								/>
							) : (
								<AccountCircleIcon
									sx={{
										width: "120px",
										height: "120px",
										borderRadius: "50%",
										color: color.midGray,
									}}
								/>
							)}
							<input
								hidden
								accept="image/*"
								type="file"
								onChange={handleProfileImageChange}
							/>
						</Button>
						{!isReadOnly && profileUser.avatar && (
							<IconButton
								onClick={handleImageDelete}
								sx={{
									position: "absolute",
									top: "200px",
									left: "120px",
									cursor: "pointer",
									backgroundColor: color.white,
								}}
							>
								<CloseIcon />
							</IconButton>
						)}
					</Box>
					<Stack
						direction={"column"}
						textAlign={"center"}
						sx={{
							gap: "20px",
						}}
					>
						<Typography variant={"h2"}>{profileUser.username}</Typography>
						<Typography
							variant={"body1"}
							sx={{
								color: color.midNightBlueGrey,
								opacity: 0.7,
								lineHeight: "14px",
							}}
						>
							Registered {stringDateDiff(profileUser.createdAt)}
						</Typography>
						<Stack direction={"row"} justifyContent={"center"} gap={1}>
							{/* <Identification /> */}
							{/* {profileUser.vip && <Vip />} */}
						</Stack>
					</Stack>
					<TextField
						value={introduction}
						multiline={true}
						variant="outlined"
						rows={6}
						InputProps={{
							readOnly: isReadOnly,
						}}
						sx={{
							resize: "none",
							width: "540px",
							marginLeft: "69px",
							overflowY: "auto",
							"& .MuiOutlinedInput-notchedOutline": {
								borderRadius: "20px",
							},
						}}
						onChange={(e) => setIntroduction(e.target.value)}
						onBlur={handleIntroductionBlur}
					/>
					{authContext?.auth &&
						authContext.auth.id !== profileUser.id &&
						profileUser.role === UserRoleTypes.USER && (
							<Button
								sx={{
									marginLeft: "141px",
								}}
								onClick={handleMessageClick}
							>
								<Typography variant="subtitle1">メッセージを送る</Typography>
							</Button>
						)}
				</Stack>
				<Stack
					direction={"row"}
					sx={{
						marginTop: "45px",
						width: "100%",
						gap: "20px",
					}}
				>
					<Stack
						direction={"column"}
						sx={{
							width: "100%",
							padding: "0px 15px",
							flexBasis: "0",
							flexGrow: 3,
							flexShrink: 1,
						}}
						divider={<Divider orientation={"horizontal"} flexItem={true} />}
					>
						<Typography variant="h4" sx={{ marginBottom: "25px" }}>
							販売リスト:
						</Typography>
						<Stack
							sx={{
								marginTop: "20px",
								gap: "20px",
							}}
							divider={<Divider orientation={"horizontal"} flexItem={true} />}
						>
							{accounts.map((account, _) => (
								<Stack
									key={account.id}
									direction={"row"}
									onClick={() => handleAccountClick(account.id)}
									gap={2}
								>
									<Box
										sx={{
											width: "120px",
											height: "120px",
											border: `1px solid ${color.salmon}`,
											borderRadius: "50%",
										}}
									>
										<img
											alt="account"
											src={account.image}
											width="120px"
											height="120px"
											style={{
												borderRadius: "50%",
												border: `1px solid ${color.salmon}`,
											}}
											loading="lazy"
										/>
									</Box>
									<Stack
										direction={"column"}
										sx={{
											maxWidth: "800px",
											justifyContent: "space-around",
										}}
									>
										<Box
											onClick={() => {
												handleAccountClick(account.id);
											}}
											sx={{
												cursor: "pointer",
											}}
										>
											<Typography
												variant="h4"
												sx={{
													color: "#0095ee",
												}}
											>
												{account.accountName}
											</Typography>
										</Box>
										<Stack
											direction={"column"}
											sx={{
												gap: "10px",
											}}
										>
											<Typography
												variant="subtitle1"
												sx={{
													color: color.midNightBlueGrey,
												}}
											>
												{account.platform}
											</Typography>
											<Stack direction={"row"} gap={"5px"}>
												<Typography
													variant="subtitle3"
													sx={{
														color: color.midNightBlueGrey,
														opacity: 0.7,
													}}
												>
													{`${account.followers.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}人`}
												</Typography>
												<Typography
													variant="subtitle3"
													sx={{
														color: color.midNightBlueGrey,
														opacity: 0.7,
													}}
												>
													{`¥${account.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円`}
												</Typography>
											</Stack>
										</Stack>
									</Stack>
								</Stack>
							))}
						</Stack>
					</Stack>
					<Box
						sx={{
							width: "100%",
							padding: "0px 15px 0px 50px",
							flexBasis: "0",
							flexGrow: 1,
							flexShrink: 1,
						}}
					>
						<Stack direction={"row"} sx={{ gap: "15px" }}>
							<Typography variant="h4">レビュー一覧</Typography>
							<Typography
								variant="subtitle2"
								sx={{
									color: "#00D50A",
								}}
							>
								+{profileUser.reviewPlus}
							</Typography>
							<Typography
								variant="subtitle2"
								sx={{
									color: "#BFBFBF",
								}}
							>
								{profileUser.reviewMiddle}
							</Typography>
							<Typography
								variant="subtitle2"
								sx={{
									color: "#FF0000",
								}}
							>
								-{profileUser.reviewMinus}
							</Typography>
						</Stack>
						{profileUser?.userReviews?.map((review, _) => (
							<Stack
								key={review.reviewUser.id}
								sx={{
									gap: "10px",
								}}
							>
								<Stack
									direction={"row"}
									divider={<Divider orientation={"vertical"} flexItem={true} />}
									sx={{
										gap: "10px",
										marginTop: "10px",
									}}
								>
									<EvaluationIcon condition={review.evaluation} />
									<Typography
										variant="subtitle1"
										sx={{
											textDecoration: "underline",
											cursor: "pointer",
										}}
										onClick={() => {
											handleProfileUserClick(review.reviewUser.username);
										}}
									>
										{review.reviewUser.username}
									</Typography>
									<Typography variant="subtitle1">
										{`¥${review.account.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円`}
									</Typography>
								</Stack>
								<Typography variant="subtitle1">
									{review.account.name}
								</Typography>
								<Typography variant="subtitle3" whiteSpace={"pre-wrap"}>
									{review.reviewComment}
								</Typography>
							</Stack>
						))}
					</Box>
				</Stack>
				{chatOpen && (
					<Chat profileUser={profileUser} onClose={() => setChatOpen(false)} />
				)}
			</Box>
		);
	},
);
