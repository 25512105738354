import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  memo,
  useState,
} from 'react'
import type { Auth } from '../../types/auth'

interface AuthContextProps {
  auth: Auth | undefined
  isAuthenticated: boolean
  loggedIn: () => void
  loggedOut: () => void
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined)
export const SetAuthContext = createContext<Dispatch<SetStateAction<Auth | undefined>>>(
  () => undefined,
)

export const AuthProvider = memo(({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() =>
    JSON.parse(localStorage.getItem('isAuthenticated') || 'false'),
  )
  const [auth, setAuth] = useState<Auth | undefined>()

  const loggedIn = () => {
    setIsAuthenticated(true)
    localStorage.setItem('isAuthenticated', 'true') // ローカルストレージに保存
  }
  const loggedOut = () => {
    setIsAuthenticated(false)
    localStorage.setItem('isAuthenticated', 'false') // ローカルストレージに保存
  }

  return (
    <AuthContext.Provider value={{ auth, isAuthenticated, loggedIn, loggedOut }}>
      <SetAuthContext.Provider value={setAuth}>{children}</SetAuthContext.Provider>
    </AuthContext.Provider>
  )
})
