import { Button, Stack, Typography } from "@mui/material";
import { ModalBase } from "../../../../components/common/modals/ModalBase";

type SaleStatusModalProps = {
	open: boolean;
	statusName: string;
	onUpdate: () => void;
	onClose: () => void;
};

export const SaleStatusModal = ({
	open,
	statusName,
	onUpdate,
	onClose,
}: SaleStatusModalProps) => {
	return (
		<ModalBase
			title={"取引状態変更"}
			open={open}
			onClose={onClose}
			sx={{
				width: 350,
				height: 200,
			}}
		>
			<Stack sx={{ gap: 2 }}>
				<Typography
					variant={"h4"}
					textAlign={"center"}
				>{`${statusName}に変更しますか？`}</Typography>
				<Stack direction={"row"} justifyContent={"space-around"}>
					<Button color={"secondary"} onClick={onClose}>
						<Typography variant="subtitle1">キャンセル</Typography>
					</Button>
					<Button onClick={onUpdate}>
						<Typography variant="subtitle1">更新</Typography>
					</Button>
				</Stack>
			</Stack>
		</ModalBase>
	);
};
