import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { useEffect } from "react";
import type React from "react";
import {
	Route,
	BrowserRouter as Router,
	Routes,
	useNavigate,
} from "react-router-dom";
import "./App.css";
import { AuthGuard } from "./components/auth/Guard";
import { AccountComplete } from "./components/common/layouts/accounts/AccountComplete";
import { AuthProvider } from "./hooks/context/auth";
import { Layout } from "./pages/Layout";
import { CreateAccount } from "./pages/accounts/create";
import { CreateAccountConfirm } from "./pages/accounts/create/CreateAccountConfirm";
import { AccountDetail } from "./pages/accounts/detail";
import { UpdateAccount } from "./pages/accounts/edit";
import { UpdateAccountConfirm } from "./pages/accounts/edit/UpdateAccountConfirm";
import { Accounts } from "./pages/accounts/list";
import { AccountPurchase } from "./pages/accounts/purchase";
import { TradeAccount } from "./pages/accounts/purchase/TradeAccount";
import { Tradings } from "./pages/tradings/list";
import { Users } from "./pages/users";
import { ConfirmFactor } from "./pages/users/create/ConfirmFactor";
import { TwoFactor } from "./pages/users/create/TwoFactor";
import { VerifyRegisterCode } from "./pages/users/create/VerifyRegisterCode";
import { UserPoint } from "./pages/users/point";
import { WithdrawalRequestList } from "./pages/users/withdrawalRequest/list";
import { theme } from "./styles/theme";

export const App: React.FC = () => (
	<ThemeProvider theme={theme}>
		<AuthProvider>
			<AppRouter />
		</AuthProvider>
	</ThemeProvider>
);

const RedirectToAccounts: React.FC = () => {
	const navigate = useNavigate();
	useEffect(() => {
		navigate("/accounts");
	}, [navigate]);

	return null;
};

const AppRouter: React.FC = () => {
	return (
		<>
			<Router>
				<CssBaseline />
				<Routes>
					<Route path="/verify/register" element={<VerifyRegisterCode />} />
					<Route path="/verify/sms-factor" element={<TwoFactor />} />
					<Route path="/confirm/sms-factor" element={<ConfirmFactor />} />
					<Route path="" element={<Layout />}>
						<Route element={<AuthGuard />}>
							<Route path="/" element={<RedirectToAccounts />} />
							<Route path="/accounts" element={<Accounts />} />
							<Route path="/accounts/:id" element={<AccountDetail />} />
							<Route path="/accounts/create" element={<CreateAccount />} />
							<Route
								path="/accounts/create/confirm"
								element={<CreateAccountConfirm />}
							/>
							<Route path="/accounts/edit/:id" element={<UpdateAccount />} />
							<Route
								path="/accounts/edit/confirm"
								element={<UpdateAccountConfirm />}
							/>
							<Route path="/accounts/complete" element={<AccountComplete />} />
							<Route
								path="/accounts/purchase/:id"
								element={<AccountPurchase />}
							/>
							<Route
								path="/accounts/:id/purchase/:uuid"
								element={<TradeAccount />}
							/>
							<Route path="/user/:name" element={<Users />} />
							<Route path="/user/point/:id" element={<UserPoint />} />
							<Route path="/accounts/tradings/list" element={<Tradings />} />
							<Route
								path="/user/point/withdrawal/request/list"
								element={<WithdrawalRequestList />}
							/>
						</Route>
					</Route>
				</Routes>
			</Router>
		</>
	);
};
