import CloseIcon from "@mui/icons-material/Close";
import {
	Modal,
	Stack,
	type SxProps,
	type Theme,
	Typography,
} from "@mui/material";
import { memo } from "react";
import { color } from "../../../styles/theme";

type ModalBaseProps = {
	open: boolean;
	onClose?: () => void;
	title?: string;
	children?: React.ReactNode;
	sx?: SxProps<Theme>;
};

export const ModalBase = memo(
	({ open, onClose, title, children, sx }: ModalBaseProps) => {
		return (
			<Modal open={open} aria-labelledby="modal-title">
				<Stack
					justifyContent={"center"}
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						maxWidth: "100%",
						maxHeight: "100%",
						padding: "27px 0px 30px",
						bgcolor: "#FFFFFF",
						borderRadius: "6px",
						boxShadow: "0px 4px 0px rgba(0, 0, 0, 0.04)",
						boxSizing: "border-box",
						p: 4,
						"&:focus-visible": {
							outline: "none",
						},
						...sx,
					}}
				>
					{title && (
						<Stack
							sx={{
								position: "absolute",
								top: "0px",
								left: "0px",
								width: "inherit",
								height: "40px",
							}}
						>
							<Stack
								alignItems={"start"}
								sx={{
									width: "inherit",
									height: "inherit",
									backgroundColor: color.black,
									padding: "10px",
									borderRadius: "5px 5px 0px 0px",
								}}
							>
								<Typography variant={"h4"} sx={{ color: color.white }}>
									{title}
								</Typography>
							</Stack>
						</Stack>
					)}
					{children}
					<CloseIcon
						sx={{
							position: "absolute",
							top: "10px",
							right: "16px",
							cursor: "pointer",
							color: title ? color.white : color.black,
						}}
						onClick={onClose}
					/>
				</Stack>
			</Modal>
		);
	},
);
