import { z } from 'zod'

export const AccountRequestSchema = z.object({
  id: z.string().optional(),
  images: z
    .array(z.instanceof(File))
    .min(1, { message: '少なくとも1枚の画像を選択してください。' }),
  categoryId: z.number(),
  categoryName: z.string(),
  accountName: z
    .string()
    .min(1, { message: '案件タイトルは必須です。' })
    .max(50, { message: '案件タイトルは50文字以内である必要があります。' }),
  description: z.string().min(1, { message: '案件説明は必須です。' }),
  followers: z.string().optional(),
  price: z
    .string()
    .min(1, { message: '売却価格は必須です。' })
    .refine(
      (value) =>
        !Number.isNaN(Number(value)) && Number(value) > 0 && Number.isInteger(Number(value)),
      {
        message: '売却価格は1以上の整数である必要があります。',
      },
    ),
})

export type AccountRequest = z.infer<typeof AccountRequestSchema>
