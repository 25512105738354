import {
	Box,
	Divider,
	Link,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { KeyedMutator } from "swr";
import { UserRoleTypes } from "../../../enum/userRole";
import type { PurchasedAccountResponse } from "../../../hooks/api/purchases";
import { AuthContext } from "../../../hooks/context/auth";
import { color } from "../../../styles/theme";
import { TradingRow } from "./TradingRow";

export const TradingTable = ({
	purchasedAccounts,
	refetch,
}: {
	purchasedAccounts: PurchasedAccountResponse[];
	refetch: KeyedMutator<PurchasedAccountResponse[]>;
}) => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const [sort, setSort] = useState("");
	const [order, setOrder] = useState("");
	useEffect(() => {
		if (!sort || !order) return;

		const searchParams = new URLSearchParams();
		searchParams.set("sort", sort);
		searchParams.set("order", order);
		return navigate(`?${searchParams.toString()}`);
	}, [sort, order, navigate]);

	const handleSortChange = (newSort: string) => {
		if (sort === newSort) {
			setOrder(order === "asc" ? "desc" : "asc");
		} else {
			setSort(newSort);
			setOrder("asc");
		}
	};

	return (
		<Box sx={{ marginTop: "53px", width: "100%" }}>
			<TableContainer
				sx={{
					overflowX: "hidden",
				}}
			>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell colSpan={3}>
								<Stack
									direction={"row"}
									alignItems={"center"}
									spacing={2}
									divider={<Divider orientation={"vertical"} flexItem={true} />}
								>
									<Typography variant="h4" sx={{ opacity: 0.5 }}>
										並び替え:
									</Typography>
									<Link
										id="subscriberCount"
										onClick={() => handleSortChange("id")}
										sx={{
											color: color.black,
											textDecorationColor: color.black,
											cursor: "pointer",
										}}
									>
										<Typography variant="subtitle1">チャットID</Typography>
									</Link>
									<Link
										id="price"
										onClick={() => handleSortChange("startDate")}
										sx={{
											color: color.black,
											textDecorationColor: color.black,
											cursor: "pointer",
										}}
									>
										<Typography variant="subtitle1">取引日</Typography>
									</Link>
								</Stack>
							</TableCell>
							<TableCell>
								<Typography
									variant="h4"
									textAlign={"right"}
									sx={{ opacity: 0.5 }}
								>
									金額
								</Typography>
							</TableCell>
							{authContext?.auth?.role === UserRoleTypes.ADMIN && (
								<TableCell>
									<Typography
										variant="h4"
										textAlign={"center"}
										sx={{ opacity: 0.5 }}
									>
										取引状態
									</Typography>
								</TableCell>
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{purchasedAccounts.length === 0 ? (
							<Stack
								justifyContent={"center"}
								alignContent={"center"}
								alignItems={"center"}
								sx={{
									width: "100vw",
									marginTop: "50px",
								}}
							>
								<Stack
									sx={{
										width: "60%",
										height: "100px",
										backgroundColor: color.matGray,
										borderRadius: "10px",
									}}
								>
									<Stack justifyContent={"center"} sx={{ height: "100%" }}>
										<Typography
											variant="h4"
											textAlign={"center"}
											color={color.black}
											sx={{
												opacity: 0.5,
											}}
										>
											取引がありません
										</Typography>
									</Stack>
								</Stack>
							</Stack>
						) : (
							purchasedAccounts.map((account) => (
								<TradingRow
									key={account.id}
									purchasedAccount={account}
									onChange={refetch}
								/>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};
