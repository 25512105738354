import type { KeyboardEvent } from 'react'

export const useFormSubmit = (callback: () => void) => {
  return (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      callback()
    }
  }
}
