import type { AccountCategory } from "../types/account";
import type { CommonOption } from "../types/commonOption";

export interface AccountOption extends CommonOption {
	firstLetter: string;
}

export const getCategoryOptions = (
	accountCategories: AccountCategory[],
): AccountOption[] => {
	return (
		accountCategories?.map((category) => ({
			label: category.name,
			value: category.id,
			firstLetter: category.name[0].toUpperCase(),
		})) || []
	);
};
