import { Button, Stack, TextField, Typography } from "@mui/material";
import { memo, useState } from "react";
import { useLocation } from "react-router-dom";
import { TableItem } from "../../../pages/accounts/purchase/TableItem";
import { PointTable } from "../../../pages/users/point/PointTable";
import { color, theme } from "../../../styles/theme";
import type { UserBankAccount } from "../../../types/userBankAccount";
import type { UserPoint } from "../../../types/userPoint";
import { BankAccountForm } from "../parts/bank/BankAccountForm";
import { WithdrawalPointForm } from "../parts/bank/WithdrawalPointForm";
import { ModalBase } from "./ModalBase";

type WithdrawalModalPointProps = {
	open: boolean;
	proceeds: number;
	userPoints: UserPoint[];
	onClose: () => void;
	onSubmit: (userBankAccount: UserBankAccount, amount: number) => void;
};

const withdrawalFee = 220;

export const WithdrawalPointModal = memo(
	({
		open,
		proceeds,
		userPoints,
		onClose,
		onSubmit,
	}: WithdrawalModalPointProps) => {
		const location = useLocation();
		const search = new URLSearchParams(location.search);
		const page = search.get("p");
		const currentPage = page ? Number(page) : 1;
		const [buttonDisabled, setButtonDisabled] = useState(true);
		const [withdrawalAmount, setWithdrawalAmount] = useState(0);
		const [error, setError] = useState("");
		const [previousUserBankAccount, setPreviousUserBankAccount] =
			useState(false);
		const [openBankAccountModal, setOpenBankAccountModal] = useState(false);

		const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			const amount = Number(event.target.value);
			setWithdrawalAmount(amount);
			setButtonDisabled(!(amount > 0) || amount > proceeds);
			setError(
				amount + withdrawalFee > proceeds
					? "手数料含む出金金額が現在の売上金を超えています"
					: "",
			);
		};

		const handleSubmit = (userBankAccount: UserBankAccount) => {
			onSubmit(userBankAccount, withdrawalAmount);
		};

		return (
			<ModalBase
				open={open}
				title={"銀行口座に出金"}
				onClose={onClose}
				sx={{
					width: 800,
					minHeight: 160,
				}}
			>
				<Stack
					width={"100%"}
					sx={{
						marginTop: theme.spacing(2),
					}}
				>
					<TableItem
						title={"現在の売上金"}
						titleWidth={"50%"}
						titleVariant={"subtitle1"}
						content={`${proceeds}円`}
						contentWidth={"50%"}
						contentAlign="end"
						contentVariant={"subtitle3"}
						sx={{
							border: `1px solid ${color.paleGray}`,
						}}
					/>
					<TableItem
						title={"出金金額"}
						titleWidth={"50%"}
						titleVariant={"subtitle1"}
						sx={{
							alignItems: "center",
							border: `1px solid ${color.paleGray}`,
							borderTop: "none",
						}}
					>
						<Stack
							direction={"row"}
							alignItems={"center"}
							justifyContent={"end"}
							width={"50%"}
						>
							<TextField
								value={withdrawalAmount}
								type="number"
								size="small"
								onChange={handleAmountChange}
								InputProps={{
									inputProps: {
										style: { textAlign: "end" },
									},
								}}
							>
								※出金金額は1円以上で入力してください
							</TextField>
							<Typography
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}
								height={"40px"}
								width={"30px"}
								sx={{
									backgroundColor: color.matGray,
								}}
							>
								円
							</Typography>
						</Stack>
					</TableItem>
					<TableItem
						title={"出金手数料"}
						titleWidth={"50%"}
						titleVariant={"subtitle1"}
						content={"220円"}
						contentWidth={"50%"}
						contentAlign="end"
						contentVariant={"subtitle3"}
						sx={{
							border: `1px solid ${color.paleGray}`,
							borderTop: "none",
						}}
					/>
					<Typography variant="h5" color="red" textAlign={"end"}>
						{error}
					</Typography>
				</Stack>
				<Stack
					width={"100%"}
					sx={{
						marginTop: theme.spacing(2),
						gap: "5px",
					}}
				>
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<Typography variant={"h5"}>振込先口座</Typography>
						{!openBankAccountModal && (
							<Stack direction={"row"} gap={"5px"}>
								<Button
									color="info"
									sx={{
										height: "25px",
									}}
									onClick={() => setPreviousUserBankAccount(true)}
								>
									<Typography variant="subtitle1">
										前回の振込先を使用する
									</Typography>
								</Button>
								<Button
									sx={{
										height: "25px",
									}}
									onClick={() => setOpenBankAccountModal(true)}
								>
									<Typography variant="subtitle1">振込先を登録する</Typography>
								</Button>
							</Stack>
						)}
					</Stack>
					{openBankAccountModal ? (
						<BankAccountForm
							onClose={() => {
								setOpenBankAccountModal(false);
								setPreviousUserBankAccount(true);
							}}
							onCancel={() => setOpenBankAccountModal(false)}
						/>
					) : (
						<WithdrawalPointForm
							buttonDisabled={buttonDisabled}
							previousUserBankAccount={previousUserBankAccount}
							onClick={handleSubmit}
						/>
					)}
					<PointTable userPoints={userPoints} />
				</Stack>
			</ModalBase>
		);
	},
);
