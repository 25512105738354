export enum UserRoleTypes {
	ADMIN = 1,
	USER = 2,
}

export type UserRoleType = UserRoleTypes;

export const UserRoleStrings = {
	[UserRoleTypes.ADMIN]: "管理者",
	[UserRoleTypes.USER]: "ユーザー",
} as const;

export type UserRoleString = (typeof UserRoleStrings)[UserRoleType];
