import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@mui/material";
import { memo, useState } from "react";
import { color } from "../../../styles/theme";
import type { UserPoint } from "../../../types/userPoint";

export const PointTable = memo(
	({ userPoints }: { userPoints: UserPoint[] }) => {
		const [page, setPage] = useState(0);
		const [rowsPerPage, setRowsPerPage] = useState(5);

		const handleChangePage = (event: unknown, newPage: number) => {
			setPage(newPage);
		};

		const handleChangeRowsPerPage = (
			event: React.ChangeEvent<HTMLInputElement>,
		) => {
			setRowsPerPage(Number(event.target.value));
			setPage(0);
		};

		return (
			<TableContainer component={Paper} sx={{ maxHeight: 400 }}>
				<Table>
					<TableHead>
						<TableRow sx={{ backgroundColor: color.black }}>
							<TableCell>
								<Typography variant="subtitle1" sx={{ color: color.white }}>
									取引日時
								</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="subtitle1" sx={{ color: color.white }}>
									内容
								</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="subtitle1" sx={{ color: color.white }}>
									増減
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{userPoints
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((userPoint) => (
								<TableRow key={userPoint.id}>
									<TableCell>{userPoint.createdAt}</TableCell>
									<TableCell>{userPoint.point > 0 ? "入金" : "出金"}</TableCell>
									<TableCell>{userPoint.point}</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					count={userPoints.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</TableContainer>
		);
	},
);
