import useSWR from "swr";
import type { Bank } from "../../types/bank";
import type { BankBranch } from "../../types/bankBranch";
import { fetcher } from "./fetcher";

export type BankBranchQueryParams = {
	accountName?: string;
	userID?: string;
	keyword?: string;
	followersFrom?: string;
	followersTo?: string;
	priceFrom?: string;
	priceTo?: string;
	sort?: string;
	order?: string;
};

export const useGetBanks = (): {
	banks: Bank[];
} => {
	const key = "/api/banks";
	const { data, error } = useSWR<Bank[]>(key, fetcher);

	if (error) {
		return {
			banks: [],
		};
	}

	return {
		banks: data || [],
	};
};

export const useGetBankBranches = (
	bankCode: string,
): {
	bankBranches: BankBranch[];
} => {
	const key = `/api/branches?bank_code=${bankCode}`;
	const { data, error } = useSWR<BankBranch[]>(key, fetcher);

	if (!bankCode) {
		return {
			bankBranches: [],
		};
	}

	if (error) {
		return {
			bankBranches: [],
		};
	}

	return {
		bankBranches: data || [],
	};
};
