import { BorderColor } from "@mui/icons-material";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import {
	Box,
	Divider,
	IconButton,
	Link,
	Stack,
	Typography,
} from "@mui/material";
import { memo, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import header_white from "../../../assets/images/header_white_320x36.png";
import homeImage from "../../../assets/images/home.jpg";
import { useCreateRegisterCodeAPI, useLoginAPI } from "../../../hooks/api/auth";
import { AuthContext } from "../../../hooks/context/auth";
import { color } from "../../../styles/theme";
// Header.tsx
import type { LoginRequest, RegisterRequest } from "../../../types/auth";
import { LoginModal } from "../modals/LoginModal";
import { RegisterModal } from "../modals/RegisterModal";
import { LoginProfile } from "../parts/LoginProfile";

export const Header = memo(() => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const { login } = useLoginAPI();
	const { register } = useCreateRegisterCodeAPI();
	const [loginModalOpen, setLoginModalOpen] = useState(false);
	const [registerModalOpen, setRegisterModalOpen] = useState(false);
	const [loginError, setLoginError] = useState(false);
	const [registerError, setRegisterError] = useState("");

	const handleRegister = (data: RegisterRequest) => {
		register(data)
			.then((e) => {
				window.location.reload();
			})
			.catch((err) => {
				setRegisterError(err.message);
			});
	};

	const handleLogin = (data: LoginRequest) => {
		login(data)
			.then((e) => {
				authContext?.loggedIn();
				window.location.reload();
			})
			.catch((err) => {
				setLoginError(true);
			});
	};

	const handleRootLink = (
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
	) => {
		event.preventDefault();
		navigate("/accounts", { replace: true });
	};

	return (
		<Box
			sx={{
				height: "140px",
				justifyContent: "space-between",
				backgroundImage: `url(${homeImage})`,
				backgroundRepeat: "no-repeat",
				backgroundPositionX: "center",
				backgroundPositionY: "center",
				padding: "33px 0px 0px",
			}}
		>
			<Box
				sx={{
					width: {
						xs: "718px",
						md: "750px",
						lg: "970px",
						xl: "1580px",
					},
					height: "66px",
					margin: "0px auto",
					padding: "0px 15px",
				}}
			>
				<IconButton
					component="a"
					href="/accounts"
					sx={{
						float: "left",
						fontSize: 16,
						border: `1px solid ${color.white}`,
						backgroundColor: color.white,
					}}
				>
					<HomeTwoToneIcon />
				</IconButton>
				<Stack
					direction={"row"}
					alignItems={"center"}
					sx={{ width: "252px", float: "right" }}
				>
					{authContext?.auth ? (
						<LoginProfile auth={authContext.auth} />
					) : (
						<Stack
							direction={"row"}
							sx={{
								float: "right",
								marginLeft: "30px",
								".MuiDivider-wrapper": {
									display: "flex",
									alignItems: "center",
									color: color.white,
									padding: 0,
								},
							}}
							divider={
								<Divider orientation={"horizontal"} flexItem={true}>
									/
								</Divider>
							}
						>
							<Link sx={{ color: color.white }}>
								<Typography
									variant="body2"
									sx={{
										color: color.white,
										textDecoration: "underline",
										cursor: "pointer",
									}}
									onClick={() => setLoginModalOpen(true)}
								>
									ログイン
								</Typography>
							</Link>
							<Link sx={{ color: color.white }}>
								<Typography
									variant="body2"
									sx={{
										color: color.white,
										textDecoration: "underline",
										cursor: "pointer",
									}}
									onClick={() => setRegisterModalOpen(true)}
								>
									ユーザー登録
								</Typography>
							</Link>
						</Stack>
					)}
				</Stack>
				<Link
					onClick={handleRootLink}
					sx={{
						display: "block",
						textAlign: "center",
						margin: "auto",
						width: "480px",
						backgroundImage: `url(${header_white})`,
						backgroundRepeat: "no-repeat",
						backgroundPositionX: "50%",
						backgroundPositionY: "0%",
						paddingTop: "45px",
						cursor: "pointer",
					}}
				/>
			</Box>
			<LoginModal
				open={loginModalOpen}
				onClose={() => setLoginModalOpen(false)}
				onSubmit={handleLogin}
				message={"ログイン"}
				alertMessage={
					loginError
						? "ログインに失敗しました。\nメールアドレスとパスワードを確認してください。"
						: undefined
				}
				confirmLabel={"ログイン"}
				confirmIcon={<BorderColor />}
			/>
			<RegisterModal
				open={registerModalOpen}
				onClose={() => {
					setRegisterModalOpen(false);
					setRegisterError("");
				}}
				onSubmit={handleRegister}
				message={"会員登録"}
				alertMessage={registerError}
				confirmLabel={"登録"}
				confirmIcon={<BorderColor />}
			/>
		</Box>
	);
});
