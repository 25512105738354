import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useVerifyAPI } from "../../hooks/api/auth";
import { SetAuthContext } from "../../hooks/context/auth";

export const AuthGuard = () => {
	const { data } = useVerifyAPI();
	const setAuth = useContext(SetAuthContext);

	useEffect(() => {
		setAuth(data.auth);
	}, [setAuth, data]);

	return <Outlet />;
};
