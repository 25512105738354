import { Box } from "@mui/material";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { useGetAccounts } from "../../hooks/api/accounts";
import { useGetUserDetail } from "../../hooks/api/users";
import { Profile } from "./Profile";

export const Users = memo(() => {
	const { name } = useParams<{ name: string }>();
	const { user: receiverUser } = useGetUserDetail(name || "");
	const { accounts } = useGetAccounts({ userID: receiverUser?.id });

	if (!name || !receiverUser) {
		return <Box>存在しないユーザーです</Box>;
	}

	if (!accounts) {
		return <Box>アカウントがありません</Box>;
	}

	return (
		<Box
			sx={{
				paddingTop: "50px",
			}}
		>
			<Box
				sx={{ width: "1580px", margin: "0px auto", justifyContent: "center" }}
			>
				<Profile profileUser={receiverUser} accounts={accounts} />
			</Box>
		</Box>
	);
});
