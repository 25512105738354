import { Box, Button, Stack } from '@mui/material'
import {
  type DateValidationError,
  type DateView,
  LocalizationProvider,
  DatePicker as MuiDatePicker,
  type PickerChangeHandlerContext,
  type PickersActionBarProps,
  PickersLayoutContentWrapper,
  type PickersLayoutProps,
  PickersLayoutRoot,
  pickersLayoutClasses,
  usePickerLayout,
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ja from 'date-fns/locale/ja'
import { memo, useEffect, useState } from 'react'
import { color } from '../../../styles/theme'

type Props = {
  value: Date | null
  placeholder: string
  onChange: (value: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => void
}

export const BasicDatePicker = memo(({ value, placeholder, onChange }: Props) => {
  const [locale, setLocale] = useState(ja)
  const customOnChange = (
    newValue: Date | null,
    ctx: PickerChangeHandlerContext<DateValidationError>,
  ) => {
    if (ctx.validationError === null && newValue !== null) {
      onChange(newValue ?? null, ctx)
    }
  }
  useEffect(() => {
    import(`date-fns/locale/${navigator.language}`).then((v) => {
      setLocale(v.default)
    })
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <MuiDatePicker
        value={value}
        slots={{
          layout: CustomLayout,
          actionBar: ActionBar,
        }}
        slotProps={{
          textField: {
            placeholder: placeholder,
            sx: {
              '& .MuiOutlinedInput-root': {
                backgroundColor: color.white,
                width: '180px',
              },
              '.MuiOutlinedInput-input': {
                fontSize: '14px',
                height: '25px',
                padding: '8.5px 14px',
              },
            },
          },
        }}
        onChange={customOnChange}
      />
    </LocalizationProvider>
  )
})

const ActionBar = memo((props: PickersActionBarProps) => {
  const { onAccept, onClear, onCancel } = props
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '24px' }}>
      <Button onClick={onCancel}>キャンセル</Button>
      <Stack direction="row" spacing={'8px'}>
        <Button onClick={onClear}>クリア</Button>
        <Button onClick={onAccept}>適用する</Button>
      </Stack>
    </Box>
  )
})

const CustomLayout = memo((props: PickersLayoutProps<Date | null, Date, DateView>) => {
  const { content, actionBar } = usePickerLayout(props)

  return (
    <PickersLayoutRoot ownerState={props}>
      <PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
        {content}
        {actionBar}
      </PickersLayoutContentWrapper>
    </PickersLayoutRoot>
  )
})
