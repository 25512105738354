import {
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
	differenceInMonths,
	differenceInYears,
} from "date-fns";

export function stringDateDiff(dateString: string): string {
	const now = new Date();
	const pastDate = new Date(dateString);
	const diffTime = Math.abs(now.getTime() - pastDate.getTime());

	// 日数差
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	// 年数差
	const diffYears = Math.floor(diffDays / 365);

	if (diffYears > 0) {
		return `${diffYears}年前`;
	}
	return `${diffDays}日前`;
}

export function displayDateAgo(date: Date): string {
	const now = new Date();

	const diffInYears = differenceInYears(now, date);
	if (diffInYears > 0) {
		return `${diffInYears}年前`;
	}

	const diffInMonths = differenceInMonths(now, date);
	if (diffInMonths > 0) {
		return `${diffInMonths}ヶ月前`;
	}

	const diffInDays = differenceInDays(now, date);
	if (diffInDays > 0) {
		return `${diffInDays}日前`;
	}

	const diffInHours = differenceInHours(now, date);
	if (diffInHours > 0) {
		return `${diffInHours}時間前`;
	}

	const diffInMinutes = differenceInMinutes(now, date);
	if (diffInMinutes > 0) {
		return `${diffInMinutes}分前`;
	}

	return "たった今";
}
