import { Box, Grid, Stack, Typography } from '@mui/material'
import { memo } from 'react'
import type { AccountSubImage } from '../../../types/account'

interface AccountImagesProps {
  subImages?: AccountSubImage[]
  onClick: (img: string) => void
}

export const AccountImages: React.FC<AccountImagesProps> = memo(({ subImages, onClick }) => {
  return (
    <Stack
      direction={'column'}
      alignItems={'top'}
      sx={{
        width: '310px',
        height: 'inherit',
      }}
    >
      <Typography variant="h4">商品イメージ</Typography>
      <Grid
        container={true}
        sx={{
          marginTop: '14px',
          '& .MuiGrid-item': {
            padding: '0px',
            height: '30%',
          },
        }}
      >
        {subImages?.map((item, _) => (
          <Grid item xs={6} key={`${item.accountId}-${item.orderIndex}`}>
            <Box
              sx={{
                width: '150px',
                height: '150px',
                backgroundImage: `url(${item.imageUrl})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                border: '3px solid #E5E5E5',
                borderRadius: '6px',
              }}
              onClick={() => onClick(item.imageUrl)}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
})
