import MoodIcon from "@mui/icons-material/Mood";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import {
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Radio,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { memo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { TransactionStatusTypes } from "../../../enum/transactionStatus";
import { useUpdatePurchaseAccountReview } from "../../../hooks/api/purchases";
import {
	type EvaluateRequest,
	EvaluateRequestSchema,
} from "../../../hooks/api/schema/evaluateRequest";
import { useCreateUserPoint } from "../../../hooks/api/userPoints";
import { color } from "../../../styles/theme";

type EvaluateFormProps = {
	accountId: string;
	points: number;
	isBuyer: boolean;
	purchasedUserId: string;
	exhibitorUserId: string;
};

function ReviewTypeToNumber(reviewType: string): number {
	if (reviewType === "good") {
		return 1;
	}
	return -1;
}

export const EvaluateForm = memo(
	({
		accountId,
		points,
		isBuyer,
		purchasedUserId,
		exhibitorUserId,
	}: EvaluateFormProps) => {
		const { register, setValue, setError, handleSubmit } =
			useForm<EvaluateRequest>();
		const [selectedRadioValue, setSelectedRadioValue] = useState("");
		const [recieved, setRecieved] = useState(false);
		const [reviewButtonDisabled, setReviewButtonDisabled] = useState(true);

		const { updateReview } = useUpdatePurchaseAccountReview();
		const { createUserPoint } = useCreateUserPoint();
		const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			setSelectedRadioValue(event.target.value);
			setValue("review", ReviewTypeToNumber(event.target.value));
			setReviewButtonDisabled(isBuyer && !recieved);
		};

		const handleRecivedChange = (
			event: React.ChangeEvent<HTMLInputElement>,
		) => {
			setRecieved(event.target.checked);
			setReviewButtonDisabled(
				isBuyer && (!event.target.checked || selectedRadioValue === ""),
			);
		};

		const handleEvaluateClick = async (formData: EvaluateRequest) => {
			if (isBuyer) {
				formData.transactionStatus =
					TransactionStatusTypes.WAITING_EXHIBITOR_EVALUATION;
			} else {
				formData.transactionStatus = TransactionStatusTypes.SUCCESS;
			}

			formData.purchasedUserId = purchasedUserId;
			formData.exhibitorUserId = exhibitorUserId;

			try {
				EvaluateRequestSchema.parse(formData);
				await updateReview(accountId, formData)
					.then(async () => {
						if (formData.transactionStatus === TransactionStatusTypes.SUCCESS) {
							await createUserPoint({
								points,
								userId: purchasedUserId,
								saleStatus: 2,
							}).catch(() => {
								formData.transactionStatus =
									TransactionStatusTypes.WAITING_EXHIBITOR_EVALUATION;
								updateReview(accountId, formData);
							});
						}
						window.location.reload();
					})
					.catch((error) => {
						throw error;
					});
			} catch (error) {
				if (error instanceof z.ZodError) {
					for (const err of error.errors) {
						setError(err.path[0] as keyof EvaluateRequest, {
							type: "manual",
							message: err.message,
						});
					}
				}
			}
		};

		return (
			<>
				<Stack direction={"row"} sx={{ gap: "40px" }}>
					<Stack
						alignItems={"center"}
						sx={{
							gap: "5px",
						}}
					>
						<MoodIcon fontSize="large" color="error" />
						<Typography variant={"h4"}>良い</Typography>
						<Radio
							name="radio-buttons"
							value="good"
							checked={selectedRadioValue === "good"}
							onChange={handleRadioChange}
							inputProps={{ "aria-label": "good" }}
							color="error"
							sx={{
								padding: "0px",
							}}
						/>
					</Stack>
					<Stack
						alignItems={"center"}
						sx={{
							gap: "5px",
						}}
					>
						<SentimentVeryDissatisfiedIcon fontSize="large" color="primary" />
						<Typography variant={"h4"}>悪い</Typography>
						<Radio
							name="radio-buttons"
							value="bad"
							checked={selectedRadioValue === "bad"}
							onChange={handleRadioChange}
							inputProps={{ "aria-label": "bad" }}
							color="error"
							sx={{
								padding: "0px",
							}}
						/>
					</Stack>
				</Stack>
				<TextField
					{...register("comment")}
					placeholder="例) 丁寧な取引ありがとうございました。"
					multiline={true}
					minRows={2}
					sx={{
						width: "100%",
					}}
				/>
				<Stack
					width="100%"
					alignItems={"center"}
					sx={{
						backgroundColor: color.paleGray,
						padding: "10px",
						gap: "10px",
					}}
				>
					{isBuyer && (
						<FormGroup>
							<FormControlLabel
								control={<Checkbox onChange={handleRecivedChange} />}
								label="商品を受け取りました。"
							/>
						</FormGroup>
					)}
					<Button
						disabled={reviewButtonDisabled}
						sx={{
							width: "inherit",
						}}
						onClick={handleSubmit(handleEvaluateClick)}
					>
						<Typography>レビューを送信する</Typography>
					</Button>
				</Stack>
			</>
		);
	},
);
