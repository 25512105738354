import { BorderColor } from "@mui/icons-material";
import {
	Box,
	Button,
	Divider,
	Link,
	ListItemButton,
	ListItemText,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { memo, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import header_white from "../../../assets/images/header_white_320x36.png";
import homeImage from "../../../assets/images/home.jpg";
import { CustomAutocomplete } from "../../../components/CustomAutocomplete";
import { LoginModal } from "../../../components/common/modals/LoginModal";
import { RegisterModal } from "../../../components/common/modals/RegisterModal";
import { LoginProfile } from "../../../components/common/parts/LoginProfile";
import {
	type AccountOption,
	getCategoryOptions,
} from "../../../data/accountData";
import { RecommendAccounts } from "../../../enum/categories";
import { useGetAccountCategories } from "../../../hooks/api/accounts";
import { useCreateRegisterCodeAPI, useLoginAPI } from "../../../hooks/api/auth";
import { AuthContext } from "../../../hooks/context/auth";
import { color } from "../../../styles/theme";
import type { LoginRequest, RegisterRequest } from "../../../types/auth";

type FilterQuery = {
	accountName?: string;
	keyword?: string;
	followersFrom?: string;
	followersTo?: string;
	priceFrom?: string;
	priceTo?: string;
};

export const AccountHeader = memo(({ filter }: { filter: FilterQuery }) => {
	const navigate = useNavigate();
	const { login } = useLoginAPI();
	const { register } = useCreateRegisterCodeAPI();
	const { data } = useGetAccountCategories();
	const options = getCategoryOptions(data.accountCategories);
	const authContext = useContext(AuthContext);
	const [loginModalOpen, setLoginModalOpen] = useState(false);
	const [registerModalOpen, setRegisterModalOpen] = useState(false);
	const [chatOpen, setChatOpen] = useState(false);
	const [loginError, setLoginError] = useState(false);

	const [accountName, setAccountName] = useState<string | undefined>(
		filter.accountName,
	);
	const [keyword, setKeyword] = useState<string>(filter.keyword ?? "");
	const [followersFrom, setFollowersFrom] = useState<string>(
		filter.followersFrom ?? "",
	);
	const [followersTo, setFollowersTo] = useState<string>(
		filter.followersTo ?? "",
	);
	const [priceFrom, setPriceFrom] = useState<string>(filter.priceFrom ?? "");
	const [priceTo, setPriceTo] = useState<string>(filter.priceTo ?? "");

	const handleCreateRegisterCode = (data: RegisterRequest) => {
		register(data)
			.then((e) => {
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleSearch = () => {
		const search = new URLSearchParams();
		accountName && search.set("accountName", accountName);
		keyword && search.set("keyword", keyword);
		followersFrom && search.set("followersFrom", followersFrom.toString());
		followersTo && search.set("followersTo", followersTo.toString());
		priceFrom && search.set("priceFrom", priceFrom.toString());
		priceTo && search.set("priceTo", priceTo.toString());
		return navigate(`?${search.toString()}`);
	};

	const handleLogin = (data: LoginRequest) => {
		login(data)
			.then(() => {
				authContext?.loggedIn();
				setLoginModalOpen(false);
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
				setLoginError(true);
			});
	};

	const handleRootLink = (
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
	) => {
		event.preventDefault();
		navigate("/accounts", { replace: true });
	};

	return (
		<Box
			sx={{
				height: "720px",
				backgroundImage: `url(${homeImage})`,
				backgroundRepeat: "no-repeat",
				backgroundPositionX: "center",
				backgroundPositionY: "center",
				padding: "33px 0px 0px 0px",
				overflowX: "hidden",
			}}
		>
			<Box
				sx={{
					width: {
						xs: "718px",
						md: "750px",
						lg: "970px",
						xl: "1580px",
					},
					height: "66px",
					margin: "0px auto",
					padding: "0px 15px",
				}}
			>
				<Stack
					direction={"row"}
					alignItems={"center"}
					sx={{ width: "252px", float: "right" }}
				>
					{authContext?.auth ? (
						<LoginProfile auth={authContext.auth} />
					) : (
						<Stack
							direction={"row"}
							sx={{
								marginLeft: "30px",
								".MuiDivider-wrapper": {
									display: "flex",
									alignItems: "center",
									color: color.white,
									padding: 0,
								},
							}}
							divider={
								<Divider orientation={"horizontal"} flexItem={true}>
									/
								</Divider>
							}
						>
							<Link sx={{ color: color.white }}>
								<Typography
									variant="body2"
									sx={{
										color: color.white,
										textDecoration: "underline",
										cursor: "pointer",
									}}
									onClick={() => setLoginModalOpen(true)}
								>
									ログイン
								</Typography>
							</Link>
							<Link sx={{ color: color.white }}>
								<Typography
									variant="body2"
									sx={{
										color: color.white,
										textDecoration: "underline",
										cursor: "pointer",
									}}
									onClick={() => setRegisterModalOpen(true)}
								>
									ユーザー登録
								</Typography>
							</Link>
						</Stack>
					)}
				</Stack>
				<Link
					onClick={handleRootLink}
					sx={{
						display: "block",
						textAlign: "center",
						margin: "auto",
						width: "480px",
						backgroundImage: `url(${header_white})`,
						backgroundRepeat: "no-repeat",
						backgroundPositionX: "50%",
						backgroundPositionY: "0%",
						paddingTop: "45px",
						cursor: "pointer",
					}}
				/>
			</Box>
			<Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
				{/* ヘッダー項目 */}
				<Stack
					direction={"column"}
					sx={{
						width: {
							xs: "547px",
							md: "767px",
							lg: "885px",
							xl: "885px",
						},
						height: "500px",
						backgroundColor: "rgba(0, 0, 0, 0.4)",
						marginTop: "35px",
						overflow: "auto",
						padding: "20px",
					}}
				>
					<Stack direction={"row"} justifyContent={"center"}>
						<CustomAutocomplete
							options={options}
							value={
								accountName
									? options.find((option) => option.label === accountName) ??
										null
									: null
							}
							groupBy={(option) => {
								const accountOption = option as AccountOption;
								return accountOption.firstLetter;
							}}
							onChange={(_, newValue) => {
								setAccountName(newValue?.label);
							}}
							sx={{
								width: {
									xs: "347px",
									md: "567px",
									lg: "685px",
									xl: "685px",
								},
							}}
						/>
					</Stack>
					<Stack direction={"row"} justifyContent={"center"}>
						<Stack
							direction={"row"}
							justifyContent={"center"}
							alignItems={"center"}
						>
							<Typography
								sx={{
									color: color.white,
								}}
							>
								おすすめ :
							</Typography>
							{RecommendAccounts.map((account, index) => (
								<ListItemButton
									key={`${account.accountName}-${index}`}
									onClick={() => {
										const searchParams = new URLSearchParams();
										searchParams.set("accountName", account.accountName);
										setAccountName(account.accountName);
										return navigate(`?${searchParams.toString()}`);
									}}
									sx={{
										width: "auto",
										"&:hover": {
											backgroundColor: "transparent",
										},
									}}
								>
									<ListItemText
										primary={account.accountName}
										primaryTypographyProps={{
											color: color.white,
											fontSize: 12,
											fontWeight: 500,
										}}
										sx={{
											color: color.white,
											textDecoration: "underline",
										}}
									/>
								</ListItemButton>
							))}
						</Stack>
					</Stack>
					<Stack
						sx={{
							marginTop: "20px",
							gap: "20px",
						}}
					>
						<Stack direction={"row"} justifyContent={"center"}>
							<TextField
								id="outlined-basic"
								value={keyword ?? ""}
								placeholder="キーワード"
								variant="outlined"
								size="small"
								sx={{
									width: {
										xs: "347px",
										md: "567px",
										lg: "685px",
										xl: "685px",
									},
									backgroundColor: color.white,
								}}
								onChange={(e) => {
									setKeyword(e.target.value);
								}}
							/>
						</Stack>
						<Stack direction={"row"} justifyContent={"center"}>
							<Stack
								direction={"row"}
								alignItems={"center"}
								gap={"20px"}
								sx={{
									width: {
										xs: "347px",
										md: "567px",
										lg: "685px",
										xl: "685px",
									},
								}}
							>
								<Typography
									variant="h5"
									sx={{
										width: "100px",
										color: color.white,
									}}
								>
									フォロワー数
								</Typography>
								<TextField
									id="outlined-basic"
									value={followersFrom ?? ""}
									placeholder="from"
									variant="outlined"
									type="number"
									size="small"
									sx={{
										backgroundColor: color.white,
									}}
									onChange={(e) => {
										setFollowersFrom(e.target.value);
									}}
								/>
								<Typography
									variant="h3"
									sx={{
										color: color.white,
									}}
								>
									~
								</Typography>
								<TextField
									id="outlined-basic"
									value={followersTo ?? ""}
									placeholder="to"
									type="number"
									variant="outlined"
									size="small"
									sx={{
										backgroundColor: color.white,
									}}
									onChange={(e) => {
										setFollowersTo(e.target.value);
									}}
								/>
							</Stack>
						</Stack>
						<Stack direction={"row"} justifyContent={"center"}>
							<Stack
								direction={"row"}
								alignItems={"center"}
								gap={"20px"}
								sx={{
									width: {
										xs: "347px",
										md: "567px",
										lg: "685px",
										xl: "685px",
									},
								}}
							>
								<Typography
									variant="h5"
									sx={{
										width: "100px",
										color: color.white,
									}}
								>
									商品価格
								</Typography>
								<TextField
									id="outlined-basic"
									value={priceFrom ?? ""}
									placeholder="from"
									type="number"
									variant="outlined"
									size="small"
									sx={{
										backgroundColor: color.white,
									}}
									onChange={(e) => {
										setPriceFrom(e.target.value);
									}}
								/>
								<Typography
									variant="h3"
									sx={{
										color: color.white,
									}}
								>
									~
								</Typography>
								<TextField
									id="outlined-basic"
									value={priceTo ?? ""}
									placeholder="to"
									type="number"
									variant="outlined"
									size="small"
									sx={{
										backgroundColor: color.white,
									}}
									onChange={(e) => {
										setPriceTo(e.target.value);
									}}
								/>
							</Stack>
						</Stack>
						<Stack direction={"row"} justifyContent={"center"}>
							<Button
								variant="contained"
								color="primary"
								sx={{ width: 135, height: 45 }}
								onClick={handleSearch}
							>
								<Typography variant="h5">検索</Typography>
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
			<LoginModal
				open={loginModalOpen}
				onClose={() => setLoginModalOpen(false)}
				onSubmit={handleLogin}
				message={"ログイン"}
				alertMessage={
					loginError
						? "ログインに失敗しました。\nメールアドレスとパスワードを確認してください。"
						: undefined
				}
				confirmLabel={"ログイン"}
				confirmIcon={<BorderColor />}
			/>
			<RegisterModal
				open={registerModalOpen}
				onClose={() => setRegisterModalOpen(false)}
				onSubmit={handleCreateRegisterCode}
				message={"会員登録"}
				confirmLabel={"登録"}
				confirmIcon={<BorderColor />}
			/>
			{/* {chatOpen && (
				<Chat
					profileUser={profileUser}
					onClose={() => setChatOpen(false)}
				/>
			)} */}
		</Box>
	);
});
