import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import {
	Box,
	Button,
	IconButton,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { memo, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../../components/common/layouts/Footer";
import { ImageModal } from "../../../components/common/layouts/ImageModal";
import { ScrollToTop } from "../../../components/common/layouts/ScrollToTop";
import { DeleteAccountModal } from "../../../components/common/modals/DeleteAccountModal";
import { Inquiry } from "../../../components/common/parts/Inquiry";
import {
	useCreateAccountInquiry,
	useGetAccountDetail,
	useGetAccountInquiries,
} from "../../../hooks/api/accounts";
import { AuthContext } from "../../../hooks/context/auth";
import { color } from "../../../styles/theme";
import { SellingStatus } from "../../../types/account";
import { AccountImages } from "./AccountImages";

export const AccountDetail = memo(() => {
	const { register, handleSubmit, reset } = useForm();
	const { id } = useParams<{ id: string }>();
	const [isFavorite, setIsFavorite] = useState(false);
	const [openImageModal, setOpenImageModal] = useState(false);
	const [imageModalUrl, setImageModalUrl] = useState("");
	const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
	const navigate = useNavigate();
	ScrollToTop();
	const authContext = useContext(AuthContext);
	const { createAccountInquiry, error } = useCreateAccountInquiry();
	const { account, error: accountError } = useGetAccountDetail(id || "");
	const user = account?.user;

	const {
		accountInquiries,
		error: accountInquiriesError,
		mutate,
	} = useGetAccountInquiries(Number(id));

	if (!account || !user) {
		return <div>{accountError}</div>;
	}

	const handleFavoriteClick = () => {
		setIsFavorite(!isFavorite);
	};

	const handleUserClick = () => {
		navigate(`/user/${user.username}`);
	};

	const handleImageModalClose = () => {
		setOpenImageModal(false);
		setImageModalUrl("");
	};

	const handleImageClick = (url: string) => {
		setOpenImageModal(true);
		setImageModalUrl(url);
	};

	const handleCommentClick = async (payload: any) => {
		if (!payload.comment) {
			return;
		}

		if (authContext === undefined) {
			return;
		}

		if (authContext.auth === undefined) {
			return;
		}

		try {
			await createAccountInquiry({
				accountId: account.id.toString(),
				userId: authContext.auth.id.toString(),
				comment: payload.comment,
			});
			await mutate();
			reset({ comment: "" });
		} catch (error) {}
	};

	return (
		<>
			<Box>
				<Box sx={{ padding: "33px 115px 0px" }}>
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						sx={{ height: 280, gap: 6 }}
					>
						<Stack direction={"row"}>
							<Box
								sx={{
									width: "240px",
									height: "240px",
									backgroundImage: `url(${account.image})`,
									backgroundSize: "cover",
									backgroundRepeat: "no-repeat",
									border: "3px solid #E5E5E5",
									borderRadius: "6px",
								}}
								onClick={() => handleImageClick(account.image)}
							/>
							<Box
								sx={{
									width: "587px",
									marginTop: "50px",
									marginLeft: "48px",
								}}
							>
								<Typography
									variant="h2"
									sx={{
										marginBottom: "7px",
									}}
								>
									{account.accountName}
								</Typography>
								<Box
									sx={{
										borderLeft: `2px solid ${color.purple}`,
										paddingLeft: "15px",
										gap: "7px",
									}}
								>
									<Typography>
										フォロワー数:{" "}
										{`${account.followers.replace(
											/\B(?=(\d{3})+(?!\d))/g,
											",",
										)}`}
									</Typography>
								</Box>
								<Typography
									variant="h1"
									sx={{
										marginTop: "27px",
									}}
								>
									{`¥${account.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
								</Typography>
								{/* NOTE: いつかお気に入り機能を実装する予定があるため今はコメントアウト */}
								{/* {isFavorite ? (
										<StarIcon
											style={{ color: "red" }}
											onClick={handleFavoriteClick}
										/>
									) : (
										<StarBorderIcon
											style={{ color: "red" }}
											onClick={handleFavoriteClick}
										/>
									)} */}
							</Box>
						</Stack>
						<Box
							sx={{
								marginBottom: "65px",
							}}
						>
							<Stack
								direction={"row"}
								sx={{
									width: "310px",
									height: "54px",
									marginTop: "53px",
									border: "1px solid #E7E7E7",
									borderRadius: "27px 8px 0px 27px",
									transformDuration: "0.2s",
									"&:hover": {
										boxShadow: "0 0 12px rgba(0, 0, 0, 0.13)",
									},
								}}
								onClick={handleUserClick}
							>
								<Box
									sx={{
										width: "60px",
										height: "60x",
										margin: "-3px 0px 0px",
									}}
								>
									{user.avatar ? (
										<img
											src={user.avatar}
											width="60px"
											height="60px"
											loading="lazy"
											alt="avatar"
											style={{
												borderRadius: "50%",
												border: `1px solid ${color.salmon}`,
											}}
										/>
									) : (
										<AccountCircleIcon
											sx={{
												width: "60px",
												height: "60px",
												borderRadius: "50%",
												border: `1px solid ${color.salmon}`,
												color: color.midGray,
											}}
										/>
									)}
								</Box>
								<Box
									sx={{
										width: "120px",
										margin: "8px 0px 0px 20px",
									}}
								>
									<Typography variant="subtitle2">{user.username}</Typography>
									<Typography variant="body1">rating: {user.rating}</Typography>
								</Box>
								<Stack
									direction={"row"}
									sx={{ width: "120px", marginTop: "17px", gap: "15px" }}
								>
									<Typography
										variant="subtitle2"
										sx={{
											color: "#00D50A",
										}}
									>
										+{user.reviewPlus}
									</Typography>
									<Typography
										variant="subtitle2"
										sx={{
											color: "#BFBFBF",
										}}
									>
										{user.reviewMiddle}
									</Typography>
									<Typography
										variant="subtitle2"
										sx={{
											color: "#FF0000",
										}}
									>
										-{user.reviewMinus}
									</Typography>
								</Stack>
							</Stack>
						</Box>
					</Stack>
					<Stack
						direction={"row"}
						alignItems={"flex-end"}
						sx={{
							marginTop: "45px",
							height: "750px",
						}}
					>
						<Stack
							direction={"column"}
							justifyContent={"space-between"}
							sx={{
								width: "65%",
								height: "inherit",
								margin: "0px 79px 0px 0px",
							}}
						>
							<Box>
								<Typography
									variant="h4"
									sx={{
										marginBottom: "10px",
									}}
								>
									概要
								</Typography>
								<Typography
									variant="body1"
									sx={{
										height: "500px",
										overflowY: "auto",
										overflowX: "hidden",
										whiteSpace: "pre-line",
									}}
								>
									{account.description}
								</Typography>
							</Box>
						</Stack>
						<AccountImages
							subImages={account.subImages}
							onClick={handleImageClick}
						/>
					</Stack>
					<Stack
						sx={{
							marginTop: "50px",
							gap: "20px",
						}}
					>
						<Stack
							direction={"row"}
							sx={{
								gap: "5px",
							}}
						>
							{authContext?.auth?.id &&
							authContext.auth.id === account.userId &&
							account.sellingStatus === SellingStatus.Selling ? (
								<>
									<Button
										color="secondary"
										sx={{
											height: "70px",
											width: "100%",
											borderRadius: "10px",
										}}
										onClick={() => setDeleteAccountModalOpen(true)}
									>
										<Typography variant="h5">商品削除</Typography>
									</Button>
									<Button
										sx={{
											height: "70px",
											width: "100%",
											borderRadius: "10px",
										}}
										onClick={() => navigate(`/accounts/edit/${account.id}`)}
									>
										<Typography variant="h5">編集する</Typography>
									</Button>
								</>
							) : (
								<Button
									disabled={account.sellingStatus > SellingStatus.Selling}
									sx={{
										height: "70px",
										width: "100%",
										borderRadius: "10px",
									}}
									onClick={() => navigate(`/accounts/purchase/${account.id}`)}
								>
									<Typography variant="h5">
										{account.sellingStatus > SellingStatus.Selling
											? "取引中"
											: "購入する"}
									</Typography>
								</Button>
							)}
						</Stack>
						<Box>
							<Typography
								variant="h3"
								sx={{
									backgroundColor: color.black,
									color: color.white,
									padding: "10px",
								}}
							>
								質問
							</Typography>
							<Box
								sx={{
									width: "100%",
									backgroundColor: "#F5F5F5",
									gap: "20px",
									padding: "20px",
								}}
							>
								{accountInquiries?.map((item, _) => (
									<Inquiry
										key={item.id}
										isOwner={item.userId === account.userId}
										inquiry={item}
									/>
								))}
							</Box>
						</Box>
						<Stack
							direction={"column"}
							sx={{
								gap: "8px",
							}}
						>
							{authContext?.auth === undefined && (
								<Typography variant="h5" color={"red"}>
									※質問にはログインが必要です
								</Typography>
							)}
							<TextField
								variant="outlined"
								multiline={true}
								placeholder="売り手へのコメントを入力してください。"
								rows={4}
								disabled={authContext?.auth === undefined}
								sx={{ resize: "none", width: "100%" }}
								{...register("comment")}
							/>
							<Stack direction={"row"} justifyContent={"flex-end"}>
								<Button
									disabled={authContext?.auth === undefined}
									onClick={handleSubmit(handleCommentClick)}
								>
									送信
								</Button>
							</Stack>
						</Stack>
					</Stack>
				</Box>
			</Box>
			<Footer />
			<ImageModal
				open={openImageModal}
				imageUrl={imageModalUrl}
				onClose={handleImageModalClose}
			/>
			<DeleteAccountModal
				open={deleteAccountModalOpen}
				accountId={account.id}
				onClose={() => setDeleteAccountModalOpen(false)}
			/>
			{authContext?.auth && (
				<Box
					sx={{
						position: "fixed",
						bottom: "10px",
						right: "10px",
					}}
				>
					<IconButton
						sx={{
							width: "100px",
							height: "100px",
							border: `1px solid ${color.midGray}`,
							borderRadius: "50%",
							backgroundColor: color.red,
							"&:hover": {
								backgroundColor: color.red,
								boxShadow: `0 0 10px ${color.red}, 0 0 20px ${color.red}`,
							},
						}}
						onClick={() => navigate("/accounts/create")}
					>
						<AddAPhotoIcon
							sx={{
								color: color.white,
								fontSize: "40px",
							}}
						/>
					</IconButton>
				</Box>
			)}
		</>
	);
});
