import { KeyboardArrowDown } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
	Box,
	IconButton,
	ListItemButton,
	ListItemText,
	Stack,
} from "@mui/material";
import { memo, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLogoutAPI } from "../../../hooks/api/auth";
import { AuthContext } from "../../../hooks/context/auth";
import { color } from "../../../styles/theme";
import type { Auth } from "../../../types/auth";

export const LoginProfile = memo(({ auth }: { auth: Auth }) => {
	const [profileOpen, setProfileOpen] = useState(false);
	const { logout } = useLogoutAPI();
	const navigate = useNavigate();
	const authContext = useContext(AuthContext);
	const profileItems = [
		{
			label: "プロフィール",
			command: (event: React.MouseEvent<HTMLElement>) => {
				handleProfileNavigate(event);
			},
		},
	];

	if (auth.role === 1) {
		profileItems.push({
			label: "取引状態一覧",
			command: (event: React.MouseEvent<HTMLElement>) => {
				handleTradingsNavigate(event);
			},
		});
		profileItems.push({
			label: "出金依頼一覧",
			command: (event: React.MouseEvent<HTMLElement>) => {
				handleUserPointWithdrawalNavigate(event);
			},
		});
	} else {
		profileItems.push({
			label: "取引履歴一覧",
			command: (event: React.MouseEvent<HTMLElement>) => {
				handleTradingsNavigate(event);
			},
		});
	}

	profileItems.push({
		label: "ポイント",
		command: (event: React.MouseEvent<HTMLElement>) => {
			handleUserPointNavigate(event);
		},
	});

	profileItems.push({
		label: "ログアウト",
		command: (event: React.MouseEvent<HTMLElement>) => handleLogout(event),
	});

	const handleProfileNavigate = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		navigate(`/user/${auth.username}`);
	};

	const handleTradingsNavigate = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		navigate("/accounts/tradings/list");
	};

	const handleUserPointNavigate = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		navigate(`/user/point/${auth.id}`);
	};

	const handleUserPointWithdrawalNavigate = (
		event: React.MouseEvent<HTMLElement>,
	) => {
		event.preventDefault();
		navigate("/user/point/withdrawal/request/list");
	};

	const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		logout().then(() => {
			authContext?.loggedOut();
			navigate("/");
		});
	};
	return (
		<Box onClick={() => setProfileOpen(!profileOpen)}>
			<Stack
				direction={"row"}
				alignItems={"center"}
				sx={{
					gap: "10px",
				}}
			>
				{auth.avatar ? (
					<IconButton
						sx={{
							border: `1px solid ${color.white}`,
							backgroundColor: color.midGray,
							backgroundImage: `url(${auth.avatar})`,
							backgroundPosition: "center",
							backgroundSize: "contain",
							backgroundRepeat: "no-repeat",
							width: "35px",
							height: "35px",
							padding: "0px",
							"&:hover": {
								backgroundColor: color.midGray,
								boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
							},
						}}
					/>
				) : (
					<AccountCircleIcon fontSize="large" />
				)}
				<ListItemButton
					disableRipple={true}
					sx={{
						color: color.white,
						"&:hover, &:focus": {
							textDecoration: "underline",
						},
						"& svg": {
							opacity: 1,
						},
					}}
				>
					<ListItemText
						primary={`${auth.username}`}
						primaryTypographyProps={{
							fontSize: 15,
							fontWeight: "medium",
							lineHeight: "20px",
						}}
					/>
					<KeyboardArrowDown
						sx={{
							mr: -1,
							opacity: 0,
							transform: profileOpen ? "rotate(-180deg)" : "rotate(0)",
							transition: "0.2s",
						}}
					/>
				</ListItemButton>
			</Stack>
			{profileOpen && (
				<Box
					position="absolute"
					width="160px"
					sx={{
						backgroundColor: color.white,
						border: `1px solid ${color.white}`,
						borderRadius: "15px 0px 15px 15px",
						boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.15)",
						padding: "15px 0px 5px 30px",
					}}
				>
					{profileItems.map((item) => (
						<ListItemButton
							key={item.label}
							sx={{
								marginTop: "5px",
								marginBottom: "15px",
								color: color.black,
								padding: "0px",
								"&:hover": {
									backgroundColor: color.white,
								},
							}}
							onClick={item.command}
						>
							<ListItemText
								primary={item.label}
								primaryTypographyProps={{ fontSize: 12, fontWeight: 500 }}
								sx={{
									"&:hover": {
										textDecoration: "underline",
									},
								}}
							/>
						</ListItemButton>
					))}
				</Box>
			)}
		</Box>
	);
});
