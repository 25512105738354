import {
	Box,
	Button,
	Stack,
	Step,
	type StepIconProps,
	StepLabel,
	Stepper,
	TextField,
	Typography,
	styled,
} from "@mui/material";
import StepConnector, {
	stepConnectorClasses,
} from "@mui/material/StepConnector";
import { memo } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
	type AuthRegisterResponse,
	useSendOPTAPI,
	useUpdateRegisterCodeAPI,
} from "../../../hooks/api/auth";
import { color } from "../../../styles/theme";

const ColorlibConnector = styled(StepConnector)(() => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 12,
		left: "calc(-50%)",
		right: "calc(50%)",
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: color.purple,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderTopWidth: 3,
		borderColor: color.paleGray,
	},
}));

const ColorlibStepIconRoot = styled("div")<{
	ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
	backgroundColor: color.paleGray,
	zIndex: 1,
	width: 25,
	height: 25,
	borderRadius: "50%",
	...(ownerState.active && {
		backgroundColor: color.purple,
	}),
	...(ownerState.completed && {
		backgroundColor: color.purple,
	}),
}));

function ColorlibStepIcon(props: StepIconProps) {
	const { active, completed, className } = props;

	return (
		<ColorlibStepIconRoot
			ownerState={{ completed, active }}
			className={className}
		/>
	);
}

export const TwoFactor = memo(() => {
	const steps = ["仮登録完了", "本登録完了", "SMS認証"];
	const smsDescription =
		"SMS認証を完了すると、新規案件登録、商品の購入がすぐにご利用できます！\nSMSが自身可能な電話番号を入力してください。認証コードを送付します。";
	const navigate = useNavigate();
	const location = useLocation();
	const { updateRegister } = useUpdateRegisterCodeAPI();
	const { sendSMS } = useSendOPTAPI();
	const { register, handleSubmit } = useForm();
	const handleTelClick = async (payload: any) => {
		const regex = /^0\d{1,4}\d{1,4}\d{3,4}$/;
		const tel = payload.tel.replace(/-/g, "");

		if (!regex.test(tel)) {
			return;
		}

		sendSMS(tel).then((r) => {
			const authRegister = location.state.authRegister;
			updateRegister({
				email: authRegister.email,
				tel,
				username: authRegister.username,
				password: authRegister.password,
			}).then((data: AuthRegisterResponse) => {
				navigate("/confirm/sms-factor", {
					state: { authRegister: data.authRegister },
				});
			});
		});
	};

	return (
		<Stack
			direction={"column"}
			sx={{
				width: "100%",
				backgroundColor: color.paleGray,
				gap: "5px",
			}}
		>
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				alignItems={"center"}
				sx={{
					backgroundColor: color.jewelGreen,
					padding: "10px",
				}}
			>
				<Typography variant="subtitle1" color={color.white}>
					メールアドレスの認証が完了しました。
				</Typography>
			</Stack>
			<Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
				<Typography variant="h4">M & A</Typography>
				<Typography variant="h5">CLUB</Typography>
			</Stack>
			<Stack
				direction={"column"}
				height={"100vh"}
				justifyContent={"start"}
				alignItems={"center"}
			>
				<Stack
					direction={"column"}
					alignItems={"center"}
					sx={{
						width: "700px",
						height: "500px",
						backgroundColor: color.white,
						padding: "40px 60px 40px",
						gap: "20px",
					}}
				>
					<Typography variant="h5" sx={{ fontWeight: 700 }}>
						会員登録完了まであとワンステップです！
					</Typography>
					<Box>
						<Typography
							variant="subtext1"
							sx={{
								fontWeight: 700,
								whiteSpace: "pre-line",
							}}
						>
							{smsDescription}
						</Typography>
					</Box>
					<Box
						sx={{
							width: "100%",
						}}
					>
						<Stepper
							alternativeLabel
							activeStep={1}
							connector={<ColorlibConnector />}
						>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel StepIconComponent={ColorlibStepIcon}>
										{label}
									</StepLabel>
								</Step>
							))}
						</Stepper>
					</Box>
					<Box
						sx={{
							width: "100%",
							borderBottom: `1px solid ${color.paleGray}`,
						}}
					/>
					<TextField
						size="small"
						placeholder="電話番号を入力してください"
						fullWidth={true}
						{...register("tel")}
					/>
					<Button
						fullWidth={true}
						sx={{
							borderRadius: "0px",
						}}
						onClick={handleSubmit(handleTelClick)}
					>
						<Typography variant="button">認証を行う</Typography>
					</Button>
					<Stack
						direction={"row"}
						justifyContent={"start"}
						alignItems={"start"}
						sx={{
							width: "100%",
						}}
					>
						<Typography variant="subtext1">
							※電話番号はほかユーザーに公開されません。
						</Typography>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
});
