export enum SaleStatusTypes {
	PENDING = 1,
	APPROVED = 2,
	REJECTED = 3,
}

export type SaleStatusType = SaleStatusTypes;

export const SaleStatusStrings = {
	[SaleStatusTypes.PENDING]: "申請中",
	[SaleStatusTypes.APPROVED]: "承認済み",
	[SaleStatusTypes.REJECTED]: "却下済み",
} as const;

export type SaleStatusString = (typeof SaleStatusStrings)[SaleStatusType];
