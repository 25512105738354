import {
	Button,
	FormControl,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { useState } from "react";
import type { WithdrawalQueryParams } from "../../../../hooks/api/withdrawal";
import { color } from "../../../../styles/theme";

export const WithdrawalRequestHeader = ({
	queryParams,
	onSearch,
}: {
	queryParams: WithdrawalQueryParams;
	onSearch: (query: WithdrawalQueryParams) => void;
}) => {
	const [userId, setUserId] = useState<string | undefined>(queryParams.userId);
	const [saleStatus, setSaleStatus] = useState<number | undefined>(
		queryParams.saleStatus,
	);

	return (
		<Stack
			sx={{
				width: "800px",
				height: "300px",
				backgroundColor: color.matGray,
				padding: "20px",
				gap: "20px",
			}}
		>
			<Stack
				direction={"row"}
				alignItems={"center"}
				sx={{
					width: "100%",
				}}
			>
				<Typography variant="h4" width={"130px"}>
					ユーザーID
				</Typography>
				<TextField
					value={userId || ""}
					sx={{
						backgroundColor: color.white,
						"& .MuiInputBase-root": {
							height: "40px",
						},
					}}
					onChange={(e) => {
						setUserId(e.target.value);
					}}
				/>
			</Stack>
			<Stack
				direction={"row"}
				alignItems={"center"}
				sx={{
					width: "100%",
				}}
			>
				<Typography variant="h4" width={"130px"}>
					出金申請状態
				</Typography>
				<FormControl>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={saleStatus ?? ""}
						onChange={(e) => setSaleStatus(Number(e.target.value))}
						sx={{
							width: "300px",
							height: "40px",
							backgroundColor: color.white,
						}}
					>
						<MenuItem value={1}>申請中</MenuItem>
						<MenuItem value={2}>承認済み</MenuItem>
						<MenuItem value={3}>却下済み</MenuItem>
					</Select>
				</FormControl>
			</Stack>
			<Stack
				sx={{
					alignItems: "center",
				}}
			>
				<Button
					sx={{
						width: "200px",
					}}
					onClick={() =>
						onSearch({
							userId,
							saleStatus,
						})
					}
				>
					<Typography variant="h5">検索</Typography>
				</Button>
			</Stack>
		</Stack>
	);
};
