export enum BankAccountTypes {
	PLAIN = "plain",
	DANGANESE = "danganese",
}

export type BankAccountType = BankAccountTypes;

export const BankAccountTypeStrings = {
	[BankAccountTypes.PLAIN]: "普通",
	[BankAccountTypes.DANGANESE]: "当座",
} as const;

export type BankAccountTypeString =
	(typeof BankAccountTypeStrings)[BankAccountType];
