import useSWR from "swr";
import { fetcher } from "./fetcher";

export type TradeUserMessage = {
	senderId: string;
	senderName: string;
	content: string;
	updatedAt: Date;
};

type TradeUserMessageData = {
	messages?: TradeUserMessage[];
	error: string | null;
};

export const useGetTradeUserMessages = (
	accountId: string,
): {
	responses: TradeUserMessage[] | undefined;
	error: string | null;
} => {
	const key = `/api/trade_user_message/${accountId}`;
	const { data, error } = useSWR<TradeUserMessageData>(key, fetcher);

	if (error) {
		return {
			responses: undefined,
			error: "エラーが発生しました",
		};
	}

	return {
		responses: data?.messages || [],
		error: null,
	};
};
