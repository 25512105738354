import {
	Button,
	FormControl,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { BasicDatePicker } from "../../../components/common/parts/BasicDatePicker";
import type { PurchasedAccountQueryParams } from "../../../hooks/api/purchases";
import { color } from "../../../styles/theme";

export const TradingHeader = ({
	queryParams,
	onSearch,
}: {
	queryParams: PurchasedAccountQueryParams;
	onSearch: (query: PurchasedAccountQueryParams) => void;
}) => {
	const [id, setId] = useState<string | undefined>(queryParams.id);
	const [startDate, setStartDate] = useState<string | undefined>(
		queryParams.startDate,
	);
	const [endDate, setEndDate] = useState<string | undefined>(
		queryParams.endDate,
	);
	const [transactionStatus, setTransactionStatus] = useState<
		string | undefined
	>(queryParams.transactionStatus);

	return (
		<Stack
			sx={{
				width: "800px",
				height: "300px",
				backgroundColor: color.matGray,
				padding: "20px",
				gap: "20px",
			}}
		>
			<Stack
				direction={"row"}
				alignItems={"center"}
				sx={{
					width: "100%",
					gap: "10px",
				}}
			>
				<Typography variant="h4">チャットID</Typography>
				<TextField
					value={id || ""}
					sx={{
						backgroundColor: color.white,
						"& .MuiInputBase-root": {
							height: "40px",
						},
					}}
					onChange={(e) => {
						setId(e.target.value);
					}}
				/>
			</Stack>
			<Stack
				direction={"row"}
				alignItems={"center"}
				sx={{
					width: "100%",
					gap: "45px",
				}}
			>
				<Typography variant="h4">取引日</Typography>
				<Stack
					direction={"row"}
					alignItems={"center"}
					sx={{
						gap: "10px",
					}}
				>
					<BasicDatePicker
						value={startDate ? new Date(startDate) : null}
						placeholder="From"
						onChange={(date: Date | null) => {
							setStartDate(date ? format(date, "yyyy/MM/dd") : undefined);
						}}
					/>
					<Typography variant="h3">〜</Typography>
					<BasicDatePicker
						value={endDate ? new Date(endDate) : null}
						placeholder="To"
						onChange={(date: Date | null) => {
							setEndDate(date ? format(date, "yyyy/MM/dd") : undefined);
						}}
					/>
				</Stack>
			</Stack>
			<Stack
				direction={"row"}
				alignItems={"center"}
				sx={{
					width: "100%",
					gap: "26px",
				}}
			>
				<Typography
					variant="h4"
					sx={{
						whiteSpace: "nowrap",
					}}
				>
					取引状態
				</Typography>
				<FormControl fullWidth={true}>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={transactionStatus ?? ""}
						onChange={(e) => setTransactionStatus(e.target.value)}
						sx={{
							width: "300px",
							height: "40px",
							backgroundColor: color.white,
						}}
					>
						<MenuItem value={1}>支払い待ち</MenuItem>
						<MenuItem value={2}>商品お渡し</MenuItem>
						<MenuItem value={3}>売上反映</MenuItem>
					</Select>
				</FormControl>
			</Stack>
			<Stack
				sx={{
					alignItems: "center",
				}}
			>
				<Button
					sx={{
						width: "200px",
					}}
					onClick={() =>
						onSearch({
							id,
							startDate,
							endDate,
							transactionStatus,
						})
					}
				>
					<Typography variant="h5">検索</Typography>
				</Button>
			</Stack>
		</Stack>
	);
};
