import { zodResolver } from "@hookform/resolvers/zod";
import {
	Box,
	Button,
	FormControlLabel,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { memo, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
	type CreateUserBankAccountRequest,
	useCreateUserBankPoint,
} from "../../../../hooks/api/bank";
import {
	type UserBankAccountRequest,
	UserBankAccountRequestSchema,
} from "../../../../hooks/api/schema/userBankAccountRequest";
import { AuthContext } from "../../../../hooks/context/auth";
import { TableItem } from "../../../../pages/accounts/purchase/TableItem";
import { color } from "../../../../styles/theme";
import type { CommonOption } from "../../../../types/commonOption";
import { CustomTextareaAutosize } from "../../../styled/TextareaAutosize";
import { BankBranchPulldown } from "./BankBranchPulldown";
import { BankPulldown } from "./BankPulldown";

type BankAccountFormProps = {
	onClose: () => void;
	onCancel: () => void;
};

export const BankAccountForm = memo(
	({ onClose, onCancel }: BankAccountFormProps) => {
		const authContext = useContext(AuthContext);
		const [bank, setBank] = useState<CommonOption | null>(null);
		const [bankBranch, setBankBranch] = useState<CommonOption | null>(null);
		const [accountType, setAccountType] = useState<string>("plain");
		const { createUserBankPoint } = useCreateUserBankPoint();
		const { register, setValue, control, handleSubmit, clearErrors } =
			useForm<UserBankAccountRequest>({
				defaultValues: {
					bankCode: "",
					bankName: "",
					branchCode: "",
					branchName: "",
					accountType: "plain",
					accountNumber: "",
				},
				resolver: zodResolver(UserBankAccountRequestSchema),
			});

		const onSubmit = (payload: UserBankAccountRequest) => {
			if (!authContext?.auth?.id) {
				return <Box>ログインしてください</Box>;
			}
			clearErrors();
			try {
				UserBankAccountRequestSchema.parse(payload);
				const req: CreateUserBankAccountRequest = {
					userId: authContext.auth.id,
					defaultAccount: true,
					...payload,
				};
				createUserBankPoint(req).then((res) => {
					alert("振込先を登録しました");
					onClose();
				});
			} catch (error) {}
		};

		return (
			<Stack
				sx={{
					gap: "10px",
				}}
			>
				<Box>
					<TableItem
						title={"銀行名"}
						titleWidth={"30%"}
						titleVariant={"subtitle1"}
						sx={{
							border: `1px solid ${color.paleGray}`,
							alignItems: "center",
						}}
					>
						<Controller
							name="bankCode"
							control={control}
							render={({ field }) => (
								<BankPulldown
									value={bank ?? null}
									onChange={(_, newValue) => {
										field.onChange(newValue?.value);
										setBank(newValue);
										setValue("bankCode", newValue?.value.toString() ?? "");
										setValue("bankName", newValue?.label ?? "");
									}}
								/>
							)}
						/>
					</TableItem>
					<TableItem
						title={"支店名"}
						titleWidth={"30%"}
						titleVariant={"subtitle1"}
						sx={{
							border: `1px solid ${color.paleGray}`,
							alignItems: "center",
						}}
					>
						<Controller
							name="branchCode"
							control={control}
							render={({ field }) => (
								<BankBranchPulldown
									bankCode={bank?.value.toString() ?? ""}
									value={bankBranch ?? null}
									onChange={(_, newValue) => {
										field.onChange(newValue?.value);
										setBankBranch(newValue);
										setValue("branchCode", newValue?.value.toString() ?? "");
										setValue("branchName", newValue?.label ?? "");
									}}
								/>
							)}
						/>
					</TableItem>
					<TableItem
						title={"種別"}
						titleWidth={"30%"}
						titleVariant={"subtitle1"}
						sx={{
							border: `1px solid ${color.paleGray}`,
							alignItems: "center",
						}}
					>
						<Controller
							name="accountType"
							control={control}
							render={({ field }) => (
								<RadioGroup
									value={accountType ?? "plain"}
									row={true}
									aria-labelledby="radio-account-type"
									name="account-type-buttons-group"
									sx={{ padding: "0 10px" }}
									onChange={(e) => {
										const accountType = e.target.value;
										field.onChange(accountType);
										setAccountType(accountType);
										setValue("accountType", accountType);
									}}
								>
									<FormControlLabel
										value="plain"
										control={<Radio />}
										label="普通"
									/>
									<FormControlLabel
										value="danganese"
										control={<Radio />}
										label="当座"
									/>
								</RadioGroup>
							)}
						/>
					</TableItem>
					<TableItem
						title={"口座番号"}
						titleWidth={"30%"}
						titleVariant={"subtitle1"}
						sx={{
							border: `1px solid ${color.paleGray}`,
							alignItems: "center",
						}}
					>
						<TextField
							size="small"
							label="口座番号"
							{...register("accountNumber")}
							sx={{ width: "70%" }}
							onChange={(e) => {
								const accountNumber = e.target.value;
								setValue("accountNumber", accountNumber);
							}}
						/>
					</TableItem>
					<TableItem
						title={"口座名義"}
						titleWidth={"30%"}
						titleVariant={"subtitle1"}
						sx={{
							border: `1px solid ${color.paleGray}`,
							alignItems: "center",
						}}
					>
						<TextField
							size="small"
							label="口座名義"
							{...register("accountName")}
							sx={{ width: "70%" }}
							onChange={(e) => {
								const accountName = e.target.value;
								setValue("accountName", accountName);
							}}
						/>
					</TableItem>
				</Box>
				<CustomTextareaAutosize
					{...register("remarks")}
					minRows={7}
					placeholder={
						"該当する銀行が存在しない場合はこちらに口座情報を記載してください。"
					}
					sx={{
						width: "100%",
						resize: "none",
					}}
				/>
				<Button onClick={handleSubmit(onSubmit)}>
					<Typography variant="subtitle1">振込先を保存する</Typography>
				</Button>
				<Button color="secondary" onClick={onCancel}>
					<Typography variant="subtitle1">キャンセル</Typography>
				</Button>
			</Stack>
		);
	},
);
