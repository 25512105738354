import { Box } from "@mui/material";
import { useContext } from "react";
import { TransactionStatusStrings } from "../../../enum/transactionStatus";
import type { PurchasedAccountResponse } from "../../../hooks/api/purchases";
import { AuthContext } from "../../../hooks/context/auth";
import { color } from "../../../styles/theme";
import type { Account } from "../../../types/account";
import { TableItem } from "./TableItem";

type PurchaseInfoListProps = {
	purchasedAccount: PurchasedAccountResponse;
};

export const PurchaseInfoList = ({
	purchasedAccount,
}: PurchaseInfoListProps) => {
	const authContext = useContext(AuthContext);
	const account = purchasedAccount.account as Account;

	if (!authContext?.auth) {
		return <Box>ログインしてください</Box>;
	}

	const isExhibitor = authContext.auth.id === purchasedAccount.exhibitorUserId;
	return (
		<Box>
			<TableItem
				title="チャットID"
				content={purchasedAccount.id}
				contentVariant="subtitle1"
				sx={{
					border: `1px solid ${color.paleGray}`,
				}}
			/>
			<TableItem
				title="タイトル"
				linkProps={{
					isLink: true,
					accountTitle: account.accountName,
					href: `/accounts/${account.id}`,
				}}
				content={account.accountName}
				contentVariant="subtitle1"
				sx={{
					border: `1px solid ${color.paleGray}`,
					borderTop: "none",
				}}
			/>
			<TableItem
				title="ステータス"
				content={TransactionStatusStrings[purchasedAccount.transactionStatus]}
				contentVariant="subtitle1"
				sx={{
					border: `1px solid ${color.paleGray}`,
					borderTop: "none",
				}}
			/>
			<TableItem
				title={isExhibitor ? "販売日時" : "購入日時"}
				content={purchasedAccount.createdAt}
				contentVariant="subtitle1"
				sx={{
					border: `1px solid ${color.paleGray}`,
					borderTop: "none",
				}}
			/>
			<TableItem
				title={isExhibitor ? "販売価格" : "購入金額"}
				content={`${account.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円`}
				contentVariant="subtitle1"
				sx={{
					border: `1px solid ${color.paleGray}`,
					borderTop: "none",
				}}
			/>
			{isExhibitor && (
				<TableItem
					title={"システム手数料"}
					content={`${String(Number(account.price) * 0.1).replace(
						/\B(?=(\d{3})+(?!\d))/g,
						",",
					)}円`}
					contentVariant="subtitle1"
					sx={{
						border: `1px solid ${color.paleGray}`,
						borderTop: "none",
					}}
				/>
			)}
			{isExhibitor ? (
				<TableItem
					title="売上金"
					content={`${String(
						Number(account.price) - Number(account.price) * 0.1,
					).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円`}
					contentVariant="subtitle1"
					sx={{
						border: `1px solid ${color.paleGray}`,
						borderTop: "none",
					}}
				/>
			) : (
				<TableItem
					title="決済方法"
					content="銀行振込"
					subContent="(決済手数料0円)"
					contentVariant="subtitle1"
					sx={{
						border: `1px solid ${color.paleGray}`,
						borderTop: "none",
					}}
				/>
			)}
		</Box>
	);
};
