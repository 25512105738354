import { Box, Stack, Typography } from '@mui/material'
import { color } from '../../../styles/theme'

type Props = {
  title: string
  required?: boolean
}
export const LabelContent = ({ title, required }: Props) => {
  return (
    <Stack direction={'row'} alignItems={'center'} sx={{ gap: '5px' }}>
      <Typography variant="subtitle2">{title}</Typography>
      {required && (
        <Box
          sx={{
            backgroundColor: color.red,
            padding: '5px',
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: color.white,
            }}
          >
            必須
          </Typography>
        </Box>
      )}
    </Stack>
  )
}
