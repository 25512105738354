import { Stack } from "@mui/material";
import { memo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TablePagination } from "../../../components/Pagination";
import {
	type PurchasedAccountQueryParams,
	useGetPurchasedAccounts,
} from "../../../hooks/api/purchases";
import { TradingHeader } from "./TradingHeader";
import { TradingTable } from "./TradingTable";

export const Tradings = memo(() => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const id = searchParams.get("id") ?? undefined;
	const tradingStartDate = searchParams.get("startDate") ?? undefined;
	const tradingEndDate = searchParams.get("endDate") ?? undefined;
	const transactionStatus = searchParams.get("transactionStatus") ?? undefined;
	const sort = searchParams.get("sort") ?? undefined;
	const order = searchParams.get("order") ?? undefined;

	const queryParams: PurchasedAccountQueryParams = {
		id: id,
		startDate: tradingStartDate,
		endDate: tradingEndDate,
		transactionStatus: transactionStatus,
		sort: sort,
		order: order,
	};
	const { purchasedAccounts, mutate } = useGetPurchasedAccounts(queryParams);

	const handleSearch = (params: PurchasedAccountQueryParams) => {
		searchParams.delete("id");
		searchParams.delete("startDate");
		searchParams.delete("endDate");
		searchParams.delete("transactionStatus");
		searchParams.delete("sort");
		searchParams.delete("order");
		if (params.id) {
			searchParams.set("id", params.id);
		}
		if (params.startDate) {
			searchParams.set("startDate", params.startDate);
		}
		if (params.endDate) {
			searchParams.set("endDate", params.endDate);
		}
		if (params.transactionStatus) {
			searchParams.set("transactionStatus", params.transactionStatus);
		}

		navigate(`?${searchParams.toString()}`);
	};

	return (
		<Stack
			alignItems={"center"}
			sx={{
				width: "100%",
				marginTop: "50px",
			}}
		>
			<TradingHeader queryParams={queryParams} onSearch={handleSearch} />
			<Stack
				sx={{
					width: "inherit",
					gap: 2,
				}}
			>
				<TradingTable purchasedAccounts={purchasedAccounts} refetch={mutate} />
				<TablePagination
					total={purchasedAccounts?.length ?? 0}
					displayCount={30}
					currentPage={1}
				/>
			</Stack>
		</Stack>
	);
});
