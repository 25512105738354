import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { color } from '../../../styles/theme'
import { memo } from 'react'
import { useCreateUserAPI } from '../../../hooks/api/users'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecieveOPTAPI } from '../../../hooks/api/auth'

export const ConfirmFactor = memo(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const { recieveSMS } = useRecieveOPTAPI()
  const { registerUser } = useCreateUserAPI()
  const { register, handleSubmit } = useForm()
  const handleOneTimeClick = async (payload: any) => {
    recieveSMS(location.state.authRegister?.tel, payload.oneTimePassword)
      .then((r) => {
        registerUser({
          username: location.state.authRegister?.username,
          email: location.state.authRegister?.email,
          tel: location.state.authRegister?.tel,
          password: location.state.authRegister?.password,
          registerCode: location.state.authRegister?.code,
        }).then((r) => {
          navigate('/')
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Stack
      direction={'column'}
      sx={{
        width: '100%',
        backgroundColor: color.paleGray,
        gap: '15px',
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          marginTop: '20px',
        }}
      >
        <Typography variant="h4">M & A</Typography>
        <Typography variant="h5">CLUB</Typography>
      </Stack>
      <Stack direction={'column'} height={'100vh'} justifyContent={'start'} alignItems={'center'}>
        <Stack
          direction={'column'}
          alignItems={'center'}
          sx={{
            width: '700px',
            height: '500px',
            backgroundColor: color.white,
            padding: '50px 60px 40px',
            gap: '20px',
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 700 }}>
            SMS認証を行ってください。
          </Typography>
          <Stack
            direction={'row'}
            justifyContent={'start'}
            sx={{
              width: '100%',
            }}
          >
            <Typography
              variant="subtitle3"
              sx={{
                fontWeight: 700,
              }}
            >
              SMSでお送りした6桁の認証コードを入力してください。
            </Typography>
          </Stack>
          <TextField
            size="small"
            placeholder="6桁の認証コード"
            fullWidth={true}
            {...register('oneTimePassword')}
            sx={{
              '& .MuiInputBase-input::placeholder': {
                fontSize: '18px',
              },
            }}
          />
          <Button
            fullWidth={true}
            sx={{
              borderRadius: '0px',
            }}
            onClick={handleSubmit(handleOneTimeClick)}
          >
            <Typography variant="button">SMS認証を完了する</Typography>
          </Button>
          <Stack
            direction={'row'}
            justifyContent={'start'}
            alignItems={'start'}
            sx={{
              width: '100%',
            }}
          >
            <Typography variant="subtext1">
              ※2分待ってもSMSが届かない場合は認証コードの再送をお試しください。
            </Typography>
          </Stack>
          <Box
            sx={{
              width: '100%',
              backgroundColor: '#FFFFE0',
              padding: '20px',
            }}
          >
            <Stack alignItems={'center'}>
              <Typography variant="subtitle1">【SMSが届かない主な原因】</Typography>
            </Stack>
            <Stack alignItems={'start'} spacing={1}>
              <Typography variant="subtext1">● 電話番号の打ち間違い</Typography>
              <Typography variant="subtext1">● 着信拒否設定が設定されている</Typography>
              <Typography variant="subtext1">
                ● トラフィックが多い時間帯に着信拒否設定がされている
              </Typography>
              <Typography variant="subtext1">● 端末が電源オフになっている</Typography>
              <Typography variant="subtext1">● 通信環境・圏外状況が悪い</Typography>
            </Stack>
            <Typography variant="subtext1" sx={{ marginTop: '10px' }}>
              ※どうしてもSMSが届かない方はこちらのフォームからご連絡ください。
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
})
