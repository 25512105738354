import { memo } from 'react'
import { Pagination } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

export const TablePagination = memo(
  ({
    total,
    displayCount,
    currentPage,
    onChange,
  }: {
    total: number
    displayCount: number
    currentPage: number
    onChange?: () => void
  }) => {
    const location = useLocation()
    const navigate = useNavigate()

    const count = Math.ceil(total / displayCount)

    const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
      const search = new URLSearchParams(location.search)
      search.set('p', value.toString())
      navigate(`?${search.toString()}`)
      if (onChange) {
        onChange()
      }
    }

    return (
      <>
        {total > 0 && (
          <Pagination
            color="standard"
            count={count}
            page={currentPage}
            defaultPage={1}
            onChange={handleChange}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 auto',
            }}
          />
        )}
      </>
    )
  },
)
