import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Stack, Typography } from "@mui/material";
import { memo } from "react";
import { color } from "../../../styles/theme";
import type { AccountInquiry } from "../../../types/account";

export const Answer = memo(({ inquiry }: { inquiry: AccountInquiry }) => {
	return (
		<Stack
			direction={"row"}
			justifyContent={"end"}
			sx={{
				width: "100%",
				height: "auto",
				position: "relative",
			}}
		>
			<Box
				sx={{
					position: "relative",
					margin: "0px 25px 0px 30px",
				}}
			>
				<Stack direction={"row"} justifyContent={"end"}>
					<Typography>{inquiry.user.username}【売り手】</Typography>
				</Stack>
				<Box
					sx={{
						borderRadius: "6px",
						padding: "15px 20px 18px",
						color: color.white,
						backgroundColor: color.bluePurple,
						"&:before": {
							borderLeftColor: color.black,
							right: "-8px",
							content: '""',
							width: "0px",
							height: "0px",
							borderTop: "5px solid transparent",
							borderBottom: "5px solid transparent",
							borderLeft: `8px solid ${color.bluePurple}`,
							position: "absolute",
							top: "50%",
							marginTop: "-5px",
						},
					}}
				>
					<Typography
						variant="h5"
						sx={{
							lineHeight: "22px",
							wordBreak: "break-word",
						}}
					>
						{inquiry.message}
					</Typography>
				</Box>
			</Box>
			<Stack
				direction={"column"}
				sx={{
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{inquiry.user.avatar ? (
					<img
						src={inquiry.user.avatar}
						alt={inquiry.user.username}
						width="35px"
						height="35px"
						style={{ borderRadius: "50%", border: `1px solid ${color.salmon}` }}
					/>
				) : (
					<AccountCircleIcon fontSize="large" />
				)}
			</Stack>
		</Stack>
	);
});
