import type { User } from "./users";

export type AccountCategory = {
	id: string;
	name: string;
	categoryType: string;
	FirstLatter: string;
};

export type Account = {
	id: string;
	userId: string;
	categoryId: string;
	categoryName: string;
	name: string;
	accountName: string;
	price: string;
	description: string;
	image: string;
	url: string;
	platform: string;
	followers: string;
	sellingStatus: AccountSellingStatusType;
	subImages?: AccountSubImage[];
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
	user: User;
};

export type AccountSubImage = {
	accountId: string;
	imageUrl: string;
	orderIndex: number;
};

export enum SellingStatus {
	Selling = 0,
	Trading = 1,
	Canceled = 2,
	Sold = 3,
}

export function getSellingStatusString(
	status: AccountSellingStatusType,
): string {
	switch (status) {
		case SellingStatus.Selling:
			return "販売中";
		case SellingStatus.Trading:
			return "取引中";
		case SellingStatus.Canceled:
			return "キャンセル済み";
		case SellingStatus.Sold:
			return "売却済み";
		default:
			return "";
	}
}

export type AccountSellingStatusType =
	(typeof SellingStatus)[keyof typeof SellingStatus];

export type AccountInquiry = {
	id: string;
	accountId: string;
	userId: string;
	name: string;
	message: string;
	user: User;
};
