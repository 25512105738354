import { Box } from '@mui/material'
import { color } from '../../../styles/theme'

export const Border = () => {
  return (
    <Box
      sx={{
        width: '100%',
        borderBottom: `1px solid ${color.paleGray}`,
      }}
    />
  )
}
