import { Stack, type SxProps, TextField, type Theme, Typography } from '@mui/material'
import { color } from '../../../styles/theme'
import { LabelContent } from '../parts/LabelContent'

type Props = {
  name?: string
  title: string
  required: boolean
  placeholder?: string
  helperText?: string
  helperTextColor?: string
  annotationText?: string
  multiline?: boolean
  sx?: SxProps<Theme>
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  inputProps?: Record<string, unknown>
}
export const InputField = ({
  name,
  title,
  required,
  placeholder,
  helperText,
  helperTextColor,
  multiline,
  sx,
  onChange,
  inputProps,
}: Props) => {
  return (
    <Stack sx={{ width: '100%', gap: '5px' }}>
      <LabelContent title={title} required={required} />
      <TextField
        name={name}
        placeholder={placeholder}
        multiline={multiline}
        rows={multiline ? 7 : undefined}
        sx={{
          '& .MuiInputBase-root': {
            ...(multiline ? {} : { height: '40px' }),
          },
          ...sx,
        }}
        inputProps={inputProps}
        onChange={onChange}
      />
      <Typography
        variant="subtitle3"
        sx={{
          color: helperTextColor || color.black,
        }}
      >
        {helperText}
      </Typography>
    </Stack>
  )
}
