import { Box, Button, Stack, Typography } from '@mui/material'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDeleteAccount } from '../../../hooks/api/accounts'
import { ModalBase } from './ModalBase'

type DeleteAccountModalProps = {
  open: boolean
  accountId: string
  onClose: () => void
}

export const DeleteAccountModal = memo(({ open, accountId, onClose }: DeleteAccountModalProps) => {
  const navigate = useNavigate()
  const { deleteAccount } = useDeleteAccount()
  const handleDelete = () => {
    deleteAccount(accountId)
      .then(() => {
        navigate('/accounts')
      })
      .catch((e) => console.error(e))
  }
  return (
    <ModalBase
      open={open}
      onClose={onClose}
      sx={{
        width: 350,
        height: 150,
      }}
    >
      <Stack
        sx={{
          textAlign: 'center',
          gap: 2,
        }}
      >
        <Box>
          <Typography variant={'h4'}>この商品を完全に削除しますか？</Typography>
          <Typography variant={'h4'}>(削除後は復元できません。)</Typography>
        </Box>

        <Stack direction={'row'} justifyContent={'center'} gap={2}>
          <Button onClick={handleDelete}>はい</Button>
          <Button onClick={onClose} color={'secondary'}>
            いいえ
          </Button>
        </Stack>
      </Stack>
    </ModalBase>
  )
})
