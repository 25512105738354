import { Button, Stack, Typography } from "@mui/material";
import { memo, useContext } from "react";
import { useGetUserBankAccount } from "../../../../hooks/api/bank";
import { useGetUserPoints } from "../../../../hooks/api/userPoints";
import { AuthContext } from "../../../../hooks/context/auth";
import { TableItem } from "../../../../pages/accounts/purchase/TableItem";
import { color } from "../../../../styles/theme";
import type { UserBankAccount } from "../../../../types/userBankAccount";
import { CustomTextareaAutosize } from "../../../styled/TextareaAutosize";

type WithdrawalPointModalProps = {
	buttonDisabled: boolean;
	previousUserBankAccount: boolean;
	onClick: (userBankAccount: UserBankAccount) => void;
};

export const WithdrawalPointForm = memo(
	({
		buttonDisabled,
		previousUserBankAccount,
		onClick,
	}: WithdrawalPointModalProps) => {
		const authContext = useContext(AuthContext);
		const userId = authContext?.auth?.id;
		const { userPoints, error: userPointsError } = useGetUserPoints(userId);
		const { userBankAccount, error } = useGetUserBankAccount(userId);

		if (!userBankAccount || !previousUserBankAccount) {
			const message = "該当する口座情報がありません";
			return (
				<Stack
					sx={{
						gap: 2,
					}}
				>
					<Stack
						direction={"row"}
						justifyContent={"center"}
						alignItems={"center"}
						height={"100px"}
						sx={{
							borderRadius: "10px",
							backgroundColor: color.matGray,
						}}
					>
						<Typography
							variant="h4"
							whiteSpace={"pre-wrap"}
							textAlign={"center"}
						>
							{message}
						</Typography>
					</Stack>
				</Stack>
			);
		}

		return (
			<>
				<Stack>
					<TableItem
						title={"銀行名"}
						titleWidth={"30%"}
						titleVariant={"subtitle1"}
						content={userBankAccount.bankName}
						contentWidth={"70%"}
						contentAlign="end"
						contentVariant={"subtitle3"}
						sx={{
							border: `1px solid ${color.paleGray}`,
						}}
					/>
					<TableItem
						title={"支店名"}
						titleWidth={"30%"}
						titleVariant={"subtitle1"}
						content={userBankAccount.branchName}
						contentWidth={"70%"}
						contentAlign="end"
						contentVariant={"subtitle3"}
						sx={{
							border: `1px solid ${color.paleGray}`,
							borderTop: "none",
						}}
					/>
					<TableItem
						title={"種別"}
						titleWidth={"30%"}
						titleVariant={"subtitle1"}
						content={userBankAccount.accountType === "plain" ? "普通" : "当座"}
						contentWidth={"70%"}
						contentAlign="end"
						contentVariant={"subtitle3"}
						sx={{
							border: `1px solid ${color.paleGray}`,
							borderTop: "none",
						}}
					/>
					<TableItem
						title={"口座番号"}
						titleWidth={"30%"}
						titleVariant={"subtitle1"}
						content={userBankAccount.accountNumber}
						contentWidth={"70%"}
						contentAlign="end"
						contentVariant={"subtitle3"}
						sx={{
							border: `1px solid ${color.paleGray}`,
							borderTop: "none",
						}}
					/>
					<TableItem
						title={"口座名義"}
						titleWidth={"30%"}
						titleVariant={"subtitle1"}
						content={userBankAccount.accountName}
						contentWidth={"70%"}
						contentAlign="end"
						contentVariant={"subtitle3"}
						sx={{
							border: `1px solid ${color.paleGray}`,
							borderTop: "none",
						}}
					/>
				</Stack>
				<CustomTextareaAutosize
					value={userBankAccount.remarks}
					minRows={7}
					readOnly={true}
					disabled={true}
					sx={{ resize: "none" }}
				/>
				<Typography variant="subtitle3" color="red">
					口座情報に誤りがあった場合も振り込み手数料が発生するため、出金手数料の返却は行なっておりませんのでご注意ください。
				</Typography>
				<Button
					disabled={buttonDisabled}
					onClick={() => onClick(userBankAccount)}
				>
					<Typography>出金する</Typography>
				</Button>
			</>
		);
	},
);
