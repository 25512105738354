import {
	Autocomplete,
	Box,
	type SxProps,
	TextField,
	type Theme,
} from "@mui/material";
import { type SyntheticEvent, forwardRef } from "react";
import type { AccountOption } from "../data/accountData";
import { GroupHeader, GroupItems } from "../styles/groupstyles";
import { color } from "../styles/theme";
import type { CommonOption } from "../types/commonOption";

interface CustomAutocompleteProps<T extends CommonOption> {
	options: T[];
	value: T | null;
	sx?: SxProps<Theme>;
	groupBy?: (option: T) => string;
	onChange: (event: SyntheticEvent<Element, Event>, newValue: T | null) => void;
}

// forwardRef を使用してコンポーネントを定義
export const CustomAutocomplete = forwardRef<
	HTMLDivElement,
	CustomAutocompleteProps<CommonOption>
>(({ options, value, sx, groupBy, onChange }, ref) => {
	if (options.length) {
		if ("firstLetter" in options[0]) {
			const accountOptions = options as AccountOption[];
			options = accountOptions.sort(
				(a, b) => -b.firstLetter.localeCompare(a.firstLetter),
			);
		} else {
			options = options.sort((a, b) => -b.label.localeCompare(a.label));
		}
	}

	return (
		<Autocomplete
			value={value}
			options={options as CommonOption[]}
			groupBy={groupBy}
			getOptionLabel={(option) => option.label || ""}
			isOptionEqualToValue={(option, value) => option.value === value.value}
			sx={{
				backgroundColor: color.white,
				...sx,
			}}
			renderInput={(params) => <TextField {...params} />}
			renderGroup={(params) => (
				<li key={params.key}>
					<GroupHeader>{params.group}</GroupHeader>
					<GroupItems>{params.children}</GroupItems>
				</li>
			)}
			renderOption={(props, option) => (
				<Box component={"li"} {...props} key={option.value}>
					{option.label}
				</Box>
			)}
			onChange={onChange}
			ref={ref}
		/>
	);
});
