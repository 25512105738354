import { Box, Stack, Typography } from "@mui/material";
import type { MessageData } from "../../../hooks/api/userMessage";
import { color } from "../../../styles/theme";
import { displayDateAgo } from "../../../utils/date";

type Props = {
	res: MessageData;
	partnerId: string | null;
	onClick: (res: MessageData) => void;
};

export const Partner = ({ res, partnerId, onClick }: Props) => {
	return (
		<Stack
			direction={"row"}
			sx={{
				position: "relative",
				padding: "25px 0px 25px 25px",
				borderLeft: partnerId === res.partner.id ? "3px solid #2B80FF" : "none",
				cursor: "pointer",
				"&::after": {
					content: '""',
					width: "calc(100% - 97px)",
					height: "1px",
					position: "absolute",
					bottom: "0px",
					transition: "background-color 2s",
					background: "transparent",
				},
				"&:hover::after": {
					background: "#3D3949",
				},
			}}
			onClick={() => onClick(res)}
		>
			<Box
				sx={{
					width: "50px",
					height: "1px",
					position: "absolute",
					bottom: "0px",
					background: "#3D3949",
				}}
			/>
			<Box
				sx={{
					border: `1px solid ${color.salmon}`,
					borderRadius: "50%",
					width: "50px",
					height: "50px",
				}}
			>
				<img
					src={res.partner.avatar}
					alt={res.partner.username}
					width="50px"
					height="50px"
					style={{
						borderRadius: "50%",
						border: `1px solid ${color.salmon}`,
					}}
				/>
			</Box>
			<Box
				sx={{
					float: "left",
					padding: "8px 15px 0px 20px",
					width: "calc(99% - 97px)",
				}}
			>
				<Typography variant="h5" sx={{ color: color.salmon }}>
					{res.partner.username}
				</Typography>
				<Typography
					variant="subtitle2"
					sx={{
						color: color.white,
						opacity: 0.5,
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						fontWeight: 300,
					}}
				>
					{res.currentMessage}
				</Typography>
			</Box>
			<Typography
				variant="body2"
				sx={{
					color: color.white,
					lineHeight: "12px",
					paddingTop: "24px",
					opacity: 0.5,
				}}
			>
				{res.lastSentAt ? displayDateAgo(new Date(res.lastSentAt)) : ""}
			</Typography>
		</Stack>
	);
};
