import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { memo } from 'react'
import type * as React from 'react'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '&:focus-visible': {
    outline: 'none',
    border: 'none',
  },
}

interface ImageModalProps {
  open: boolean
  imageUrl: string
  onClose: () => void
}

export const ImageModal: React.FC<ImageModalProps> = memo(({ open, imageUrl, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <img
          src={imageUrl}
          alt="Modal"
          style={{
            borderWidth: '0px',
            borderRadius: '10px',
          }}
        />
      </Box>
    </Modal>
  )
})
