import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
	interface TypographyVariants {
		subtitle3: React.CSSProperties;
		subtext1: React.CSSProperties;
		subtext2: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		subtitle3?: React.CSSProperties;
		subtext1?: React.CSSProperties;
		subtext2?: React.CSSProperties;
	}
}

// Update the variant prop options
declare module "@mui/material" {
	interface TypographyPropsVariantOverrides {
		subtitle3: true;
		subtext1: true;
		subtext2: true;
	}
}

export const color = {
	black: "#111821",
	white: "#FFFFFF",
	darkGray: "#A9A9A9",
	midNightBlueGrey: "#293037",
	midGray: "#BFBFBF",
	matGray: "#EDEEF2",
	paleGray: "#F4F4F4",
	purple: "#4B2EAE",
	bluePurple: "#272234",
	darkPurple: "#28212D",
	red: "#EA352D",
	blue: "#0067C0",
	salmon: "#FF792B",
	jewelGreen: "#06C755",
	lightYellow: "#FFFFD5",
};

export const theme = createTheme({
	components: {
		MuiTypography: {
			variants: [
				{
					props: { variant: "h1" },
					style: { fontSize: 38, lineHeight: "40px", fontWeight: 700 },
				},
				{
					props: { variant: "h2" },
					style: { fontSize: 30, lineHeight: "30px", fontWeight: 700 },
				},
				{
					props: { variant: "h3" },
					style: { fontSize: 20, lineHeight: "20px", fontWeight: 700 },
				},
				{
					props: { variant: "h4" },
					style: { fontSize: 17, lineHeight: "20px", fontWeight: 700 },
				},
				{
					props: { variant: "h5" },
					style: {
						fontSize: 16,
						lineHeight: "16px",
						fontFamily: `"Proxima Nova", sans-serif`,
					},
				},
				{
					props: { variant: "subtitle1" },
					style: { fontSize: 14, lineHeight: "16px", fontWeight: 700 },
				},
				{
					props: { variant: "subtitle2" },
					style: { fontSize: 14, lineHeight: "14px", fontWeight: 700 },
				},
				{
					props: { variant: "subtitle3" },
					style: { fontSize: 14, lineHeight: "16px", fontWeight: 400 },
				},
				{
					props: { variant: "body1" },
					style: {
						fontSize: 14,
						lineHeight: "22px",
						fontWeight: 400,
						fontFamily: `"Proxima Nova", sans-serif`,
					},
				},
				{
					props: { variant: "body2" },
					style: { fontSize: 12, lineHeight: "32px", fontWeight: 400 },
				},
				{
					props: { variant: "subtext1" },
					style: { fontSize: 12, lineHeight: "12px", fontWeight: 400 },
				},
				{
					props: { variant: "subtext2" },
					style: { fontSize: 10, lineHeight: "10px", fontWeight: 400 },
				},
			],
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 23,
					fontSize: "14px",
					textTransform: "none",
					backgroundColor: color.jewelGreen,
					"&:hover": {
						backgroundColor: color.jewelGreen,
						boxShadow: `0 0 10px ${color.jewelGreen}, 0 0 20px ${color.jewelGreen}`,
					},
				},
				startIcon: {
					margin: "3px 0 0 -4px",
				},
				endIcon: {
					position: "absolute",
					right: "16px",
				},
			},
			variants: [
				// size
				{ props: { size: "large" }, style: { height: 48, padding: "16px" } },
				{
					props: { size: "medium" },
					style: { fontSize: 12, height: 40, padding: "0px 26px" },
				},
				{
					props: { size: "small" },
					style: { height: 20, fontSize: 12, padding: "8px 12px" },
				},
				// color
				{
					props: { color: "primary" },
					style: {
						color: color.white,
						fontFamily: `"Proxima Nova", sans-serif`,
					},
				},
				{
					props: { color: "primary", disabled: true },
					style: {
						backgroundColor: color.matGray,
						color: color.midGray,
					},
				},
				{
					props: { color: "secondary" },
					style: {
						color: color.white,
						backgroundColor: color.red,
						"&:hover": {
							backgroundColor: color.red,
							boxShadow: `0 0 10px ${color.red}, 0 0 20px ${color.red}`,
						},
					},
				},
				{
					props: { color: "info" },
					style: {
						color: color.white,
						backgroundColor: color.darkGray,
						"&:hover": {
							backgroundColor: color.darkGray,
							boxShadow: `0 0 10px ${color.darkGray}, 0 0 20px ${color.darkGray}`,
						},
					},
				},
			],
		},
		MuiToggleButtonGroup: {
			styleOverrides: {
				root: {
					flexWrap: "wrap",
				},
				grouped: {
					"&:not(:first-of-type)": {
						border: `1px solid ${color.matGray}`,
						borderRadius: "20px",
						margin: "5px 20px",
					},
					"&:not(:last-of-type)": {
						border: `1px solid ${color.matGray}`,
						borderRadius: "20px",
						margin: "5px 20px",
					},
					"&.Mui-selected": {
						color: color.black,
						background: color.white,
						border: `1px solid ${color.black}`,
						transitionDuration: "0.0s",
					},
					"&:hover": {
						background: color.white,
						boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16);",
					},
					"&.Mui-disabled": {
						color: color.matGray,
						background: color.paleGray,
						border: `1px solid ${color.paleGray}`,
					},
					color: color.white,
					transitionDuration: "0.3s",
					background: "transparent",
					padding: "0px 20px",
					margin: "5px 20px",
					height: "34px",
					minWidth: "80px",
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					padding: "20px",
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					"& .MuiInputBase-input::placeholder": {
						fontSize: "12px",
					},
					"& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
						{
							WebkitAppearance: "none",
							margin: 0,
						},
					"& input[type=number]": {
						MozAppearance: "textfield",
					},
					"& .Mui-disabled": {
						backgroundColor: color.midGray,
					},
				},
			},
		},
	},
});
