import { Stack, TableCell, TableRow, Typography } from '@mui/material'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { color } from '../../../styles/theme'
import type { Account } from '../../../types/account'

export const AccountRow = memo(({ account }: { account: Account }) => {
  const navigate = useNavigate()
  return (
    <TableRow
      sx={{
        height: '100px',
      }}
      onClick={() => {
        navigate(`/accounts/${account.id}`)
      }}
    >
      <TableCell align="left" sx={{ width: 100, height: 100 }}>
        <img
          src={account.image}
          alt={`${account.accountName}`}
          width="100"
          height="100"
          loading="lazy"
        />
      </TableCell>
      <TableCell
        sx={{
          verticalAlign: 'top',
        }}
      >
        <Stack
          direction={'column'}
          sx={{
            gap: 2,
          }}
        >
          <Typography
            variant="h2"
            noWrap={true}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {account.accountName}
          </Typography>
          <Typography
            variant="h4"
            noWrap={true}
            sx={{
              maxWidth: '500px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {account.description}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell
        align="right"
        sx={{
          verticalAlign: 'bottom',
          width: '100%',
        }}
      >
        <Stack direction={'row'} justifyContent={'flex-end'}>
          <Typography variant="h2" sx={{ color: color.red }}>
            {`¥${account.price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}円`}
          </Typography>
        </Stack>
      </TableCell>
    </TableRow>
  )
})
