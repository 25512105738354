import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { WithdrawalPointModal } from "../../../components/common/modals/WithdrawalPointModal";
import { Loading } from "../../../components/common/parts/Loading";
import { SaleStatusTypes } from "../../../enum/saleStatus";
import { useGetUserPoints } from "../../../hooks/api/userPoints";
import { useCreateWithdrawal } from "../../../hooks/api/withdrawal";
import { AuthContext } from "../../../hooks/context/auth";
import type { UserBankAccount } from "../../../types/userBankAccount";

export const UserPoint = () => {
	const authContext = useContext(AuthContext);
	const { id: userId } = useParams<{ id: string }>();
	const { userPoints, error } = useGetUserPoints(userId);
	const { createWithdrawal } = useCreateWithdrawal();

	const [open, setOpen] = useState(false);

	if (error) {
		return <Box>{error}</Box>;
	}

	if (!userPoints) {
		return <Loading />;
	}

	if (!authContext?.auth?.id) {
		return <Box>ログインしてください</Box>;
	}

	if (authContext.auth.id !== userId) {
		return <Box>アクセス権限がありません</Box>;
	}

	let proceeds = 0;
	if (userPoints) {
		proceeds = userPoints.reduce(
			(total, userPoint) => total + userPoint.point,
			0,
		);
	}

	const handleCreateWithdrawal = (
		userBankAccount: UserBankAccount,
		amount: number,
	) => {
		createWithdrawal({
			userId: userId.toString(),
			userBankAccountId: userBankAccount.id.toString(),
			amount: amount,
			remarks: "",
			status: SaleStatusTypes.PENDING,
		})
			.then(() => {
				alert("出金が完了しました");
				window.location.reload();
			})
			.catch((error) => {
				console.error(error);
				alert("出金に失敗しました");
			});
	};

	return (
		<>
			<Stack
				alignItems={"center"}
				sx={{
					width: "100%",
					marginTop: "50px",
				}}
			>
				<Stack
					alignItems={"center"}
					width={"400px"}
					sx={{
						gap: "20px",
						padding: "20px",
					}}
				>
					<CurrencyYenIcon
						fontSize="large"
						sx={{
							color: "#FFD700",
						}}
					/>
					<Typography variant="h4">現在の売上金</Typography>
					<Typography variant="h3">{proceeds}円</Typography>
					<Button sx={{ width: "inherit" }} onClick={() => setOpen(true)}>
						<Typography variant="h5">売上金を出金する</Typography>
					</Button>
				</Stack>
			</Stack>
			<WithdrawalPointModal
				open={open}
				proceeds={proceeds}
				userPoints={userPoints}
				onClose={() => setOpen(false)}
				onSubmit={handleCreateWithdrawal}
			/>
		</>
	);
};
