import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Box } from "@mui/material";

export const EvaluationIcon = ({ condition }: { condition: string }) => {
	switch (condition) {
		case "plus":
			return <CheckIcon color="success" />;
		case "minus":
			return <ClearIcon color="error" />;
		default:
			return (
				<Box
					sx={{
						width: "24px",
						height: "24px",
					}}
				/>
			); // 条件が 'plus' または 'minus' 以外の場合は何も表示しない
	}
};
