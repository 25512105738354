import { Link, Stack, Typography } from "@mui/material";
import { memo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useGetAccountDetail } from "../../../../hooks/api/accounts";
import { TableItem } from "../../../../pages/accounts/purchase/TableItem";
import { color } from "../../../../styles/theme";
import { Loading } from "../../parts/Loading";

export const AccountComplete = memo(() => {
	const location = useLocation();
	const { account, error, isLoading } = useGetAccountDetail(
		location.state.accountId,
	);

	if (!location?.state?.resultState || !location?.state?.accountId) {
		return <Navigate to={"/accounts"} />;
	}

	if (isLoading) {
		return <Loading />;
	}

	if (!account || error) {
		return <Navigate to={"/accounts"} />;
	}

	return (
		<Stack
			alignItems={"center"}
			sx={{
				marginTop: 4,
				gap: 2,
			}}
		>
			<Typography variant={"h2"}>
				{location.state.resultState === "created" ? "登録" : "編集"}
				が完了しました
			</Typography>
			<Stack
				sx={{
					width: "560px",
				}}
			>
				<Stack>
					<Typography
						variant={"h3"}
						color={color.white}
						sx={{
							backgroundColor: color.black,
							padding: "5px",
						}}
					>
						アカウント画像
					</Typography>
				</Stack>
				<Stack
					direction={"row"}
					sx={{
						border: `1px solid ${color.paleGray}`,
						flexWrap: "wrap",
						maxwidth: "560px",
						gap: "10px",
						padding: "10px 0px 10px 10px",
					}}
				>
					<img
						src={account?.image}
						alt={account?.accountName}
						width={100}
						height={100}
					/>
					{account?.subImages?.map((image, _) => (
						<img
							key={`${image.accountId}-${image.orderIndex}`}
							src={image.imageUrl}
							alt={account?.accountName}
							width={100}
							height={100}
						/>
					))}
				</Stack>
				<Stack>
					<TableItem
						title={"案件カテゴリー"}
						titleWidth={"30%"}
						titleVariant={"subtitle1"}
						content={account.categoryName}
						contentWidth={"70%"}
						contentAlign="end"
						contentVariant={"subtitle3"}
						sx={{
							border: `1px solid ${color.paleGray}`,
						}}
					/>
					<TableItem
						title={"案件タイトル"}
						titleWidth={"30%"}
						titleVariant={"subtitle1"}
						content={account.accountName}
						contentWidth={"70%"}
						contentAlign="end"
						contentVariant={"subtitle3"}
						sx={{
							border: `1px solid ${color.paleGray}`,
						}}
					/>
					<TableItem
						title={"案件説明"}
						titleWidth={"30%"}
						titleVariant={"subtitle1"}
						content={account.description}
						contentWidth={"70%"}
						contentAlign="end"
						contentVariant={"subtitle3"}
						sx={{
							border: `1px solid ${color.paleGray}`,
						}}
					/>
					<TableItem
						title={"フォロワー数"}
						titleWidth={"30%"}
						titleVariant={"subtitle1"}
						content={`${account.followers.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}人`}
						contentWidth={"70%"}
						contentAlign="end"
						contentVariant={"subtitle3"}
						sx={{
							border: `1px solid ${color.paleGray}`,
						}}
					/>
					<TableItem
						title={"価格"}
						titleWidth={"30%"}
						titleVariant={"subtitle1"}
						content={`¥${account.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
						contentWidth={"70%"}
						contentAlign="end"
						contentVariant={"subtitle3"}
						sx={{
							border: `1px solid ${color.paleGray}`,
						}}
					/>
				</Stack>
			</Stack>
			<Link href={"/accounts"}>アカウント一覧へ</Link>
		</Stack>
	);
});
