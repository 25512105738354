import { Box } from '@mui/material'
import { Header } from '../components/common/layouts/Header'
import { Outlet, useLocation } from 'react-router-dom'

export const Layout = () => {
  const location = useLocation()
  return (
    <Box sx={{ overflowX: 'hidden' }}>
      {location.pathname !== '/accounts' && <Header />}
      <Outlet />
    </Box>
  )
}
