import { Box, Button, TextField, Typography } from "@mui/material";
import { type ReactNode, memo, useState } from "react";
import type { RegisterRequest } from "../../../types/auth";
import { ModalBase } from "./ModalBase";

export const RegisterModal = memo(
	({
		open,
		onClose,
		onSubmit,
		message,
		alertMessage,
		confirmLabel,
		confirmIcon,
	}: {
		open: boolean;
		onClose: () => void;
		onSubmit: (req: RegisterRequest) => void;
		message: string;
		alertMessage?: string;
		confirmLabel?: string;
		cancelLabel?: string;
		confirmIcon?: ReactNode;
		children?: ReactNode;
	}) => {
		const [email, setEmail] = useState("");
		const [username, setUsername] = useState("");
		const [password, setPassword] = useState("");
		const [confirmPassword, setConfirmPassword] = useState("");
		const handleSubmit = () => {
			if (password !== confirmPassword) {
				alert("Passwords do not match");
				return;
			}
			onSubmit({ email, username, password });
		};

		return (
			<ModalBase
				open={open}
				onClose={onClose}
				sx={{
					width: 390,
					minHeight: 160,
				}}
			>
				<Box
					id={"modal-title"}
					sx={{ flexGrow: 1, textAlign: "center", marginBottom: "25px" }}
				>
					<Typography variant={"h4"}>{message}</Typography>
					{alertMessage && (
						<Typography
							sx={{
								color: "red",
								fontSize: "0.9rem",
							}}
						>
							{alertMessage}
						</Typography>
					)}
				</Box>
				<Box
					sx={{
						width: "280px",
						margin: "auto",
					}}
				>
					<Box
						sx={{
							marginBottom: "5px",
						}}
					>
						<TextField
							size="small"
							placeholder="Email"
							sx={{
								width: "280px",
							}}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</Box>
					<Box
						sx={{
							marginBottom: "5px",
						}}
					>
						<TextField
							size="small"
							placeholder="Username"
							sx={{
								width: "280px",
							}}
							onChange={(e) => setUsername(e.target.value)}
						/>
					</Box>
					<Box
						sx={{
							marginBottom: "5px",
						}}
					>
						<TextField
							size="small"
							type="password"
							placeholder="Password"
							sx={{
								width: "280px",
							}}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</Box>
					<Box
						sx={{
							marginBottom: "5px",
						}}
					>
						<TextField
							size="small"
							type="password"
							placeholder="Confirm password"
							sx={{
								width: "280px",
							}}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
					</Box>
				</Box>
				<Box
					sx={{
						textAlign: "center",
						margin: "20px auto auto",
					}}
				>
					<Button
						size={"medium"}
						sx={{ width: 160, ml: 2 }}
						onClick={handleSubmit}
						startIcon={confirmIcon}
					>
						{confirmLabel}
					</Button>
				</Box>
			</ModalBase>
		);
	},
);
