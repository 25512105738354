import useSWR from "swr";
import type { UserPoint } from "../../types/userPoint";
import { fetcher } from "./fetcher";

export type CreateUserPointRequest = {
	points: number;
	userId: string;
	saleStatus: number;
};

export type GetUserPointResponses = {
	userPoints: UserPoint[];
};

export const useGetUserPoints = (
	userId?: string,
	lastCreatedAt?: string,
): {
	userPoints?: UserPoint[] | undefined;
	error: string | null;
} => {
	const key = `/api/user_point/${userId}${lastCreatedAt ? `?lastCreatedAt=${lastCreatedAt}` : ""}`;
	const { data, error } = useSWR<GetUserPointResponses>(key, fetcher);

	if (error) {
		return {
			userPoints: undefined,
			error: error.message,
		};
	}

	return {
		userPoints: data?.userPoints,
		error: null,
	};
};

export const useCreateUserPoint = (): {
	createUserPoint: (formData: CreateUserPointRequest) => Promise<void>;
} => {
	return {
		createUserPoint: async (data: CreateUserPointRequest) =>
			await fetcher("/api/user_point/create", {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			}),
	};
};
