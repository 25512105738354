import { Box } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AccountForm } from '../../../components/common/layouts/accounts/AccountForm'
import { useGetAccountDetail } from '../../../hooks/api/accounts'
import type { AccountRequest } from '../../../hooks/api/schema/accountRequest'
import { AuthContext } from '../../../hooks/context/auth'
import type { AccountSubImage } from '../../../types/account'
import { type FileData, urlsToFiles } from '../../../utils/url'

export const UpdateAccount = () => {
  const authContext = useContext(AuthContext)
  const { id } = useParams<{ id: string }>()
  const { account, error: accountError } = useGetAccountDetail(id || '')
  let initialState: AccountRequest | undefined = undefined
  const imageFiles: File[] = []
  if (account) {
    initialState = {
      id: account.id,
      images: [],
      categoryId: Number(account.categoryId),
      categoryName: account.categoryName,
      accountName: account.accountName,
      description: account.description,
      followers: account.followers,
      price: account.price,
    }
  }

  const [images, setImages] = useState<File[]>(imageFiles)
  useEffect(() => {
    if (account) {
      let fileData: FileData[] = []
      fileData = [
        {
          url: account.image,
          mimeType: `image/${account.image.split('.').pop()}`,
        },
      ]
      if (account.subImages) {
        for (const subImage of account.subImages as AccountSubImage[]) {
          fileData.push({
            url: subImage.imageUrl,
            mimeType: `image/${subImage.imageUrl.split('.').pop()}`,
          })
        }
      }
      urlsToFiles(fileData).then((files) => {
        const sortedFiles = files.sort((a, b) => a.name.localeCompare(b.name))
        setImages(sortedFiles)
      })
    }
  }, [account])

  return accountError ? (
    <Box />
  ) : authContext?.auth ? (
    account ? (
      <AccountForm
        accountParams={initialState}
        images={images}
        mode="edit"
        onImagesChange={(files: File[]) => {
          setImages(files)
        }}
      />
    ) : (
      <Box />
    )
  ) : (
    <Box />
  )
}
